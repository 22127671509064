var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "400px", scrollable: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var attrs = ref.attrs
            return [
              _c(
                "v-list-item",
                _vm._b(
                  {
                    attrs: { link: "" },
                    on: {
                      click: function($event) {
                        return _vm.setDialog(true, true)
                      }
                    }
                  },
                  "v-list-item",
                  attrs,
                  false
                ),
                [
                  _c(
                    "v-list-item-subtitle",
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-3",
                          attrs: { size: "16", color: "error" }
                        },
                        [_vm._v(" $paper_trash_outline ")]
                      ),
                      _c("span", { staticClass: "error--text" }, [
                        _vm._v(" " + _vm._s(_vm.$t("papers.btn.remove")) + " ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c("alert-remove-card", {
        attrs: { loading: _vm.submitting },
        on: {
          confirm: function($event) {
            return _vm.submit()
          },
          close: function($event) {
            return _vm.setDialog(false)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }