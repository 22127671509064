var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "text-editor-inline",
    {
      attrs: {
        value: _vm.active,
        "font-size": _vm.fontSize,
        "text-align": _vm.textAlign,
        "font-weight": _vm.fontWeight,
        "font-style": _vm.fontStyle,
        "text-decoration": _vm.textDecoration,
        color: _vm.color
      },
      on: {
        input: function(key, value) {
          return _vm.setElement(key, value)
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "text-element",
          style: {
            width: "100%",
            height: "100%",
            fontSize: _vm.fontSize,
            textAlign: _vm.textAlign,
            fontWeight: _vm.fontWeight,
            fontStyle: _vm.fontStyle,
            textDecoration: _vm.textDecoration,
            color: _vm.color,
            overflow: _vm.overflow,
            whiteSpace: _vm.whiteSpace
          }
        },
        [_vm._v(" " + _vm._s(_vm.text) + " ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }