var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      staticClass: "py-4",
      attrs: {
        color: "primary",
        outlined: "",
        depressed: "",
        tile: "",
        "x-small": ""
      },
      on: {
        click: function($event) {
          return _vm.selectAll()
        }
      }
    },
    [_c("v-icon", { attrs: { small: "" } }, [_vm._v(" mdi-select-all ")])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }