<template>
  <div
    :style="{
      width: '100%',
      height: '100%',
      fontSize,
      overflow
    }">
    {{ text }}
  </div>
</template>

<script>
import paperElementMixin from '@/mixins/paperElementMixin'

export default {
  mixins: [paperElementMixin],
  props: {
    fontSize: {
      type: String,
      default: '14px'
    },
    overflow: {
      type: String,
      default: 'initial'
    },
    numberDisplayType: {
      type: String,
      default: ''
    }
  },
  computed: {
    text () {
      const text = 'วัน/เดือน/ปี'
      // if (this.numberDisplayType === 'number_to_word') {
      //   text = 'หนึ่งหมื่น'
      // }
      return text
    }
  }

}
</script>
<style scoped>
</style>
