<template>
  <v-dialog
    v-model="dialog"
    max-width="430px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        color="primary"
        x-small
        icon
        v-on="on">
        <v-icon
          size="17">
          $paper_cog
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="px-4 pb-1">
        <title-label :label="title" />
        <v-spacer />
        <v-icon
          class="dialog-close-tr"
          color="#C8C8C8"
          small
          @click="setDialog(false)">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="px-4">
        <div class="setting-subtitle mt-2 mb-4">
          {{ $t('papers.builder.settings.decimal.title') }}
        </div>
        <v-row
          no-gutters
          class="mb-4">
          <v-col cols="3">
            <text-field-label
              :label="$t('papers.builder.settings.decimal.digits')"
              class="mr-2" />
          </v-col>
          <v-col cols="3">
            <div class="d-flex align-center">
              <div class="mr-2">
                <v-select
                  :value="digit"
                  :items="digits"
                  class="app-style"
                  hide-details
                  outlined
                  dense
                  @input="setElement('fx.digit', {
                    value: $event
                  })" />
              </div>
              <text-field-label :label="$t('papers.builder.settings.decimal.digit')" />
            </div>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          class="mb-4">
          <v-col cols="3">
            <text-field-label
              :label="$t('papers.builder.settings.decimal.decimal')"
              class="mr-2" />
          </v-col>
          <v-col cols="3">
            <div class="d-flex align-center">
              <div class="mr-2">
                <v-select
                  :value="decimal"
                  :items="decimals"
                  class="app-style"
                  hide-details
                  outlined
                  dense
                  @input="setElement('fx.decimal', {
                    value: $event
                  })" />
              </div>
              <text-field-label :label="$t('papers.builder.settings.decimal.digit')" />
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="5">
            <text-field-label
              :label="$t('papers.builder.settings.decimal.display')"
              class="mr-2" />
          </v-col>
          <v-col cols="5">
            <div class="mr-2">
              <v-select
                :value="numberDisplayType"
                :items="paperNumberDisplayEnum"
                class="app-style"
                item-value="name"
                item-text="description"
                hide-details
                outlined
                dense
                @input="setElement('fx.numberDisplayType', {
                  value: $event
                })"></v-select>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import paperSettingMixin from '@/mixins/paperSettingMixin'

export default {
  mixins: [paperSettingMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'None'
    },
    digit: {
      type: Number,
      default: 4
    },
    decimal: {
      type: Number,
      default: 4
    },
    numberDisplayType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dialog: false,
      digits: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      decimals: [1, 2, 3, 4]
    }
  },
  computed: {
    ...mapGetters({
      findEnum: 'Enum/find'
    }),
    paperNumberDisplayEnum () {
      return this.findEnum('PaperNumberDisplay')?.values || []
    }
  },
  methods: {
    setDialog (dialog = false) {
      this.dialog = dialog
    }
  }
}
</script>
<style scoped>
  .setting-subtitle {
    color: #B8B8B8;
    font-size: 16px;
  }
</style>
