<template>
  <v-dialog
    v-model="dialog"
    max-width="420px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        color="primary"
        x-small
        icon
        v-on="on">
        <v-icon
          size="17">
          $paper_cog
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="px-4 pb-1">
        <title-label :label="title" />
        <v-spacer />
        <v-icon
          class="dialog-close-tr"
          color="#C8C8C8"
          small
          @click="setDialog(false)">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="px-4">
        <div class="setting-subtitle mt-2 mb-2">
          {{ $t('papers.builder.settings.telephone.of') }}
        </div>
        <div class="telephone--container mb-4">
          <v-select
            :value="telOwnerType"
            :items="paperTelEnum"
            class="app-style"
            item-value="name"
            item-text="description"
            hide-details
            outlined
            dense
            @input="setElement('data', $event)"></v-select>
        </div>
        <div class="setting-subtitle mb-2">
          {{ $t('papers.builder.settings.telephone.display') }}
        </div>
        <div class="telephone--container mb-4">
          <v-select
            :value="telDisplayType"
            :items="paperTelDisplayEnum"
            class="app-style"
            item-value="name"
            item-text="description"
            hide-details
            outlined
            dense
            @input="setElement('fx.telDisplayType', {
              value: $event
            })"></v-select>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import paperSettingMixin from '@/mixins/paperSettingMixin'

export default {
  mixins: [paperSettingMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'None'
    },
    telOwnerType: {
      type: String,
      default: ''
    },
    telDisplayType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapGetters({
      findEnum: 'Enum/find'
    }),
    paperTelEnum () {
      return this.findEnum('PaperTel')?.values || []
    },
    paperTelDisplayEnum () {
      return this.findEnum('PaperTelDisplay')?.values || []
    }
  },
  methods: {
    setDialog (dialog = false) {
      this.dialog = dialog
    }
  }
}
</script>
<style scoped>
  .setting-subtitle {
    color: #B8B8B8;
    font-size: 16px;
  }
  .telephone--container {
    width: 240px;
  }
</style>
