<template>
  <v-dialog
    v-model="dialog"
    max-width="420px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        color="primary"
        x-small
        icon
        v-on="on">
        <v-icon
          size="17">
          $paper_cog
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="px-4 pb-1">
        <title-label :label="title" />
        <v-spacer />
        <v-icon
          class="dialog-close-tr"
          color="#C8C8C8"
          small
          @click="setDialog(false)">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="px-4">
        <div class="setting-subtitle mt-2 mb-2">
          {{ $t('papers.builder.settings.address.of') }}
        </div>
        <div class="address-owner--container mb-4">
          <v-select
            :value="dataType"
            :items="paperAddressEnum"
            class="app-style"
            item-value="name"
            item-text="description"
            hide-details
            outlined
            dense
            @input="setElement('data', concatDataType($event, suffixDataType))" />
        </div>
        <div class="setting-subtitle mt-2 mb-2">
          {{ $t('papers.builder.settings.address.dataType') }}
        </div>
        <div class="address-owner--container mb-4">
          <v-select
            :value="suffixDataType"
            :items="dataTypeOptions"
            class="app-style"
            item-value="name"
            item-text="description"
            hide-details
            outlined
            dense
            @input="setElement('data', concatDataType(dataType, $event))" />
        </div>
        <div class="setting-subtitle mt-2 mb-2">
          {{ $t('papers.builder.settings.address.whitespace') }}
        </div>
        <div class="address-owner--container mb-4">
          <v-select
            :value="whiteSpace"
            :items="whiteSpaceOptions"
            class="app-style"
            item-value="name"
            item-text="description"
            hide-details
            outlined
            dense
            @input="setElement('properties.style.whiteSpace', $event)" />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import paperSettingMixin from '@/mixins/paperSettingMixin'

export default {
  mixins: [paperSettingMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'None'
    },
    addressOwnerType: {
      type: String,
      default: ''
    },
    whiteSpace: {
      type: String,
      default: 'normal'
    }
  },
  data () {
    return {
      dialog: false,
      dataTypeOptions: [
        { name: 'address', description: 'ปกติ' },
        { name: 'address_without_zipcode', description: 'ไม่มีรหัสไปรษณีย์' },
        { name: 'zipcode', description: 'รหัสไปรษณีย์เท่านั้น' }
      ],
      whiteSpaceOptions: [
        { name: 'normal', description: this.$t('papers.builder.settings.address.whitespaceNormal') },
        { name: 'pre-line', description: this.$t('papers.builder.settings.address.whitespacePreLine') }
      ]
    }
  },
  computed: {
    ...mapGetters({
      findEnum: 'Enum/find'
    }),
    paperAddressEnum () {
      return this.findEnum('PaperAddress')?.values || []
    },
    dataType () {
      let addressOwnerType = this.addressOwnerType
      addressOwnerType = this.$_.replace(addressOwnerType, '_without_zipcode', '')
      addressOwnerType = this.$_.replace(addressOwnerType, 'zipcode', 'address')
      return addressOwnerType
    },
    suffixDataType () {
      if (this.$_.endsWith(this.addressOwnerType, 'address_without_zipcode')) {
        return 'address_without_zipcode'
      }
      if (this.$_.endsWith(this.addressOwnerType, 'zipcode')) {
        return 'zipcode'
      }
      return 'address'
    }
  },
  methods: {
    concatDataType (value, suffix) {
      return this.$_.replace(value, 'address', suffix)
    },
    setDialog (dialog = false) {
      this.dialog = dialog
    }
  }
}
</script>
<style scoped>
  .setting-subtitle {
    color: #B8B8B8;
    font-size: 16px;
  }
  .address-owner--container {
    width: 240px;
  }
</style>
