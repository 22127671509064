<template>
  <div
    :style="{
      width: '100%',
      height: '100%'
    }">
    <img
      class="w-100 h-100"
      :src="barcode" />
  </div>
</template>

<script>
import paperElementMixin from '@/mixins/paperElementMixin'
import barcode from '@/assets/img/paper/barcode.png'

export default {
  mixins: [paperElementMixin],
  props: {

  },
  data () {
    return {
      barcode
    }
  }
}
</script>
<style scoped>
</style>
