<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="150"
    offset-x>
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        class="snt-color-picker snt-btn--rounded mx-0 px-0"
        v-bind="attrs"
        :ripple="false"
        :color="value"
        width="48"
        height="48"
        depressed
        v-on="on" />
    </template>
    <v-color-picker
      :value="value"
      :dot-size="25"
      mode="hexa"
      @input="debounceOnChange($event)">
    </v-color-picker>
  </v-menu>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    value: {
      type: String,
      default: '#ffffff'
    }
  },
  data () {
    return {
      menu: false
    }
  },
  created () {
    this.debounceOnChange = _.debounce(this.onChange, 300)
  },
  methods: {
    onChange (event) {
      this.$emit('input', event)
    }
  }
}
</script>
<style scoped>
  .snt-color-picker.v-btn {
    border: 1px solid #e0e0e0 !important;
    min-width: 48px;
  }
</style>
