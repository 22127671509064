var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "calc-editor calc-editor-container",
      class: { "calc-editor-container--error": _vm.error }
    },
    [
      _c(
        "div",
        { staticClass: "calc-editor-body" },
        [
          _c(
            "div",
            { staticClass: "calc-editor-formula" },
            [
              _vm._l(_vm.series, function(obj, index) {
                return [
                  obj.type === "number"
                    ? _c(
                        "div",
                        { key: "smart-input-number-" + index },
                        [
                          _c("v-text-field", {
                            staticClass:
                              "input-calculator input-calculator--number",
                            attrs: {
                              value: Number(obj.value),
                              type: "number",
                              "hide-details": "",
                              dense: "",
                              outlined: ""
                            },
                            on: {
                              change: function($event) {
                                return _vm.update($event, obj.id)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : obj.type === "operator"
                    ? _c(
                        "div",
                        { key: "smart-input-operator-" + index },
                        [
                          _c("v-select", {
                            staticClass:
                              "input-calculator input-calculator--operator",
                            attrs: {
                              value: obj.value,
                              items: _vm.operator,
                              "item-text": "display",
                              "item-value": "type",
                              "hide-details": "",
                              dense: "",
                              outlined: ""
                            },
                            on: {
                              change: function($event) {
                                return _vm.update($event, obj.id)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : obj.type === "associate" || obj.type === "saved_data"
                    ? _c(
                        "div",
                        {
                          key: "smart-input-associate-" + index,
                          staticClass: "d-flex"
                        },
                        [
                          _c("variable-selector", {
                            attrs: {
                              value: obj.value,
                              items: _vm.variables,
                              "item-value": "field",
                              "item-text": "display",
                              "group-by-value": "tableName",
                              "group-by-text": "tableDescription",
                              "show-selected": ""
                            },
                            on: {
                              change: function($event) {
                                return _vm.update($event, obj.id)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  ["aggregator", "begin-aggregator", "begin-bracket"].includes(
                    obj.type
                  )
                    ? _c("div", { key: "smart-input-bracket-" + index }, [
                        _vm._v(" ( ")
                      ])
                    : ["end-aggregator", "end-bracket"].includes(obj.type)
                    ? _c("div", { key: "smart-input-bracket-" + index }, [
                        _vm._v(" ) ")
                      ])
                    : ["end-bracket"].includes(obj.type)
                    ? _c("div", { key: "smart-input-bracket-" + index }, [
                        _vm._v(" ) ")
                      ])
                    : _vm._e()
                ]
              })
            ],
            2
          ),
          _c("v-spacer"),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("div", { staticClass: "error--text" }, [
                _vm._v(" " + _vm._s(_vm.errorMessage) + " ")
              ]),
              _c("v-spacer"),
              _vm.$route.query.dev
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "", "x-small": "" },
                      on: {
                        click: function($event) {
                          return _vm.setTextMode(!_vm.isTextMode)
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v(" mdi-format-text ")
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "calc-editor-footer" },
        [
          _vm.series.length
            ? _c(
                "v-icon",
                {
                  staticClass: "btn-calc-editor-close",
                  attrs: { color: "#DADADA", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.clear()
                    }
                  }
                },
                [_vm._v(" mdi-close-circle ")]
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }