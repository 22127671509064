<template>
  <span class="example example--text">{{ string }}</span>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    variables: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  },
  computed: {
    series () {
      return this.value || []
    },
    string () {
      const strings = this.series.map(({ type, value }) => {
        let str = ''
        switch (type) {
          case 'number':
            str += ` ${value} `
            break
          case 'operator':
            switch (value) {
              case 'plus':
                str += ' + '
                break
              case 'minus':
                str += ' - '
                break
              case 'divided_by':
                str += ' / '
                break
              case 'times':
                str += ' * '
                break
              default:
                break
            }
            break
          case 'field':
            str += value.title
            break
          case 'associate':
            str += this.findVariableDisplay(value)
            break
          case 'aggregator':
          case 'begin-aggregator':
            str += `${value.toUpperCase()}`
            break
          default:
            str += ''
            break
        }
        switch (type) {
          case 'aggregator':
          case 'begin-aggregator':
          case 'begin-bracket':
            str += '('
            break
          case 'end-aggregator':
          case 'end-bracket':
            str += ')'
            break
          default:
            break
        }
        return str
      })

      if (_.head(strings) === '(' && _.last(strings) === ')') {
        strings.pop()
        strings.shift()
      }

      return strings.join('')
    }
  },
  methods: {
    findVariableDisplay (value) {
      const variable = this.variables.find((item) => item.id === value)
      return ` ${variable?.display} ` || ''
    }
  }
}
</script>

<style scoped>
  .example--text {
    letter-spacing: 1.5px;
  }
</style>
