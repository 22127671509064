var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "min-width": "200",
        origin: "center center",
        transition: "slide-x-transition",
        "offset-x": ""
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        ripple: false,
                        "x-small": "",
                        icon: "",
                        depressed: ""
                      }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_c("v-icon", [_vm._v(" mdi-dots-vertical ")])],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "v-list",
        { attrs: { dense: "" } },
        [
          _c(
            "v-list-item",
            {
              attrs: { link: "" },
              on: {
                click: function($event) {
                  return _vm.clickRename()
                }
              }
            },
            [
              _c(
                "v-list-item-subtitle",
                [
                  _c("v-icon", { staticClass: "mr-3", attrs: { size: "16" } }, [
                    _vm._v(" $paper_edit_outline ")
                  ]),
                  _c("span", [
                    _vm._v(" " + _vm._s(_vm.$t("papers.btn.rename")))
                  ])
                ],
                1
              )
            ],
            1
          ),
          !_vm.itemActive
            ? _c("draft-remove-button", {
                attrs: { "item-id": _vm.itemId },
                on: {
                  submitted: function($event) {
                    return _vm.afterRemoved($event)
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }