var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "1300px" },
      on: {
        input: function($event) {
          return _vm.changeDialog($event)
        }
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "py-4",
                      attrs: {
                        color: "primary",
                        outlined: "",
                        depressed: "",
                        tile: "",
                        "x-small": ""
                      }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_c("v-icon", { attrs: { small: "" } }, [_vm._v(" mdi-eye ")])],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { attrs: { cols: "8" } }, [
            _c("div", { staticClass: "example py-6 px-8 h-100" }, [
              _c(
                "div",
                { staticClass: "example-preview text-center overflow-auto" },
                [
                  _vm.isLoading
                    ? _c(
                        "div",
                        {
                          staticClass: "pa-10",
                          style: { height: _vm.height + "px" }
                        },
                        [
                          _c("v-progress-circular", {
                            attrs: { indeterminate: "", color: "#ffffff" }
                          })
                        ],
                        1
                      )
                    : _vm._l(_vm.pages, function(page, i) {
                        return _c("div", {
                          key: "example-" + i,
                          staticClass:
                            "d-inline-block relative white overflow-hidden",
                          class: {
                            "canvas--black-mode": _vm.color === "black"
                          },
                          style: {
                            width: _vm.width + "px",
                            height: _vm.height + "px"
                          },
                          domProps: { innerHTML: _vm._s(page) }
                        })
                      })
                ],
                2
              )
            ])
          ]),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "v-sheet",
                { staticClass: "py-6 px-4", attrs: { height: "100%" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex mb-3" },
                    [
                      _c(
                        "div",
                        { staticClass: "font-weight-medium secondary--text" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("papers.builder.example.title")) +
                              " "
                          )
                        ]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "dialog-close-tr",
                          attrs: { ripple: false, icon: "", "x-small": "" },
                          on: {
                            click: function($event) {
                              return _vm.setDialog(false)
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "#C8C8C8", small: "" } },
                            [_vm._v(" mdi-close ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "example-subtitle mt-2 mb-4" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("papers.builder.example.subtitle")) +
                        " "
                    )
                  ]),
                  _c(
                    "v-row",
                    { staticClass: "mb-4", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("text-field-label", {
                            staticClass: "mr-2",
                            attrs: {
                              label: _vm.$t("papers.builder.example.amount")
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "5" } }, [
                        _c(
                          "div",
                          { staticClass: "mr-2" },
                          [
                            _c("v-text-field", {
                              staticClass: "app-style",
                              attrs: {
                                value: 2,
                                disabled: _vm.isLoading,
                                "background-color": "#ffffff",
                                type: "number",
                                min: "1",
                                max: "100",
                                "hide-details": "",
                                outlined: "",
                                dense: ""
                              },
                              on: {
                                input: function($event) {
                                  return _vm.debounceSetAmount($event)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("text-field-label", {
                            staticClass: "mr-2",
                            attrs: {
                              label: _vm.$t("papers.builder.example.color")
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "5" } }, [
                        _c(
                          "div",
                          { staticClass: "mr-2" },
                          [
                            _c("v-select", {
                              staticClass: "app-style",
                              attrs: {
                                items: _vm.colors,
                                disabled: _vm.isLoading,
                                "item-text": "name",
                                "item-value": "value",
                                "background-color": "#ffffff",
                                "hide-details": "",
                                outlined: "",
                                dense: ""
                              },
                              model: {
                                value: _vm.color,
                                callback: function($$v) {
                                  _vm.color = $$v
                                },
                                expression: "color"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }