var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      attrs: {
        loading: _vm.submitting,
        color: "primary",
        rounded: "",
        depressed: ""
      },
      on: {
        click: function($event) {
          return _vm.submit()
        }
      }
    },
    [_vm._v(" " + _vm._s(_vm.$t("papers.builder.draft.select")) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }