var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "900px", scrollable: "" },
      on: {
        input: function($event) {
          return _vm.changeDialog($event)
        }
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: { color: "primary", depressed: "", outlined: "" }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("papers.builder.draft.views")) + " "
                  )
                ]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "pb-0" },
            [
              _c("v-spacer"),
              _c(
                "v-icon",
                {
                  attrs: { color: "#C8C8C8", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.setDialog(false)
                    }
                  }
                },
                [_vm._v(" mdi-close ")]
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "px-8" },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                _vm._l(_vm.papers, function(item) {
                  return _c(
                    "v-col",
                    {
                      key: "draft-item-" + item.id + "-" + item.updatedAt,
                      attrs: { cols: "4" }
                    },
                    [
                      _c("draft-card", {
                        attrs: {
                          "item-id": item.id,
                          "item-name": item.name,
                          "item-active": item.active,
                          "item-canvas": item.canvas,
                          "item-width": item.width,
                          "item-height": item.height,
                          "item-paper-size": item.paperSize
                        },
                        on: {
                          "after:renamed": function($event) {
                            return _vm.reload($event)
                          },
                          "after:removed": function($event) {
                            return _vm.reload($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              ),
              !_vm.papers.length
                ? _c("div", { staticClass: "text-center pa-4" }, [
                    _vm._v(" " + _vm._s(_vm.$t("app.noitem")) + " ")
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }