var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "paper-builder" },
    [
      _c("action-bar"),
      _c("div", { staticClass: "pb-6 px-6 w-100" }, [
        _c("div", { staticClass: "d-flex justify-space-between w-100" }, [
          _c(
            "div",
            { staticClass: "mr-5 overflow-auto" },
            [
              _c(
                "div",
                { staticClass: "d-flex align-center pt-2 pb-2" },
                [
                  _c("name-form"),
                  _c("v-spacer"),
                  _c("select-all-button", { staticClass: "mr-2" }),
                  _c("example")
                ],
                1
              ),
              _c(
                "v-sheet",
                { staticClass: "my-grid", attrs: { width: "100%" } },
                [_c("st-canvas")],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-13" },
            [
              _c(
                "v-sheet",
                {
                  attrs: {
                    "min-width": _vm.toolWidth,
                    width: _vm.toolWidth,
                    "max-height": 730
                  }
                },
                [_c("draggable-elements")],
                1
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }