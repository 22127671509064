var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100 h-100" },
    [
      _vm.backgroundImage
        ? _c("v-img", {
            style: {
              width: "100%",
              height: "100%",
              fontSize: _vm.fontSize,
              overflow: _vm.overflow,
              backgroundImage: _vm.backgroundImage,
              backgroundPosition: _vm.backgroundPosition,
              backgroundSize: _vm.backgroundSize
            }
          })
        : _c(
            "div",
            {
              staticClass:
                "element-upload d-flex flex-column align-center justify-center"
            },
            [
              _c("v-icon", [_vm._v("mdi-image")]),
              _c(
                "label",
                {
                  ref: _vm.id + "-upload-label",
                  staticClass: "upload-label secondary--text",
                  attrs: { for: _vm.id + "-upload" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("papers.builder.elements.image.placeholder")
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "upload-input text-center p-2" },
                [
                  _c("file-upload", {
                    ref: "upload",
                    attrs: {
                      extensions: "gif,jpg,jpeg,png,webp",
                      accept: "image/png,image/gif,image/jpeg,image/webp",
                      name: _vm.id + "-upload",
                      drop: !_vm.edit
                    },
                    on: {
                      "input-filter": _vm.inputFilter,
                      "input-file": _vm.inputFile
                    },
                    model: {
                      value: _vm.files,
                      callback: function($$v) {
                        _vm.files = $$v
                      },
                      expression: "files"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }