<template>
  <v-dialog
    v-model="dialog"
    max-width="480px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        color="primary"
        x-small
        icon
        v-on="on">
        <v-icon
          size="17">
          $paper_cog
        </v-icon>
      </v-btn>
    </template>
    <v-card
      color="#FBFBFB"
      tile>
      <v-card-title class="px-4 pb-1">
        <title-label :label="title" />
        <v-spacer />
        <v-icon
          class="dialog-close-tr"
          color="#C8C8C8"
          small
          @click="setDialog(false)">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="px-4">
        <v-tabs
          v-model="tab"
          :show-arrows="false"
          :height="30"
          :slider-size="1"
          class="mb-4"
          background-color="#FBFBFB">
          <v-tab
            v-for="item in tabs"
            :key="`tab-create-${item.tab}`"
            :ripple="false"
            class="no-hover--bg-color text-default--color font-weight-regular px-0 mr-4">
            {{ item.name }}
          </v-tab>
          <div class="tab-silder-full" />
        </v-tabs>
        <v-tabs-items
          v-model="tab"
          class="table-setting-data--wrapper">
          <v-tab-item>
            <div>
              <v-row no-gutters>
                <v-col cols="5">
                  <v-list
                    class="list-column-select"
                    flat
                    dense
                    single-line>
                    <v-list-item class="data-header px-0">
                      <template v-slot:default>
                        <v-list-item-action>
                          <v-checkbox
                            v-model="selectedAll"
                            dense
                            @change="selectAll($event)" />
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t('papers.builder.settings.table.data.info') }}
                            <field-add-button />
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                    <v-list-item-group
                      v-model="selected"
                      multiple
                      active-class="">
                      <v-list-item
                        v-for="(column, index) in columnWithOutSelected"
                        :key="`item-${index}-${column.field}`"
                        class="px-0">
                        <template v-slot:default="{ active }">
                          <v-list-item-action>
                            <v-checkbox
                              :input-value="active"
                              dense />
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>{{ column.display }}</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon
                              v-if="column.type ==='addition'"
                              color="#c6c6c6"
                              @click="removeAddition(column.field)">
                              $paper_trash_outline
                            </v-icon>
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                      <div
                        v-if="!columnWithOutSelected.length"
                        class="pa-2 text-center">
                        No data
                      </div>
                    </v-list-item-group>
                  </v-list>
                </v-col>
                <v-col cols="2">
                  <div class="d-flex h-100 flex-column justify-center align-center">
                    <v-btn
                      :disabled="!selected.length"
                      class="mb-1"
                      color="primary"
                      icon
                      small
                      outlined
                      @click="select()">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-btn
                      :disabled="!deselected.length"
                      color="primary"
                      icon
                      small
                      outlined
                      @click="deselect()">
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="5">
                  <v-list
                    class="list-column-select"
                    flat
                    dense
                    single-line>
                    <v-list-item class="data-header px-0">
                      <template v-slot:default>
                        <v-list-item-action>
                          <v-checkbox
                            v-model="deselectedAll"
                            dense
                            @change="deselectAll($event)" />
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t('papers.builder.settings.table.data.selected') }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                    <v-list-item-group
                      v-model="deselected"
                      multiple
                      active-class="">
                      <v-list-item
                        v-for="(column, index) in columnSelected"
                        :key="`item-${index}-${column.field}`"
                        class="px-0">
                        <template v-slot:default="{ active }">
                          <v-list-item-action>
                            <v-checkbox
                              :input-value="active"
                              dense></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>{{ column.display }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                      <div
                        v-if="!columnSelected.length"
                        class="pa-2 text-center">
                        No data
                      </div>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="table-setting-style--wrapper">
              <div class="mb-3 d-flex">
                {{ $t('papers.builder.settings.table.style.header') }}
                <v-spacer />
                <v-checkbox
                  :input-value="headerDisplay"
                  :label="$t('app.show')"
                  class="mt-0 pt-0"
                  true-value="table-row"
                  false-value="none"
                  dense
                  hide-details
                  @change="setElement('children.0.children.0.children.0.properties.style', {
                    display: $event
                  })" />
              </div>
              <div class="mb-3">
                <v-toolbar
                  class="text-element-editor"
                  flat
                  tile
                  dense>
                  <color-picker
                    :value="headerColor"
                    @input="setElement('children.0.children.0.children.0.properties.style', {
                      color: $event
                    })" />
                  <v-divider vertical></v-divider>
                  <v-btn-toggle
                    :value="[headerFontWeight, headerFontStyle]"
                    class="mx-1"
                    color="primary"
                    dense
                    borderless
                    multiple
                    @change="setHeaderStyle($event)">
                    <v-btn
                      value="bold"
                      text>
                      <v-icon>mdi-format-bold</v-icon>
                    </v-btn>
                    <v-btn
                      value="italic"
                      text>
                      <v-icon>mdi-format-italic</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                  <v-divider vertical></v-divider>
                  <div class="mx-1">
                    <v-select
                      :value="headerFontSize"
                      :items="fontSizeOptions"
                      class="app-style"
                      item-text="text"
                      item-value="value"
                      menu-props="auto"
                      hide-details
                      outlined
                      dense
                      @input="setElement('children.0.children.0.children.0.properties.style', {
                        fontSize: $event
                      })" />
                  </div>
                </v-toolbar>
              </div>
              <div>{{ $t('papers.builder.settings.table.style.example') }}</div>
              <div
                class="d-flex w-100 example--wrapper"
                :style="{
                  fontWeight: headerFontWeight,
                  fontStyle: headerFontStyle,
                  color: headerColor,
                }">
                <div style="width: 6%">
                  #
                </div>
                <div style="width: 24%">
                  {{ $t('papers.builder.settings.table.style.item') }}
                </div>
                <div style="width: 70%">
                  {{ $t('papers.builder.settings.table.style.price') }}
                </div>
              </div>
            </div>
            <div class="table-setting-style--wrapper">
              <div class="mb-3">
                {{ $t('papers.builder.settings.table.style.content') }}
              </div>
              <div class="mb-3">
                <v-toolbar
                  class="text-element-editor"
                  flat
                  tile
                  dense>
                  <color-picker
                    :value="contentColor"
                    @input="setElement('children.0.children.1.children.0.properties.style', {
                      color: $event
                    })" />
                  <v-divider vertical></v-divider>
                  <v-btn-toggle
                    :value="[contentFontWeight, contentFontStyle]"
                    class="mx-1"
                    color="primary"
                    dense
                    borderless
                    multiple
                    @change="setContentStyle($event)">
                    <v-btn
                      value="bold"
                      text>
                      <v-icon>mdi-format-bold</v-icon>
                    </v-btn>
                    <v-btn
                      value="italic"
                      text>
                      <v-icon>mdi-format-italic</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                  <v-divider vertical></v-divider>
                  <div class="mx-1">
                    <v-select
                      :value="contentFontSize"
                      :items="fontSizeOptions"
                      class="app-style"
                      item-text="text"
                      item-value="value"
                      menu-props="auto"
                      hide-details
                      outlined
                      dense
                      @input="setElement('children.0.children.1.children.0.properties.style', {
                        fontSize: $event
                      })" />
                  </div>
                </v-toolbar>
              </div>
              <div>{{ $t('papers.builder.settings.table.style.example') }}</div>
              <div
                class="d-flex w-100  example--wrapper"
                :style="{
                  fontWeight: contentFontWeight,
                  fontStyle: contentFontStyle,
                  color: contentColor,
                }">
                <div style="width: 6%">
                  #
                </div>
                <div style="width: 24%">
                  {{ $t('papers.builder.settings.table.style.item') }}
                </div>
                <div style="width: 70%">
                  {{ $t('papers.builder.settings.table.style.price') }}
                </div>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash'
import CalculatorParser from '@/assets/js/calculator-parser'
import { mapActions, mapGetters } from 'vuex'
import paperSettingMixin from '@/mixins/paperSettingMixin'
import { UPDATE_PAPERGROUP } from '@/resources/graphql'
import ColorPicker from '../ColorPicker.vue'
import FieldAddButton from './Table/FieldAddButton.vue'

export default {
  components: {
    ColorPicker,
    FieldAddButton
  },
  mixins: [paperSettingMixin],
  props: {
    title: {
      type: String,
      default: 'None'
    },
    // purchaseFields: {
    //   type: Array,
    //   default: () => ([])
    // },
    headerFontSize: {
      type: String,
      default: ''
    },
    headerFontWeight: {
      type: String,
      default: 'normal'
    },
    headerFontStyle: {
      type: String,
      default: 'normal'
    },
    headerDisplay: {
      type: String,
      default: 'table-row'
    },
    headerColor: {
      type: String,
      default: 'initial'
    },
    contentFontSize: {
      type: String,
      default: ''
    },
    contentFontWeight: {
      type: String,
      default: 'normal'
    },
    contentFontStyle: {
      type: String,
      default: 'normal'
    },
    contentColor: {
      type: String,
      default: 'initial'
    }
  },
  data () {
    return {
      dialog: false,
      fontSizeOptions: ['10px', '12px', '14px', '16px', '18px', '20px', '22px', '24px'],
      selectedAll: false,
      selected: [],
      deselectedAll: false,
      deselected: [],
      columnMore: [],
      tab: 'data',
      tabs: [{
        tab: 'data',
        name: this.$t('papers.builder.settings.table.data.title')
      },
      {
        tab: 'style',
        name: this.$t('papers.builder.settings.table.style.title')
      }]
    }
  },
  computed: {
    ...mapGetters({
      findEnum: 'Enum/find',
      paperBuilderId: 'App/paperBuilderId',
      findPaperBuilder: 'PaperBuilder/find',
      findTableField: 'TableField/find'
    }),
    paperOrderNumberEnum () {
      return this.findEnum('PaperOrderNumber')?.values || []
    },
    columns () {
      const paperFieldPurchase = this.findTableField('paperFieldPurchase')?.values || []
      const paper = this.findPaperBuilder(this.paperBuilderId)
      const additions = paper.savedCalculations.map((saved) => ({
        display: saved.name,
        field: saved.id,
        type: 'addition',
        value: saved.value
      }))
      return _.concat(paperFieldPurchase, additions)
    },
    columnSelected () {
      const table = this.findElement('children.0.children')
      const headers = table[0].children[0].children.map((item) => ({ display: item.children[0] }))
      const contents = table[1].children[0].children.map((item) => ({
        field: item.data || _.result(item, 'children[0].properties.src')
      }))
      return _.merge(headers, contents)
    },
    purchaseFields () {
      return this.columnSelected.map((item) => item.field)
    },
    columnWithOutSelected () {
      return this.columns.filter((item) => !this.purchaseFields.includes(item.field))
    }
  },
  methods: {
    ...mapActions({
      updatePaperBuilder: 'PaperBuilder/update'
    }),
    setHeaderStyle (values) {
      this.setElement('children.0.children.0.children.0.properties.style', {
        fontWeight: _.includes(values, 'bold') ? 'bold' : 'normal',
        fontStyle: _.includes(values, 'italic') ? 'italic' : 'normal'
      })
    },
    setContentStyle (values) {
      this.setElement('children.0.children.1.children.0.properties.style', {
        fontWeight: _.includes(values, 'bold') ? 'bold' : 'normal',
        fontStyle: _.includes(values, 'italic') ? 'italic' : 'normal'
      })
    },
    selectAll (checked) {
      const columnWithOutSelected = _.cloneDeep(this.columnWithOutSelected)
      this.selected = checked ? columnWithOutSelected.map((item, index) => index) : []
    },
    select () {
      const columnWithOutSelected = _.cloneDeep(this.columnWithOutSelected)
      const selected = this.selected.map((value) => columnWithOutSelected[value].field)
      this.selected = []
      this.changeFields(
        _.concat(this.purchaseFields, selected)
      )
    },
    deselectAll (checked) {
      const columnSelected = _.cloneDeep(this.columnSelected)
      this.deselected = checked ? columnSelected.map((item, index) => index) : []
    },
    deselect () {
      const columnSelected = _.cloneDeep(this.columnSelected)
      const deselected = this.deselected.map((value) => columnSelected[value].field)
      this.deselected = []
      this.changeFields(
        _.pull(this.purchaseFields, ...deselected)
      )
    },
    async removeAddition (id) {
      const paper = this.findPaperBuilder(this.paperBuilderId)
      const savedCalculations = paper.savedCalculations.filter((item) => item.id !== id)
      await this.$apollo.mutate({
        mutation: UPDATE_PAPERGROUP,
        variables: {
          input: {
            id: this.paperBuilderId,
            savedCalculations
          }
        }
      })
      this.updatePaperBuilder({
        id: this.paperBuilderId,
        savedCalculations
      })
    },
    clearSuffixPixel (str) {
      return _.toNumber(
        _.replace(str, 'px', '')
      )
    },
    changeFields (fields) {
      const headerChildren = []
      const contentChildren = []
      fields.forEach((field) => {
        const column = this.columns.find((item) => item.field === field)
        if (column) {
          let textAlign = 'left'
          if (column.type !== 'string') {
            textAlign = 'right'
          }
          headerChildren.push({
            tag: 'td',
            data: null,
            fx: {},
            properties: {
              style: {
                textAlign,
                padding: '0 4px'
              }
            },
            children: [column.display]
          })
          if (column.type === 'addition') {
            const calculator = new CalculatorParser(column.value)
            const series = calculator
              .toSeries()
              .filter((serie) => _.includes(['number', 'field', 'associate', 'operator'], serie.type))
            let count = 1
            let fx = {
              decimal: {
                name: 'decimal',
                value: 2
              },
              0: {
                name: 'plus',
                value: _.head(series).value
              }
            }
            _.tail(series).forEach((point) => {
              if (point.type === 'operator') {
                fx = _.merge(fx, {
                  [count]: {
                    name: point.value
                  }
                })
              } else {
                fx = _.merge(fx, {
                  [count]: {
                    value: point.value
                  }
                })
                count++
              }
            })
            contentChildren.push({
              tag: 'td',
              data: column.field,
              fx,
              properties: {
                style: {
                  textAlign,
                  borderTop: '1px solid #ddd',
                  borderBottom: '1px solid #ddd',
                  padding: '0 4px'
                }
              },
              children: []
            })
          } else if (column.type === 'url') {
            const rowHeight = this.getResult('children.0.children.1.children.0.properties.style.height', '20px')
            const imageSize = this.clearSuffixPixel(rowHeight) - 12
            contentChildren.push({
              tag: 'td',
              data: null,
              fx: {},
              properties: {
                style: {
                  textAlign,
                  borderTop: '1px solid #ddd',
                  borderBottom: '1px solid #ddd',
                  padding: '0 4px'
                }
              },
              children: [{
                tag: 'img',
                data: null,
                fx: {},
                properties: {
                  src: column.field,
                  style: {
                    height: `${imageSize}px`,
                    width: `${imageSize}px`
                  }
                }
              }]
            })
          } else if (column.type === 'decimal') {
            contentChildren.push({
              tag: 'td',
              data: column.field,
              fx: {
                decimal: {
                  name: 'decimal',
                  value: 2
                }
              },
              properties: {
                style: {
                  textAlign,
                  borderTop: '1px solid #ddd',
                  borderBottom: '1px solid #ddd',
                  padding: '0 4px'
                }
              },
              children: []
            })
          } else {
            contentChildren.push({
              tag: 'td',
              data: column.field,
              fx: {},
              properties: {
                style: {
                  textAlign,
                  borderTop: '1px solid #ddd',
                  borderBottom: '1px solid #ddd',
                  padding: '0 4px'
                }
              },
              children: []
            })
          }
        } else {
          const table = this.findElement('children.0.children')
          const index = _.findIndex(table[1].children[0].children, { data: field })
          headerChildren.push(table[0].children[0].children[index])
          contentChildren.push(table[1].children[0].children[index])
        }
      })

      const table = this.findElement('children.0.children')
      table[0].children[0].children = headerChildren
      table[1].children[0].children = contentChildren
      this.setElement('children.0.children', table)
    },
    setDialog (dialog = false) {
      this.dialog = dialog
    }
  }
}
</script>
<style scoped>
  .list-column-select {
    border: 1px solid #E4E4E4;
  }
  .data-header {
    border-bottom: 1px solid #E4E4E4;
  }
  .text-element-editor {
    border: 1px solid #E4E4E4;

  }
  .table-setting-data--wrapper {
    background-color: #FBFBFB;
  }
  .table-setting-style--wrapper {
    border: 1px solid #E4E4E4;
    border-radius: 2px;
    padding: 12px;
    margin-bottom: 16px;
  }
  .example--wrapper {
    padding: 10px 0;
    border-bottom: 1px solid #E4E4E4;
  }
</style>
