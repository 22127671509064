var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "snt-form-name d-flex align-center" },
    [
      _vm.renaming
        ? _c(
            "v-form",
            {
              ref: "form",
              staticClass: "d-flex align-center",
              attrs: { readonly: _vm.submitting },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submit()
                }
              },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _vm.renaming
                ? _c("v-text-field", {
                    staticClass: "app-style mr-2",
                    attrs: {
                      rules: _vm.rule.name,
                      "error-messages": _vm.error.name.length
                        ? [_vm.$t("papers.form.create.nameError")]
                        : [],
                      autocomplete: "off",
                      "background-color": "#ffffff",
                      autofocus: "",
                      "hide-details": "",
                      outlined: "",
                      dense: ""
                    },
                    on: {
                      input: function($event) {
                        return _vm.clearError("name")
                      }
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    loading: _vm.submitting,
                    disabled: !_vm.valid,
                    rounded: "",
                    small: "",
                    text: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.submit()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("app.btn.submit")) + " ")]
              ),
              _vm.error.name.length
                ? _c("div", { staticClass: "mx-3 error--text caption" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("papers.form.create.nameError")) + " "
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "w-100 d-flex align-center" },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { disabled: _vm.name.length < 64, bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "div",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass:
                                    "w-100 font-weight-medium secondary--text mr-4",
                                  class: {
                                    "text-overflow": _vm.name.length >= 64
                                  }
                                },
                                "div",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_vm._v(" " + _vm._s(_vm.name) + " ")]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        "max-width": "320",
                        color: "transparent",
                        flat: "",
                        dark: ""
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.name) + " ")]
                  )
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { ripple: false, icon: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.setRenaming(true)
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(" mdi-pencil ")
                  ])
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }