var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "text-editor-inline",
    {
      attrs: {
        value: _vm.active,
        "position-y": _vm.positionY,
        "font-size": _vm.fontSize,
        "text-align": _vm.textAlign,
        "font-weight": _vm.fontWeight,
        "font-style": _vm.fontStyle,
        "text-decoration": _vm.textDecoration,
        color: _vm.color
      },
      on: {
        input: function(key, value) {
          return _vm.setElement(key, value)
        }
      }
    },
    [
      _c("textarea", {
        ref: "textElement",
        staticClass: "input-element-style",
        style: {
          fontSize: _vm.fontSize,
          textAlign: _vm.textAlign,
          fontWeight: _vm.fontWeight,
          fontStyle: _vm.fontStyle,
          textDecoration: _vm.textDecoration,
          color: _vm.color
        },
        attrs: { rows: "1" },
        domProps: { value: _vm.text },
        on: {
          input: function($event) {
            return _vm.debounceSetTitle($event.target.value)
          },
          change: function($event) {
            return _vm.changeTitle($event.target.value)
          }
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }