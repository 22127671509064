<template>
  <v-dialog
    v-model="dialog"
    max-width="900px"
    scrollable
    @input="changeDialog($event)">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        depressed
        outlined
        v-bind="attrs"
        v-on="on">
        {{ $t('papers.builder.draft.views') }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="pb-0">
        <v-spacer />
        <v-icon
          color="#C8C8C8"
          small
          @click="setDialog(false)">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="px-8">
        <v-row no-gutters>
          <v-col
            v-for="(item) in papers"
            :key="`draft-item-${item.id}-${item.updatedAt}`"
            cols="4">
            <draft-card
              :item-id="item.id"
              :item-name="item.name"
              :item-active="item.active"
              :item-canvas="item.canvas"
              :item-width="item.width"
              :item-height="item.height"
              :item-paper-size="item.paperSize"
              @after:renamed="reload($event)"
              @after:removed="reload($event)" />
          </v-col>
        </v-row>
        <div
          v-if="!papers.length"
          class="text-center pa-4">
          {{ $t('app.noitem') }}
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { GET_PAPERGROUP } from '@/resources/graphql'
import DraftCard from './DraftCard.vue'

export default {
  apollo: {
    paperGroup: () => ({
      query: GET_PAPERGROUP,
      fetchPolicy: 'no-cache',
      variables () {
        return {
          id: this.paperBuilderId
        }
      },
      result ({ data: { paperGroup } }) {
        this.updatePaperBuilder({
          id: paperGroup.id,
          papers: paperGroup.papers.nodes
        })
      }
    })
  },
  components: {
    DraftCard
  },
  data () {
    return {
      dialog: false,
      renaming: false
    }
  },
  computed: {
    ...mapGetters({
      paperBuilderId: 'App/paperBuilderId',
      findPaperBuilder: 'PaperBuilder/find'
    }),
    paperBuilder () {
      return this.findPaperBuilder(this.paperBuilderId)
    },
    papers () {
      return this.paperBuilder?.papers || []
    }
  },
  methods: {
    ...mapActions({
      updatePaperBuilder: 'PaperBuilder/update'
    }),
    changeDialog (dialog) {
      if (dialog) {
        this.reload()
      }
    },
    setDialog (dialog = false) {
      this.dialog = dialog
    },
    reload () {
      this.$apollo.queries.paperGroup.refetch()
    }
  }
}
</script>
<style scoped>
</style>
