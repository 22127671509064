<template>
  <div class="action-container">
    <div class="action-bar pa-4 d-flex justify-space-between align-center">
      <cancel-button />
      <v-spacer />
      <new-draft />
      <print-button />
      <save-draft-button class="mr-2" />
      <publish-button />
    </div>
    <div class="action-bar pa-4 d-flex justify-space-between align-center">
      <div class="ml-4 mr-3">
        <span class="secondary--text">
          {{ $t('papers.builder.size') }}
        </span>
      </div>
      <div class="mr-4">
        <v-select
          :value="paperSize"
          :items="paperGetSize"
          class="size-style"
          item-value="key"
          item-text="display_name"
          return-object
          hide-details
          outlined
          dense
          @input="setInnerSize($event)" />
      </div>
      <div class="mr-3">
        <span class="secondary--text">
          {{ $t('papers.builder.condition') }}
        </span>
      </div>
      <div class="mr-4">
        <v-select
          :value="paperCondition"
          :items="paperConditions"
          class="condition-style"
          item-value="name"
          item-text="description"
          hide-details
          outlined
          dense
          @input="setCondition($event)" />
      </div>
      <v-icon
        :disabled="cantUndo"
        class="mr-4"
        size="16"
        :color="cantUndo ? '#D1D1D1' : '#6C6C6C'"
        @click="undo()">
        $paper_left_turn_arrow
      </v-icon>
      <v-icon
        :disabled="cantRedo"
        size="16"
        :color="cantRedo ? '#D1D1D1' : '#6C6C6C'"
        @click="redo()">
        $paper_right_turn_arrow
      </v-icon>
      <v-spacer />
      <draft-selector />
    </div>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
import { GET_PAPERGETSIZE } from '@/resources/graphql'
import CancelButton from './CancelButton.vue'
import DraftSelector from './DraftSelector.vue'
import NewDraft from './NewDraft.vue'
import PrintButton from './PrintButton.vue'
import PublishButton from './PublishButton.vue'
import SaveDraftButton from './SaveDraftButton.vue'

export default {
  components: { DraftSelector, NewDraft, SaveDraftButton, PublishButton, CancelButton, PrintButton },
  apollo: {
    paperGetSize: () => ({
      query: GET_PAPERGETSIZE
    })
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
      findEnum: 'Enum/find',
      paperBuilderId: 'App/paperBuilderId',
      findPaperBuilder: 'PaperBuilder/find'
    }),
    paperConditions () {
      return this.findEnum('paperConditionEnum')?.values || []
    },
    paperBuilder () {
      return this.findPaperBuilder(this.paperBuilderId)
    },
    paperSize () {
      return this.paperBuilder?.size
    },
    paperCondition () {
      return this.paperBuilder?.condition
    },
    histories () {
      return this.paperBuilder?.histories || []
    },
    cursor () {
      return this.paperBuilder?.cursor
    },
    cantUndo () {
      return !this.histories.length || this.cursor >= this.histories.length - 1
    },
    cantRedo () {
      return !this.cursor
    }
  },
  methods: {
    ...mapActions({
      updatePaperBuilder: 'PaperBuilder/update',
      undoPaperBuilder: 'PaperBuilder/undoCanvas',
      redoPaperBuilder: 'PaperBuilder/redoCanvas'
    }),
    setInnerSize ({ key, width, height }) {
      this.updatePaperBuilder({
        id: this.paperBuilderId,
        size: key,
        width,
        height: height || 2000,
        innerWidth: width,
        innerHeight: height || 2000
      })
    },
    setCondition (newValue) {
      this.updatePaperBuilder({
        id: this.paperBuilderId,
        condition: newValue
      })
    },
    undo () {
      this.undoPaperBuilder({
        id: this.paperBuilderId
      })
    },
    redo () {
      this.redoPaperBuilder({
        id: this.paperBuilderId
      })
    }
  }
}
</script>
<style scoped>
  .action-bar {
    height: 69px;
    background-color: #ffffff;
    width: 100%;
    border-bottom: 1px solid #E4E4E4;
  }
  .size-style {
    width: 120px;
  }
  .condition-style {
    width: 240px;
  }
</style>
<style>
  .size-style.v-select .v-select__selection--comma,
  .condition-style.v-select .v-select__selection--comma {
    color: #6C6C6C !important;
  }
  .size-style.v-text-field--outlined fieldset,
  .condition-style.v-text-field--outlined fieldset {
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 2px;
  }
</style>
