import { render, staticRenderFns } from "./FormulaElement.vue?vue&type=template&id=8f43b8be&scoped=true&"
import script from "./FormulaElement.vue?vue&type=script&lang=js&"
export * from "./FormulaElement.vue?vue&type=script&lang=js&"
import style0 from "./FormulaElement.vue?vue&type=style&index=0&id=8f43b8be&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f43b8be",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VDialog,VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8f43b8be')) {
      api.createRecord('8f43b8be', component.options)
    } else {
      api.reload('8f43b8be', component.options)
    }
    module.hot.accept("./FormulaElement.vue?vue&type=template&id=8f43b8be&scoped=true&", function () {
      api.rerender('8f43b8be', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Papers/pages/Paper/components/Elements/FormulaElement.vue"
export default component.exports