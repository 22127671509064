<template>
  <div
    class="w-100 h-100">
    <v-img
      v-if="backgroundImage"
      :style="{
        width: '100%',
        height: '100%',
        fontSize,
        overflow,
        backgroundImage,
        backgroundPosition,
        backgroundSize
      }"
    >
    </v-img>
    <div
      v-else
      class="element-upload d-flex flex-column align-center justify-center">
      <v-icon>mdi-image</v-icon>
      <label
        :ref="`${id}-upload-label`"
        class="upload-label secondary--text"
        :for="`${id}-upload`">
        {{ $t(`papers.builder.elements.image.placeholder`) }}
      </label>
      <div class="upload-input text-center p-2">
        <file-upload
          :ref="`upload`"
          v-model="files"
          :extensions="'gif,jpg,jpeg,png,webp'"
          :accept="'image/png,image/gif,image/jpeg,image/webp'"
          :name="`${id}-upload`"
          :drop="!edit"
          @input-filter="inputFilter"
          @input-file="inputFile" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import paperElementMixin from '@/mixins/paperElementMixin'
import paperSettingMixin from '@/mixins/paperSettingMixin'

import FileUpload from 'vue-upload-component'

export default {
  components: {
    FileUpload
  },
  mixins: [paperElementMixin, paperSettingMixin],
  props: {
    backgroundImage: {
      type: String,
      default: null
    },
    backgroundPosition: {
      type: String,
      default: 'center center'
    },
    backgroundSize: {
      type: String,
      default: null
    },
    fontSize: {
      type: String,
      default: '14px'
    },
    overflow: {
      type: String,
      default: 'initial'
    }
  },
  data () {
    return {
      files: [],
      edit: false,
      error: ''
    }
  },
  methods: {
    async inputFile (newFile, oldFile) {
      if (newFile && !oldFile) {
        this.$nextTick(async () => {
          if (this.confirm) {
            this.edit = true
          } else {
            const file = this.files[0]
            const base64 = await this.toBase64(file)
            this.setElement('properties.style.backgroundImage', `url(${base64})`)
            this.edit = false
          }
        })
      }
      if (!newFile && oldFile) {
        this.edit = false
      }
    },
    toBase64 (newFile) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(newFile.file)
        reader.onloadend = () => resolve(reader.result)
      })
    },
    async inputFilter (newFile, oldFile, prevent) {
      this.error = null
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.error = '* Your choice is not a picture'
          return prevent()
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        const URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    }
  }

}
</script>
<style scoped>
  img {
    width: 100%;
  }
</style>
<style>

  .element-upload {
    height: 100%;
    width: 100%;
    background: #C4C4C4;
  }
  .upload-label {
    margin: 6px 0 0;
    cursor: pointer;
    font-size: 12px;
  }
  .upload-input {
    display: none;
  }
  .overlay {
    height: 100%;
    background-color: rgba(245, 245, 245, .96);
  }
</style>
