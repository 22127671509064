<template>
  <div
    :style="{
      width: '100%',
      height: '100%',
      fontSize,
      overflow
    }">
    {{ text }}
  </div>
</template>

<script>
import paperElementMixin from '@/mixins/paperElementMixin'
import paperSettingMixin from '@/mixins/paperSettingMixin'

export default {
  mixins: [paperElementMixin, paperSettingMixin],
  props: {
    fontSize: {
      type: String,
      default: '14px'
    },
    textAlign: {
      type: String,
      default: 'left'
    },
    fontWeight: {
      type: String,
      default: 'normal'
    },
    fontStyle: {
      type: String,
      default: 'normal'
    },
    textDecoration: {
      type: String,
      default: 'none'
    },
    color: {
      type: String,
      default: 'initial'
    },
    overflow: {
      type: String,
      default: 'initial'
    },
    numberDisplayType: {
      type: String,
      default: ''
    }
  },
  computed: {
    text () {
      let text = '100.00'
      if (this.numberDisplayType === 'number_to_word') {
        text = 'หนึ่งหมื่นจุดศูนย์ศูนย์'
      }
      return text
    }
  }

}
</script>
<style scoped>
</style>
