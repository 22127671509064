var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    style: {
      width: "100%",
      height: "100%",
      borderRadius: _vm.borderRadius,
      backgroundColor: _vm.backgroundColor,
      borderStyle: _vm.borderStyle,
      borderTopWidth: _vm.borderTopWidth,
      borderLeftWidth: _vm.borderLeftWidth,
      borderRightWidth: _vm.borderRightWidth,
      borderBottomWidth: _vm.borderBottomWidth,
      paddingTop: _vm.paddingTop,
      borderColor: _vm.borderColor
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }