<template>
  <table
    class="w-100 table-purchase"
    dense
    @mousemove="handleMousemove($event)"
    @mouseup="handleMouseup($event)"
    @mouseleave="handleMouseup($event)">
    <thead v-if="showHeader">
      <draggable
        :value="datasource"
        :style="headerStyles"
        class="d-table-row"
        handle=".drag-col-handle"
        tag="tr"
        @input="sortable($event)">
        <td
          v-for="(source, index) in datasource"
          :key="`purchase-header-${source.field}`"
          :style="source.hProperties.style"
          scope="col">
          <div class="d-flex">
            <div class="w-100">
              <v-icon
                class="drag-col-handle"
                size="10">
                $paper_drag
              </v-icon>
              {{ source.display }}
            </div>
            <v-spacer />
            <span
              v-if="index !== datasource.length - 1"
              :ref="`resizeHandle${index}`"
              class="resize-handle"
              @mousedown="handleMousedown($event, index)">
              <v-icon
                size="14">
                $paper_resize_column
              </v-icon>
            </span>
          </div>
        </td>
      </draggable>
    </thead>
    <tbody>
      <template v-if="!showHeader">
        <draggable
          :value="datasource"
          :style="contentStyles"
          class="d-table-row"
          handle=".drag-col-handle"
          tag="tr"
          @input="sortable($event)">
          <template v-for="(source, index) in datasource">
            <td
              v-if="source.data.type === 'string'"
              :key="`purchase-header-${source.field}`"
              :style="source.cProperties.style"
              scope="col">
              <div class="d-flex">
                <div class="w-100">
                  <v-icon
                    class="drag-col-handle"
                    size="10">
                    $paper_drag
                  </v-icon>
                  {{ $t('papers.builder.elements.table.product', [1]) }}
                </div>
                <v-spacer />
                <span
                  v-if="index !== datasource.length - 1"
                  :ref="`resizeHandle${index}`"
                  class="resize-handle"
                  @mousedown="handleMousedown($event, index)">
                  <v-icon
                    size="14">
                    $paper_resize_column
                  </v-icon>
                </span>
              </div>
            </td>
            <td
              v-else-if="source.data.type === 'url'"
              :key="`purchase-td-handle-${source.field}`"
              :style="source.cProperties.style">
              <div class="d-flex">
                <v-spacer />
                <v-icon
                  class="drag-col-handle"
                  size="10">
                  $paper_drag
                </v-icon>
                <vue-draggable-resizable
                  :x="0"
                  :y="0"
                  :w="imgWidth"
                  :h="imgHeight"
                  :draggable="false"
                  :handles="['br']"
                  :active="true"
                  :prevent-deactivation="true"
                  :grid="[5,5]"
                  class="relative"
                  class-name="purchase-image-draggable"
                  @resizestop="(x, y, width, height) => resizeImage(width, height, index)">
                  <div class="purchase-image" />
                </vue-draggable-resizable>
                <span
                  v-if="index !== datasource.length - 1"
                  :ref="`resizeHandle${index}`"
                  class="resize-handle"
                  @mousedown="handleMousedown($event, index)">
                  <v-icon
                    size="14">
                    $paper_resize_column
                  </v-icon>
                </span>
              </div>
            </td>
            <td
              v-else
              :key="`purchase-header-${source.field}`"
              :style="source.cProperties.style"
              scope="col">
              <div class="d-flex">
                <div class="w-100">
                  <v-icon
                    class="drag-col-handle"
                    size="10">
                    $paper_drag
                  </v-icon>
                  100.00
                </div>
                <v-spacer />
                <span
                  v-if="index !== datasource.length - 1"
                  :ref="`resizeHandle${index}`"
                  class="resize-handle"
                  @mousedown="handleMousedown($event, index)">
                  <v-icon
                    size="14">
                    $paper_resize_column
                  </v-icon>
                </span>
              </div>
            </td>
          </template>
        </draggable>
      </template>
      <tr
        v-if="showHeader"
        :style="contentStyles">
        <template v-for="(source, index) in datasource">
          <td
            v-if="source.data.type === 'string'"
            :key="`purchase-td-handle-${source.field}`"
            :style="source.cProperties.style">
            {{ $t('papers.builder.elements.table.product', [1]) }}
          </td>
          <td
            v-else-if="source.data.type === 'url'"
            :key="`purchase-td-handle-${source.field}`"
            :style="source.cProperties.style">
            <vue-draggable-resizable
              :x="0"
              :y="0"
              :w="imgWidth"
              :h="imgHeight"
              :draggable="false"
              :handles="['br']"
              :active="true"
              :prevent-deactivation="true"
              :grid="[5,5]"
              class="relative"
              class-name="purchase-image-draggable"
              @resizestop="(x, y, width, height) => resizeImage(width, height, index)">
              <div class="purchase-image" />
            </vue-draggable-resizable>
          </td>
          <td
            v-else
            :key="`purchase-td-handle-${source.field}`"
            :style="source.cProperties.style">
            100.00
          </td>
        </template>
      </tr>
      <template>
        <tr
          v-for="item in 1"
          :key="`purchase-body-${item}`"
          :style="contentStyles">
          <td
            v-for="source in datasource"
            :key="`purchase-td-${source.field}`"
            :style="source.cProperties.style">
            <template v-if="source.data.type === 'string'">
              {{ $t('papers.builder.elements.table.product', [item + 1]) }}
            </template>
            <template v-else-if="source.data.type === 'url'">
              <img
                :width="imgWidth"
                :height="imgHeight"
                src="https://picsum.photos/100/100?image=30" />
            </template>
            <template v-else>
              100.00
            </template>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import paperElementMixin from '@/mixins/paperElementMixin'
import paperSettingMixin from '@/mixins/paperSettingMixin'
import Draggable from 'vuedraggable'

export default {
  components: {
    Draggable
  },
  mixins: [paperElementMixin, paperSettingMixin],
  props: {
    purchaseFields: {
      type: Array,
      default: () => ([])
    },
    borderTop: {
      type: String,
      default: ''
    },
    headerFontSize: {
      type: String,
      default: ''
    },
    contentFontSize: {
      type: String,
      default: ''
    },
    headerDisplay: {
      type: String,
      default: 'table-row'
    }
  },
  data () {
    return {
      imgWidth: 25,
      imgHeight: 25,
      colIndex: undefined,
      pageX: undefined,
      curCol: undefined,
      nxtCol: undefined,
      curColWidth: undefined,
      nxtColWidth: undefined
    }
  },
  computed: {
    ...mapGetters({
      paperBuilderId: 'App/paperBuilderId',
      findPaperBuilder: 'PaperBuilder/find',
      findTableField: 'TableField/find'
    }),
    showHeader () {
      return this.headerDisplay !== 'none'
    },
    columns () {
      const paperFieldPurchase = this.findTableField('paperFieldPurchase')?.values || []
      const paper = this.findPaperBuilder(this.paperBuilderId)
      const additions = paper.savedCalculations.map((saved) => ({
        display: saved.name,
        field: saved.id,
        type: 'addition',
        value: saved.value
      }))
      return _.concat(paperFieldPurchase, additions)
    },
    datasource () {
      const hProperties = this.getResult('children.0.children.0.children.0.children', [])
        .map((props) => ({
          hProperties: _.cloneDeep(props.properties)
        }))
      const cProperties = this.getResult('children.0.children.1.children.0.children', [])
        .map((props) => ({
          cProperties: _.cloneDeep(props.properties)
        }))
      const properties = _.merge(hProperties, cProperties)
      const table = this.findElement('children.0.children')
      const headers = table[0].children[0].children.map((item) => ({ display: item.children[0] }))
      const contents = table[1].children[0].children.map((item) => ({
        field: item.data || _.result(item, 'children.0.properties.src')
      }))
      const doms = _.cloneDeep(_.merge(headers, contents))
      const datasource = _.cloneDeep(_.merge(doms, properties))
      return datasource.map((data) => ({
        ...data,
        data: _.find(this.columns, ['field', data.field])
      }))
    },
    headerStyles () {
      return this.getResult('children.0.children.0.children.0.properties.style')
    },
    contentStyles () {
      return this.getResult('children.0.children.1.children.0.properties.style')
    }
  },
  mounted () {
    const imageSize = this.getResult('children.0.children.1.children.0.properties.style.height', '25px')
    this.imgWidth = this.clearSuffixPixel(imageSize) - 12
    this.imgHeight = this.clearSuffixPixel(imageSize) - 12
  },
  methods: {
    ...mapActions({
      updatePaperBuilderCanvas: 'PaperBuilder/updateAllCanvas'
    }),
    clearSuffixPixel (str) {
      return _.toNumber(
        _.replace(str, 'px', '')
      )
    },
    resizeImage (width, height, index) {
      this.imgWidth = height
      this.imgHeight = height
      this.setElement(
        `children.0.children.1.children.0.children.${index}.children.0.properties.style.width`,
        `${width}px`,
        false
      )
      this.setElement(
        `children.0.children.1.children.0.children.${index}.children.0.properties.style.height`,
        `${height}px`,
        false
      )
      this.setElement(
        'children.0.children.1.children.0.properties.style.height',
        `${height + 12}px`
      )
    },
    getStyleVal (elm, css) {
      return (window.getComputedStyle(elm, null).getPropertyValue(css))
    },
    paddingDiff (col) {
      if (this.getStyleVal(col, 'box-sizing') === 'border-box') {
        return 0
      }
      const padLeft = this.getStyleVal(col, 'padding-left')
      const padRight = this.getStyleVal(col, 'padding-right')
      return (Number(padLeft) + Number(padRight))
    },
    handleMousedown (e, colIndex) {
      const td = e.path.find((item) => item.nodeName === 'TD')
      this.colIndex = colIndex
      this.curCol = td
      this.nxtCol = this.curCol.nextElementSibling
      this.pageX = e.pageX

      const padding = this.paddingDiff(this.curCol)

      this.curColWidth = this.curCol.offsetWidth - padding
      if (this.nxtCol) this.nxtColWidth = this.nxtCol.offsetWidth - padding
    },
    handleMousemove (e) {
      if (this.curCol) {
        const diffX = e.pageX - this.pageX
        if (this.nxtCol) this.nxtCol.style.width = `${this.nxtColWidth - (diffX)}px`
        this.curCol.style.width = `${this.curColWidth + diffX}px`
      }
    },
    handleMouseup () {
      if (this.colIndex !== undefined) {
        this.resizable(this.colIndex, this.curCol.style.width)
        if (this.nxtCol) {
          this.resizable(this.colIndex + 1, this.nxtCol.style.width)
        }
        this.colIndex = undefined
        this.curCol = undefined
        this.nxtCol = undefined
        this.pageX = undefined
        this.nxtColWidth = undefined
        this.curColWidth = undefined
      }
    },
    resizable (colIndex, width) {
      this.setElement(
        `children.0.children.0.children.0.children.${colIndex}.properties.style.width`,
        `${width}`
      )
    },
    sortable (items) {
      const ths = this.findElement('children.0.children.0.children.0.children')
      const tds = this.findElement('children.0.children.1.children.0.children')
      const headers = []
      const contents = []
      items.forEach((item) => {
        const index = _.findIndex(tds, (sub) => _.result(sub, 'children.0.properties.src', sub.data) === item.field)
        headers.push(ths[index])
        contents.push(tds[index])
      })
      this.setElement('children.0.children.0.children.0.children', headers)
      this.setElement('children.0.children.1.children.0.children', contents)
    }
  }
}
</script>
<style>
  .purchase-image-draggable {
    touch-action: none;
    position: absolute;
    box-sizing: border-box;
    display: inline-block;
  }
  .purchase-image {
    width: 100%;
    height: 100%;
    border: 1px solid #1BA7E1;
    background-color: #F5F5F5;
    background-image: url('https://picsum.photos/100/100?image=30');
    background-position: center center;
    background-size: contain;
  }
  .handle-br {
    bottom: -3px;
    right: -3px;
    background: transparent;
    border: 0;
    border-right: 1px solid #1BA7E1;
    border-bottom: 1px solid #1BA7E1;
  }
</style>
<style scoped>
  .resize-handle {
    cursor: e-resize;
    margin-left: 4px;
  }
  .drag-col-handle {
    cursor: move;
  }
  .table { display:table; }
  .row { display:table-row; }
  .cell {display:table-cell; }
  .table-purchase {
    border-collapse: collapse;
  }
  .p-table-img {
    background-position: center center;
    background-size: contain;
  }
</style>
