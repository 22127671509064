<template>
  <text-editor-inline
    :value="active"
    :position-y="positionY"
    :font-size="fontSize"
    :text-align="textAlign"
    :font-weight="fontWeight"
    :font-style="fontStyle"
    :text-decoration="textDecoration"
    :color="color"
    @input="(key, value) => setElement(key, value)">
    <textarea
      ref="textElement"
      :value="text"
      :style="{
        fontSize,
        textAlign,
        fontWeight,
        fontStyle,
        textDecoration,
        color,
      }"
      class="input-element-style"
      rows="1"
      @input="debounceSetTitle($event.target.value)"
      @change="changeTitle($event.target.value)"
    />
  </text-editor-inline>
</template>

<script>
import _ from 'lodash'
import { mapActions } from 'vuex'
import paperElementMixin from '@/mixins/paperElementMixin'
import paperSettingMixin from '@/mixins/paperSettingMixin'
import TextEditorInline from '../Widget/TextEditorInline.vue'

export default {
  components: {
    TextEditorInline
  },
  mixins: [paperElementMixin, paperSettingMixin],
  props: {
    active: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    fontSize: {
      type: String,
      default: '14px'
    },
    textAlign: {
      type: String,
      default: 'left'
    },
    fontWeight: {
      type: String,
      default: 'normal'
    },
    fontStyle: {
      type: String,
      default: 'normal'
    },
    textDecoration: {
      type: String,
      default: 'none'
    },
    color: {
      type: String,
      default: '#000000'
    },
    overflow: {
      type: String,
      default: 'initial'
    }
  },
  data () {
    return {
      fixed: true,
      uid: 'none',
      positionY: 0
    }
  },
  watch: {
    width () {
      this.$nextTick(() => {
        this.autosize()
      })
    },
    fontSize () {
      this.$nextTick(() => {
        this.autosize()
      })
    }
  },
  created () {
    this.debounceSetTitle = _.debounce(this.setTitle, 300)
  },
  mounted () {
    this.$nextTick(() => {
      this.autosize()
      this.markAsPristine({
        id: this.paperBuilderId
      })
    })
  },
  methods: {
    ...mapActions({
      markAsPristine: 'PaperBuilder/markAsPristine'
    }),
    setTitle (value) {
      this.setElement('children[0]', value)
      this.$nextTick(() => {
        this.autosize()
      })
    },
    changeTitle (value) {
      this.setElement('children[0]', value, false)
      this.$nextTick(() => {
        this.autosize()
      })
    },
    autosize () {
      const el = this.$refs.textElement
      el.style.height = 'auto'
      el.style.height = `${ _.ceil(el.scrollHeight, -1) }px`
      this.positionY = (_.ceil(el.scrollHeight, -1))
      this.setElement('properties.style.height', `${ _.ceil(el.scrollHeight, -1) }px`, false)
    }
  }
}
</script>
<style>
  .input-element-style,
  .input-element-style:focus {
    width: 100%;
    border: 0;
    outline: none;
    resize: none;
    overflow: hidden;
    user-select: none;
  }
</style>
