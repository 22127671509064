var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: {
        width: "100%",
        height: "100%"
      }
    },
    [_c("img", { staticClass: "w-100 h-100", attrs: { src: _vm.barcode } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }