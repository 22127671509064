<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mr-2"
        color="primary"
        v-bind="attrs"
        text
        v-on="on">
        {{ $t('papers.builder.draft.create') }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="px-4">
        <title-label :label="$t('papers.builder.draft.title')" />
        <v-spacer />
        <v-icon
          small
          @click="setDialog(false)">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="px-4 pb-0">
        <v-form
          ref="form"
          v-model="valid"
          :readonly="submitting"
          @submit.prevent="submit()">
          <text-field-label :label="$t('papers.builder.draft.name')" />
          <div>
            <v-text-field
              v-model="name"
              :rules="rule.name"
              autocomplete="off"
              autofocus
              outlined
              dense />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer />
        <v-btn
          class="px-4"
          color="primary"
          rounded
          text
          @click="setDialog(false)">
          {{ $t('app.btn.cancel') }}
        </v-btn>
        <v-btn
          :loading="submitting"
          :disabled="!valid"
          class="px-4"
          color="primary"
          rounded
          depressed
          @click="submit()">
          {{ $t('papers.builder.draft.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { CREATE_PAPER } from '@/resources/graphql'
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      dialog: false,
      submitting: false,
      valid: true,
      name: '',
      rule: {
        name: [
          (v) => !!v || this.$t('app.validate.required')
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      paperBuilderId: 'App/paperBuilderId',
      findPaperBuilder: 'PaperBuilder/find'
    }),
    paperBuilder () {
      return this.findPaperBuilder(this.paperBuilderId)
    },
    paperSize () {
      return this.paperBuilder?.size
    },
    paperInnerWidth () {
      return this.paperBuilder?.innerWidth
    },
    paperInnerHeight () {
      return this.paperBuilder?.innerHeight
    },
    paperCondition () {
      return this.paperBuilder?.condition
    },
    canvas () {
      return this.paperBuilder?.canvas || []
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Notification/setSnackbar'
    }),
    setDialog (dialog = false) {
      this.dialog = dialog
    },
    async submit () {
      if (!this.valid) return
      this.submitting = true
      const res = await this.$apollo.mutate({
        mutation: CREATE_PAPER,
        variables: {
          input: {
            paperGroupId: this.paperBuilderId,
            name: this.name,
            width: this.paperInnerWidth,
            height: this.paperInnerHeight,
            paperSize: this.paperSize,
            condition: this.paperCondition,
            canvas: this.canvas,
            active: true
          }
        }
      })
      if (res) {
        const { data: { createPaper } } = res
        if (createPaper.errors && Object.keys(createPaper.errors).length) {
          // todo: error
        } else {
          // todo: success
          this.dialog = false
        }
      }
      this.submitting = false
      this.setSnackbar({
        message: this.$t('papers.snackbar.draftSuccess'),
        type: 'success',
        value: true,
        absolute: false,
        color: '#4F4F4F',
        timeout: 3000
      })
      setTimeout(() => {
      window.location.reload()
      }, 500)
    }
  }
}
</script>

<style scoped>

</style>
