<template>
  <div>
    <v-btn
      class="mr-2"
      color="primary"
      outlined
      @click="print()">
      <v-icon
        size="18"
        color="primary"
        left>
        $paper_print_outline
      </v-icon>
      {{ $t('papers.builder.print') }}
    </v-btn>
  </div>
</template>

<script>
import printJS from 'print-js'
import { mapGetters } from 'vuex'
import { GET_PAPERHTML } from '@/resources/graphql'

export default {
  apollo: {
    paperTestHtml: () => ({
      query: GET_PAPERHTML,
      fetchPolicy: 'no-cache',
      skip () {
        return this.canvas.length === 0 || this.skip
      },
      variables () {
        return {
          canvas: this.canvas,
          paperSize: this.size,
          width: Math.floor(this.innerWidth),
          height: Math.floor(this.innerHeight)
        }
      },
      result ({ data: { paperTestHtml } }) {
        this.skip = true
        const div = document.createElement('div')
        div.innerHTML = paperTestHtml.trim()
        printJS({
          printable: div.innerHTML,
          type: 'raw-html',
          honorColor: true,
          css: 'https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,400;0,800;1,400;1,800&display=swap',
          style: 'html { font-family: \'prompt\', sans-serif; -webkit-print-color-adjust: exact; }',
          targetStyles: ['*']
        })
      }
    })
  },
  data () {
    return {
      skip: true
    }
  },
  computed: {
    ...mapGetters({
      paperBuilderId: 'App/paperBuilderId',
      findPaperBuilder: 'PaperBuilder/find'
    }),
    paperBuilder () {
      return this.findPaperBuilder(this.paperBuilderId)
    },
    canvas () {
      return this.paperBuilder?.canvas || []
    },
    size () {
      return this.paperBuilder?.size
    },
    innerWidth () {
      return this.paperBuilder?.innerWidth
    },
    innerHeight () {
      return this.paperBuilder?.innerHeight
    }
  },
  methods: {
    print () {
      this.skip = false
      this.$apollo.queries.paperTestHtml.refetch()
      if (this.canvas.length === 0) {
        printJS({
          printable: '<div />',
          type: 'raw-html',
          honorColor: true,
          css: 'https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,400;0,800;1,400;1,800&display=swap',
          style: 'html { font-family: \'prompt\', sans-serif; -webkit-print-color-adjust: exact; }',
          targetStyles: ['*']
        })
        this.skip = true
      }
    }
  }
}
</script>
<style>

</style>
