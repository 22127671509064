<template>
  <v-menu
    :value="value"
    content-class="text-element-toolbar"
    :close-on-content-click="false"
    :close-on-click="false"
    :position-y="positionY"
    transition="none"
    offset-y
    fixed>
    <template v-slot:activator="{ attrs }">
      <slot v-bind="attrs"></slot>
    </template>
    <v-toolbar
      class="text-element-editor"
      flat
      tile
      dense>
      <color-picker
        :value="color"
        @input="$emit('input', 'properties.style', {
          color: $event
        })" />
      <v-divider vertical></v-divider>
      <v-btn-toggle
        :value="[fontWeight, fontStyle, textDecoration]"
        class="mx-1"
        color="primary"
        dense
        borderless
        multiple>
        <v-btn
          value="bold"
          text
          @input="$emit('input', 'properties.style', {
            fontWeight: $event ? 'bold' : 'normal'
          })">
          <v-icon>mdi-format-bold</v-icon>
        </v-btn>
        <v-btn
          value="italic"
          text
          @input="$emit('input', 'properties.style', {
            fontStyle: $event ? 'italic' : 'normal'
          })">
          <v-icon>mdi-format-italic</v-icon>
        </v-btn>
        <v-btn
          value="underline"
          text
          @input="$emit('input', 'properties.style', {
            textDecoration: $event ? 'underline' : 'normal'
          })">
          <v-icon>mdi-format-underline</v-icon>
        </v-btn>
      </v-btn-toggle>
      <v-divider vertical></v-divider>
      <div class="mx-1 input-select--wrap">
        <v-select
          :value="fontSize"
          :items="fontSizeOptions"
          class="app-style"
          item-text="text"
          item-value="value"
          menu-props="auto"
          hide-details
          outlined
          dense
          @input="$emit('input', 'properties.style', {
            fontSize: $event
          })" />
      </div>
      <v-divider vertical></v-divider>
      <v-btn-toggle
        :value="textAlign"
        class="mx-1"
        color="primary"
        dense
        borderless
        @change="$emit('input', 'properties.style', {
          textAlign: $event
        })">
        <v-btn
          value="left"
          text>
          <v-icon>mdi-format-align-left</v-icon>
        </v-btn>
        <v-btn
          value="center"
          text>
          <v-icon>mdi-format-align-center</v-icon>
        </v-btn>
        <v-btn
          value="right"
          text>
          <v-icon>mdi-format-align-right</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-toolbar>
  </v-menu>
</template>

<script>
import ColorPicker from '../ColorPicker.vue'

export default {
  components: {
    ColorPicker
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    fontSize: {
      type: String,
      default: '14px'
    },
    textAlign: {
      type: String,
      default: 'left'
    },
    fontWeight: {
      type: String,
      default: 'normal'
    },
    fontStyle: {
      type: String,
      default: 'normal'
    },
    textDecoration: {
      type: String,
      default: 'none'
    },
    color: {
      type: String,
      default: '#000000'
    },
    positionY: {
      type: [Number, String],
      default: undefined
    }
  },
  data () {
    return {
      fontSizeOptions: [
        { text: 10, value: '10px' },
        { text: 12, value: '12px' },
        { text: 14, value: '14px' },
        { text: 16, value: '16px' },
        { text: 18, value: '18px' },
        { text: 20, value: '20px' },
        { text: 22, value: '22px' },
        { text: 24, value: '24px' },
        { text: 36, value: '36px' },
        { text: 72, value: '72px' }
      ]
    }
  }

}
</script>
<style>
  .v-menu__content.text-element-toolbar {
    margin-top: 10px !important;
  }
  .text-element-editor .v-toolbar__content {
    padding: 0;
  }
  .text-element-editor .v-btn.v-size--default {
    min-width: 40px;
  }
  .input-select--wrap {
    width: 80px;
  }
</style>
