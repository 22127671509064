<template>
  <v-dialog
    v-model="dialog"
    max-width="420px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        color="primary"
        x-small
        icon
        v-on="on">
        <v-icon
          size="17">
          $paper_cog
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="px-4 pb-1">
        <title-label :label="title" />
        <v-spacer />
        <v-icon
          class="dialog-close-tr"
          color="#C8C8C8"
          small
          @click="setDialog(false)">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="px-4">
        <div class="line-setting-style">
          <text-field-label :label="$t('papers.builder.settings.line.color')" />
          <v-color-picker
            :value="borderColor"
            :dot-size="25"
            mode="hexa"
            @input="setElement('properties.style.borderColor', $event)">
          </v-color-picker>
          <div class="d-flex mb-3">
            <text-field-label
              class="mr-2"
              :label="$t('papers.builder.settings.line.size')" />
            <v-spacer />
            <div style="width:70%">
              <v-select
                :value="borderTopWidth"
                :items="borderTopWidthTypes"
                class="app-style"
                hide-details
                outlined
                dense
                @input="setElement('properties.style', {
                  height: $event,
                  borderTopWidth: $event
                })" />
            </div>
          </div>
          <div class="d-flex">
            <text-field-label
              class="mr-2"
              :label="$t('papers.builder.settings.line.style')" />
            <v-spacer />
            <div style="width:70%">
              <v-select
                :value="borderStyle"
                :items="borderStyleTypes"
                item-text="text"
                item-value="value"
                class="app-style"
                hide-details
                outlined
                dense
                @input="setElement('properties.style.borderStyle', $event)" />
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import paperSettingMixin from '@/mixins/paperSettingMixin'

export default {
  mixins: [paperSettingMixin],
  props: {
    title: {
      type: String,
      default: 'None'
    },
    borderColor: {
      type: String,
      default: '#ffffff'
    },
    borderStyle: {
      type: String,
      default: 'solid'
    },
    borderTopWidth: {
      type: String,
      default: '1px'
    }
  },
  data () {
    return {
      dialog: false,
      borderStyleTypes: [
        { text: this.$t('papers.builder.settings.line.solid'), value: 'solid' },
        { text: this.$t('papers.builder.settings.line.dashed'), value: 'dashed' }
      ],
      borderTopWidthTypes: ['1px', '2px', '4px', '6px', '8px', '10px', '16px']
    }
  },
  methods: {
    setDialog (dialog = false) {
      this.dialog = dialog
    }
  }
}
</script>
<style scoped>
  .line-setting-style {
    width: 280px;
  }
</style>
