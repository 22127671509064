<template>
  <div class="pa-3">
    <div class="pa-2">
      <v-text-field
        v-model="searchText"
        :placeholder="$t('app.form.search.placeholder')"
        autocomplete="off"
        class="search-style"
        prepend-inner-icon="mdi-magnify"
        hide-details
        outlined
        dense />
    </div>
    <div class="pa-2 toolbox">
      <v-row dense>
        <v-col
          v-for="item in items"
          :key="`toolbox-item-${item.name}`"
          cols="12"
          md="6"
          lg="4">
          <component
            :is="item.component"
            :name="$t(`papers.builder.templates.${item.name}`)"
            :icon="item.icon" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import TEMPLATES from '@/resources/json/template-papers.json'

function importTemplateAll (templates) {
  const components = {}
  templates.forEach(({ component }) => {
    components[component] = () => import(`./Templates/${ component }.vue`)
  })
  return components
}

export default {
  components: importTemplateAll(TEMPLATES),
  data () {
    return {
      dialog: false,
      tools: TEMPLATES,
      searchText: ''
    }
  },
  computed: {
    items () {
      let items = this.tools
      if (this.searchText) {
        items = items.filter(
          (item) => this.$t(`papers.builder.templates.${item.name}`).toLowerCase()
            .includes(this.searchText.toLowerCase())
        )
      }
      return items
    }
  }
}
</script>
<style>
  .search-style {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #C1C1C1;
  }
  .search-style .v-icon {
    font-size: 16px;
    line-height: 21px;
    color: #C1C1C1;
  }
  .search-style.v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 32px;
  }
  .search-style.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-inner {
    margin-top: 4px;
  }
  .search-style.v-text-field--outlined fieldset {
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 2px;
  }
</style>
<style scoped>
  .toolbox {
    height: 630px;
    overflow-y: auto;
  }
</style>
