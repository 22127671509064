var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        value: _vm.value,
        "content-class": "text-element-toolbar",
        "close-on-content-click": false,
        "close-on-click": false,
        "position-y": _vm.positionY,
        transition: "none",
        "offset-y": "",
        fixed: ""
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function(ref) {
              var attrs = ref.attrs
              return [_vm._t("default", null, null, attrs)]
            }
          }
        ],
        null,
        true
      )
    },
    [
      _c(
        "v-toolbar",
        {
          staticClass: "text-element-editor",
          attrs: { flat: "", tile: "", dense: "" }
        },
        [
          _c("color-picker", {
            attrs: { value: _vm.color },
            on: {
              input: function($event) {
                return _vm.$emit("input", "properties.style", {
                  color: $event
                })
              }
            }
          }),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-btn-toggle",
            {
              staticClass: "mx-1",
              attrs: {
                value: [_vm.fontWeight, _vm.fontStyle, _vm.textDecoration],
                color: "primary",
                dense: "",
                borderless: "",
                multiple: ""
              }
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { value: "bold", text: "" },
                  on: {
                    input: function($event) {
                      return _vm.$emit("input", "properties.style", {
                        fontWeight: $event ? "bold" : "normal"
                      })
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-format-bold")])],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { value: "italic", text: "" },
                  on: {
                    input: function($event) {
                      return _vm.$emit("input", "properties.style", {
                        fontStyle: $event ? "italic" : "normal"
                      })
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-format-italic")])],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { value: "underline", text: "" },
                  on: {
                    input: function($event) {
                      return _vm.$emit("input", "properties.style", {
                        textDecoration: $event ? "underline" : "normal"
                      })
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-format-underline")])],
                1
              )
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "div",
            { staticClass: "mx-1 input-select--wrap" },
            [
              _c("v-select", {
                staticClass: "app-style",
                attrs: {
                  value: _vm.fontSize,
                  items: _vm.fontSizeOptions,
                  "item-text": "text",
                  "item-value": "value",
                  "menu-props": "auto",
                  "hide-details": "",
                  outlined: "",
                  dense: ""
                },
                on: {
                  input: function($event) {
                    return _vm.$emit("input", "properties.style", {
                      fontSize: $event
                    })
                  }
                }
              })
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-btn-toggle",
            {
              staticClass: "mx-1",
              attrs: {
                value: _vm.textAlign,
                color: "primary",
                dense: "",
                borderless: ""
              },
              on: {
                change: function($event) {
                  return _vm.$emit("input", "properties.style", {
                    textAlign: $event
                  })
                }
              }
            },
            [
              _c(
                "v-btn",
                { attrs: { value: "left", text: "" } },
                [_c("v-icon", [_vm._v("mdi-format-align-left")])],
                1
              ),
              _c(
                "v-btn",
                { attrs: { value: "center", text: "" } },
                [_c("v-icon", [_vm._v("mdi-format-align-center")])],
                1
              ),
              _c(
                "v-btn",
                { attrs: { value: "right", text: "" } },
                [_c("v-icon", [_vm._v("mdi-format-align-right")])],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }