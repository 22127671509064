<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="calculator-formula">
    <div
      v-for="(numpad, index) in numpads"
      :key="`btn-row-${index}`"
      class="numpad">
      <div
        v-for="(button) in numpad"
        :key="`btn-${button.type}-${button.value}`"
        class="btn-numpad">
        <div
          v-if="button.type === 'variables'"
          class="btn-numpad--key btn-numpad--selector">
          <variable-selector
            :items="variables"
            item-value="field"
            item-text="display"
            group-by-value="tableName"
            group-by-text="tableDescription"
            @change="addVariable($event)"
            @click:add="clickAddVariable()"
            @click:edit="clickEditVariable($event)"
            @click:remove="clickRemoveVariable($event)" />
        </div>
        <div
          v-else-if="button.type === 'none'"
          class="btn-numpad--key btn-numpad--none"></div>
        <div
          v-else
          :class="button.class || 'btn-numpad--value'"
          class="btn-numpad--key"
          @click="handleEvent(button)"
          v-html="button.display || button.value" />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import VariableSelector from './VariableSelector.vue'

export default {
  components: {
    VariableSelector
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    variables: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      dialog: false,
      numpads: [
        [
          { type: 'number', value: 7 },
          { type: 'number', value: 8 },
          { type: 'number', value: 9 },
          { type: 'operator', display: '&#247;', class: 'btn-numpad--symbol', value: 'divided_by' },
          { type: 'clear', display: 'C' },
          { type: 'backspace', display: '&#8592;' }
        ],
        [
          { type: 'number', value: 4 },
          { type: 'number', value: 5 },
          { type: 'number', value: 6 },
          { type: 'operator', display: '-', class: 'btn-numpad--symbol', value: 'minus' },
          { type: 'variables', display: 'SUM', value: 'sum' }
        ],
        [
          { type: 'number', value: 1 },
          { type: 'number', value: 2 },
          { type: 'number', value: 3 },
          { type: 'operator', display: '+', class: 'btn-numpad--symbol', value: 'plus' }
        ],
        [
          { type: 'number', class: 'btn-numpad--zero', value: 0 },
          { type: 'number', value: '.' },
          { type: 'operator', display: 'x', value: 'times' }
        ]
      ]
    }
  },
  computed: {
    points () {
      return this.value || []
    },
    beginPoint () {
      return this.points.length ? this.points[0] : { type: 'none', value: null }
    },
    currentPoint () {
      return this.points.length ? this.points[this.points.length - 1] : { type: 'none', value: null }
    },
    lastAggregator () {
      let scope = null
      this.points.forEach((item) => {
        if (['aggregator', 'end-aggregator'].includes(item.type)) {
          scope = item
        }
      })
      return scope
    },
    currentAggregator () {
      const stacks = []
      this.points.forEach((item) => {
        if (['begin-aggregator'].includes(item.type)) {
          stacks.push(item)
        } else if (['end-aggregator'].includes(item.type)) {
          stacks.pop()
        }
      })
      return stacks.length ? _.last(stacks) : null
    },
    currentScope () {
      const stacks = []
      this.points.forEach((item) => {
        if (['begin-bracket', 'begin-aggregator'].includes(item.type)) {
          stacks.push(item)
        } else if (['end-bracket', 'end-aggregator'].includes(item.type)) {
          stacks.pop()
        }
      })
      return stacks.length ? _.last(stacks) : null
    }
  },
  methods: {
    handleEvent ({ type, value }) {
      switch (type) {
        case 'number':
          return this.addDigit(value)
        case 'operator':
          return this.addOperator(value)
        case 'begin-bracket':
          return this.addBracket(value)
        case 'end-bracket':
          return this.addEndScope()
        case 'backspace':
          return this.backspace()
        case 'clear':
          return this.clear()
        default:
          return this.clear()
      }
    },
    addDigit (digit) {
      if (['none', 'operator', 'begin-aggregator', 'begin-bracket'].includes(this.currentPoint?.type)) {
        this.change([...this.points, {
          type: 'number',
          value: digit
        }])
      } else if (['number'].includes(this.currentPoint?.type)) {
        const digits = `${this.currentPoint?.value || 0}${digit}`
        if (!Number.isNaN(Number(digits))) {
          const points = _.cloneDeep(this.points)
          points[points.length - 1].value = digits
          this.change(points)
        }
      }
    },
    addField (field) {
      if (['none', 'operator', 'begin-aggregator', 'begin-bracket'].includes(this.currentPoint?.type)) {
        this.change([...this.points, {
          type: 'field',
          value: field
        }])
      }
    },
    addVariable (variable) {
      if (['none', 'operator', 'begin-aggregator', 'begin-bracket'].includes(this.currentPoint?.type)) {
        this.change([...this.points, {
          type: 'associate',
          value: variable
        }])
      }
    },
    addOperator (operator) {
      if (['number', 'field', 'associate', 'aggregator', 'end-aggregator', 'end-bracket'].includes(this.currentPoint?.type)) {
        this.change([...this.points, {
          type: 'operator',
          value: operator
        }])
      }
    },
    addAggregator (aggregator) {
      if (['number', 'associate', 'aggregator', 'end-bracket'].includes(this.currentPoint?.type)) {
        if (!this.lastAggregator) {
          this.change([...this.points, {
            type: 'aggregator',
            value: aggregator
          }])
        }
      } else if (['none', 'operator'].includes(this.currentPoint?.type)) {
        if (!this.currentAggregator) {
          this.change([...this.points, {
            type: 'begin-aggregator',
            value: aggregator
          }])
        }
      }
    },
    addBracket () {
      if (['none', 'operator'].includes(this.currentPoint?.type)) {
        const beginBrackets = this.points.filter((item) => item.type === 'begin-bracket')
        this.change([...this.points, {
          type: 'begin-bracket',
          value: beginBrackets.length
        }])
      }
    },
    addEndScope () {
      if (['number', 'field', 'associate', 'aggregator', 'end-aggregator', 'end-bracket'].includes(this.currentPoint?.type)) {
        if (this.currentScope?.type === 'begin-aggregator') {
          this.change([...this.points, {
            type: 'end-aggregator',
            value: this.currentScope.value
          }])
        } else if (this.currentScope?.type === 'begin-bracket') {
          this.change([...this.points, {
            type: 'end-bracket',
            value: this.currentScope.value
          }])
        }
      }
    },
    backspace () {
      const points = _.cloneDeep(this.points)
      points.pop()
      this.change(points)
    },
    clear () {
      this.change([])
    },
    clickAddVariable () {
      this.$emit('click:addvariable')
    },
    clickEditVariable (index) {
      this.$emit('click:editvariable', index)
    },
    clickRemoveVariable (index) {
      this.$emit('click:removevariable', index)
    },
    change (points) {
      this.$emit('input', _.cloneDeep(points))
    }
  }
}
</script>

<style>
  .numpad {
    display: flex;
  }
  .btn-numpad {
    background-color: #ffffff;
    display: flex;
    height: 42px;
    border-right: 1px solid #CDCDCD;
    border-left: 1px solid #CDCDCD;
    flex: 0 1 auto;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    color: #6C6C6C;
  }
  .btn-numpad--key {
    padding: 2px;
  }
  .numpad:not(:last-child) .btn-numpad {
    border-bottom: 1px solid #CDCDCD;
  }
  .numpad .btn-numpad {
    border-top: 1px solid #CDCDCD;
  }
  .numpad:last-child .btn-numpad{
    border-bottom: 1px solid #CDCDCD;
  }
  .numpad .btn-numpad:not(:last-child) {
    border-right: 0;
  }
  .btn-numpad--none {
    border-right: 0;
  }
  .btn-before-last-child {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    width: 42px;
    border-bottom: 1px solid #CDCDCD;
  }
  .btn-numpad--value {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    width: 42px;
  }
  .btn-numpad--zero {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    width: 85px;
  }
  .btn-numpad--selector {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    width: 167px;
  }
  .btn-numpad--symbol {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    font-size: 28px;
    width: 42px;
  }
  .calculator-formula > .numpad .btn-numpad--key:hover {
    border: 2px solid #1BA7E1;
    padding: 0;
  }
  .calculator-formula > .numpad .btn-numpad:active {
    background:#1BA7E1;
    color: #ffffff;
  }
</style>
