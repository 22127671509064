var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "action-container" }, [
    _c(
      "div",
      {
        staticClass: "action-bar pa-4 d-flex justify-space-between align-center"
      },
      [
        _c("cancel-button"),
        _c("v-spacer"),
        _c("new-draft"),
        _c("print-button"),
        _c("save-draft-button", { staticClass: "mr-2" }),
        _c("publish-button")
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "action-bar pa-4 d-flex justify-space-between align-center"
      },
      [
        _c("div", { staticClass: "ml-4 mr-3" }, [
          _c("span", { staticClass: "secondary--text" }, [
            _vm._v(" " + _vm._s(_vm.$t("papers.builder.size")) + " ")
          ])
        ]),
        _c(
          "div",
          { staticClass: "mr-4" },
          [
            _c("v-select", {
              staticClass: "size-style",
              attrs: {
                value: _vm.paperSize,
                items: _vm.paperGetSize,
                "item-value": "key",
                "item-text": "display_name",
                "return-object": "",
                "hide-details": "",
                outlined: "",
                dense: ""
              },
              on: {
                input: function($event) {
                  return _vm.setInnerSize($event)
                }
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "mr-3" }, [
          _c("span", { staticClass: "secondary--text" }, [
            _vm._v(" " + _vm._s(_vm.$t("papers.builder.condition")) + " ")
          ])
        ]),
        _c(
          "div",
          { staticClass: "mr-4" },
          [
            _c("v-select", {
              staticClass: "condition-style",
              attrs: {
                value: _vm.paperCondition,
                items: _vm.paperConditions,
                "item-value": "name",
                "item-text": "description",
                "hide-details": "",
                outlined: "",
                dense: ""
              },
              on: {
                input: function($event) {
                  return _vm.setCondition($event)
                }
              }
            })
          ],
          1
        ),
        _c(
          "v-icon",
          {
            staticClass: "mr-4",
            attrs: {
              disabled: _vm.cantUndo,
              size: "16",
              color: _vm.cantUndo ? "#D1D1D1" : "#6C6C6C"
            },
            on: {
              click: function($event) {
                return _vm.undo()
              }
            }
          },
          [_vm._v(" $paper_left_turn_arrow ")]
        ),
        _c(
          "v-icon",
          {
            attrs: {
              disabled: _vm.cantRedo,
              size: "16",
              color: _vm.cantRedo ? "#D1D1D1" : "#6C6C6C"
            },
            on: {
              click: function($event) {
                return _vm.redo()
              }
            }
          },
          [_vm._v(" $paper_right_turn_arrow ")]
        ),
        _c("v-spacer"),
        _c("draft-selector")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }