<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="slide-x-transition"
    offset-x>
    <template v-slot:activator="{ on, attrs }">
      <div
        class="w-100"
        v-bind="attrs"
        v-on="on">
        <div
          v-if="!showSelected"
          class="btn-variable-selector">
          <v-list-item-title class="body-2">
            {{ $t('app.select.placeholder') }}
          </v-list-item-title>
          <v-spacer />
          <v-icon small>
            mdi-menu-down
          </v-icon>
        </div>
        <div
          v-else
          class="table-group-input--value">
          <v-list-item-title>
            {{ selectedItem }}
          </v-list-item-title>
        </div>
      </div>
    </template>
    <v-card :width="320">
      <v-text-field
        v-model="searchText"
        :placeholder="$t('app.form.search.placeholder')"
        class="px-2 pb-2 mt-0"
        prepend-inner-icon="mdi-magnify"
        autofocus
        hide-details />
      <div class="variable-selector">
        <v-list class="pa-0">
          <template v-for="group in selectItems">
            <div
              :key="`tb-list-${group.name}`"
              class="variable-group-name-list pa-2">
              {{ group.description }}
            </div>
            <template v-for="(item, index) in group.items">
              <v-list-item
                v-if="!item.isDeleted"
                :key="`tb-${group.name}-${item.name}-list`"
                class="variable-selector--list mx-2"
                link
                @click="select(group.name, item.name)">
                <v-list-item-content>
                  <v-list-item-title>{{ item.description }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon
                    v-if="group.name !== '@saved_data'"
                    class="variable-selector--btn-add"
                    color="#ffffff"
                    tag="a"
                    small>
                    mdi-plus-circle
                  </v-icon>
                  <template v-else>
                    <v-icon
                      class="variable-selector--btn-add mr-2"
                      color="#ffffff"
                      small
                      @click="edit($event, index)">
                      $pencilWhite
                    </v-icon>
                    <v-icon
                      class="variable-selector--btn-add"
                      color="#ffffff"
                      size="18"
                      @click="remove($event, index)">
                      $trashWhite
                    </v-icon>
                  </template>
                </v-list-item-icon>
              </v-list-item>
            </template>
          </template>
        </v-list>
        <div
          v-if="selectItems.length === 0"
          class="d-flex justify-center align-center h-100 secondary--text">
          No items
        </div>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    itemValue: {
      type: String,
      required: true
    },
    itemText: {
      type: String,
      required: true
    },
    groupByValue: {
      type: String,
      required: true
    },
    groupByText: {
      type: String,
      required: true
    },
    showSelected: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      menu: false,
      searchText: ''
    }
  },
  computed: {
    selectedItem () {
      const associate = this.items.find((item) => item.id === this.value)
      return associate ? associate.display : ''
    },
    selectItems () {
      let items = [...this.items]
      // note: no id
      items = items.filter((item) => !_.endsWith(item.field, '_id'))
      if (this.searchText) {
        items = items.filter((item) => item[this.itemText].includes(this.searchText))
      }
      return _.chain(items)
        .groupBy(this.groupByValue)
        .map((value, key) => ({
          name: key,
          description: _.get(_.find(value, this.groupByText), this.groupByText),
          items: value.map((item) => ({
            name: item[this.itemValue],
            description: item[this.itemText],
            isDeleted: item.isDeleted || false
          }))
        }))
        .value()
    }
  },
  methods: {
    add () {
      this.$emit('click:add')
      this.menu = false
    },
    select (model, field) {
      this.$emit('change', `${model}.${field}`)
      this.menu = false
    },
    edit (e, index) {
      e.stopPropagation()
      this.$emit('click:edit', index)
      this.menu = false
    },
    remove (e, index) {
      e.stopPropagation()
      this.$emit('click:remove', index)
    }
  }
}
</script>
<style>
  .btn-create-saved-datum.v-btn--plain .v-btn__content{
    opacity: 1 !important;
  }
</style>
<style scoped>

  .variable-selector {
    overflow: auto;
    height: 350px;
    width: 100%;
  }
  .variable-group-name-list {
    font-size: 14px;
  }
  .variable-selector .variable-selector--list {
    background: #6EB4D0;
    margin-bottom: 8px;
    height: 29px;
    min-height: 29px;
  }
  .variable-selector .variable-selector--list .v-list-item__content {
    padding: 4px 0;
  }
  .variable-selector .variable-selector--list .v-list-item__title {
    color: #ffffff;
    font-size: 14px;
  }
  .variable-selector .variable-selector--list .v-list-item__icon {
    opacity: 0;
    margin: 6px 0;
    min-width: auto;
  }
  .variable-selector .variable-selector--list:hover .v-list-item__icon {
    display: flex;
    opacity: 1;
    transition: 0.5s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
  }
  .table-group-input--value {
    border: 1px solid #6EB4D0;
    border-radius: 4px;
    background: #6EB4D0;
    color: #ffffff;
    padding: 2px 12px;
  }
  .v-btn.btn-variable-selector.has-selector {
    border: 1px solid #6EB4D0;
    background: #6EB4D0;
    color: #ffffff;
  }
  .table-group-input--disabled {
    background: #E8E8E8;
    border: 1px solid #E8E8E8;
    color: rgba(0, 0, 0, 0.6);
    cursor: default;
  }
  .btn-variable-selector {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 6px 14px;
  }
</style>
