var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "420px", scrollable: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { attrs: { color: "primary", "x-small": "", icon: "" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { attrs: { size: "17" } }, [
                    _vm._v(" $paper_cog ")
                  ])
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "px-4 pb-1" },
            [
              _c("title-label", { attrs: { label: _vm.title } }),
              _c("v-spacer"),
              _c(
                "v-icon",
                {
                  staticClass: "dialog-close-tr",
                  attrs: { color: "#C8C8C8", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.setDialog(false)
                    }
                  }
                },
                [_vm._v(" mdi-close ")]
              )
            ],
            1
          ),
          _c("v-card-text", { staticClass: "px-4" }, [
            _c(
              "div",
              { staticClass: "line-setting-style" },
              [
                _c("text-field-label", {
                  attrs: { label: _vm.$t("papers.builder.settings.line.color") }
                }),
                _c("v-color-picker", {
                  attrs: {
                    value: _vm.borderColor,
                    "dot-size": 25,
                    mode: "hexa"
                  },
                  on: {
                    input: function($event) {
                      return _vm.setElement(
                        "properties.style.borderColor",
                        $event
                      )
                    }
                  }
                }),
                _c(
                  "div",
                  { staticClass: "d-flex mb-3" },
                  [
                    _c("text-field-label", {
                      staticClass: "mr-2",
                      attrs: {
                        label: _vm.$t("papers.builder.settings.line.size")
                      }
                    }),
                    _c("v-spacer"),
                    _c(
                      "div",
                      { staticStyle: { width: "70%" } },
                      [
                        _c("v-select", {
                          staticClass: "app-style",
                          attrs: {
                            value: _vm.borderTopWidth,
                            items: _vm.borderTopWidthTypes,
                            "hide-details": "",
                            outlined: "",
                            dense: ""
                          },
                          on: {
                            input: function($event) {
                              return _vm.setElement("properties.style", {
                                height: $event,
                                borderTopWidth: $event
                              })
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("text-field-label", {
                      staticClass: "mr-2",
                      attrs: {
                        label: _vm.$t("papers.builder.settings.line.style")
                      }
                    }),
                    _c("v-spacer"),
                    _c(
                      "div",
                      { staticStyle: { width: "70%" } },
                      [
                        _c("v-select", {
                          staticClass: "app-style",
                          attrs: {
                            value: _vm.borderStyle,
                            items: _vm.borderStyleTypes,
                            "item-text": "text",
                            "item-value": "value",
                            "hide-details": "",
                            outlined: "",
                            dense: ""
                          },
                          on: {
                            input: function($event) {
                              return _vm.setElement(
                                "properties.style.borderStyle",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }