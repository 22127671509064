var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "480px", scrollable: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { attrs: { color: "primary", "x-small": "", icon: "" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { attrs: { size: "17" } }, [
                    _vm._v(" $paper_cog ")
                  ])
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#FBFBFB", tile: "" } },
        [
          _c(
            "v-card-title",
            { staticClass: "px-4 pb-1" },
            [
              _c("title-label", { attrs: { label: _vm.title } }),
              _c("v-spacer"),
              _c(
                "v-icon",
                {
                  staticClass: "dialog-close-tr",
                  attrs: { color: "#C8C8C8", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.setDialog(false)
                    }
                  }
                },
                [_vm._v(" mdi-close ")]
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "px-4" },
            [
              _c(
                "v-tabs",
                {
                  staticClass: "mb-4",
                  attrs: {
                    "show-arrows": false,
                    height: 30,
                    "slider-size": 1,
                    "background-color": "#FBFBFB"
                  },
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _vm._l(_vm.tabs, function(item) {
                    return _c(
                      "v-tab",
                      {
                        key: "tab-create-" + item.tab,
                        staticClass:
                          "no-hover--bg-color text-default--color font-weight-regular px-0 mr-4",
                        attrs: { ripple: false }
                      },
                      [_vm._v(" " + _vm._s(item.name) + " ")]
                    )
                  }),
                  _c("div", { staticClass: "tab-silder-full" })
                ],
                2
              ),
              _c(
                "v-tabs-items",
                {
                  staticClass: "table-setting-data--wrapper",
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c("v-tab-item", [
                    _c(
                      "div",
                      [
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "5" } },
                              [
                                _c(
                                  "v-list",
                                  {
                                    staticClass: "list-column-select",
                                    attrs: {
                                      flat: "",
                                      dense: "",
                                      "single-line": ""
                                    }
                                  },
                                  [
                                    _c("v-list-item", {
                                      staticClass: "data-header px-0",
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function() {
                                            return [
                                              _c(
                                                "v-list-item-action",
                                                [
                                                  _c("v-checkbox", {
                                                    attrs: { dense: "" },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.selectAll(
                                                          $event
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value: _vm.selectedAll,
                                                      callback: function($$v) {
                                                        _vm.selectedAll = $$v
                                                      },
                                                      expression: "selectedAll"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "papers.builder.settings.table.data.info"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                      _c("field-add-button")
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ])
                                    }),
                                    _c(
                                      "v-list-item-group",
                                      {
                                        attrs: {
                                          multiple: "",
                                          "active-class": ""
                                        },
                                        model: {
                                          value: _vm.selected,
                                          callback: function($$v) {
                                            _vm.selected = $$v
                                          },
                                          expression: "selected"
                                        }
                                      },
                                      [
                                        _vm._l(
                                          _vm.columnWithOutSelected,
                                          function(column, index) {
                                            return _c("v-list-item", {
                                              key:
                                                "item-" +
                                                index +
                                                "-" +
                                                column.field,
                                              staticClass: "px-0",
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var active = ref.active
                                                      return [
                                                        _c(
                                                          "v-list-item-action",
                                                          [
                                                            _c("v-checkbox", {
                                                              attrs: {
                                                                "input-value": active,
                                                                dense: ""
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    column.display
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-list-item-action",
                                                          [
                                                            column.type ===
                                                            "addition"
                                                              ? _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "#c6c6c6"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.removeAddition(
                                                                          column.field
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " $paper_trash_outline "
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          }
                                        ),
                                        !_vm.columnWithOutSelected.length
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "pa-2 text-center"
                                              },
                                              [_vm._v(" No data ")]
                                            )
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("v-col", { attrs: { cols: "2" } }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex h-100 flex-column justify-center align-center"
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mb-1",
                                      attrs: {
                                        disabled: !_vm.selected.length,
                                        color: "primary",
                                        icon: "",
                                        small: "",
                                        outlined: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.select()
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-chevron-right")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled: !_vm.deselected.length,
                                        color: "primary",
                                        icon: "",
                                        small: "",
                                        outlined: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.deselect()
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [_vm._v("mdi-chevron-left")])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                            _c(
                              "v-col",
                              { attrs: { cols: "5" } },
                              [
                                _c(
                                  "v-list",
                                  {
                                    staticClass: "list-column-select",
                                    attrs: {
                                      flat: "",
                                      dense: "",
                                      "single-line": ""
                                    }
                                  },
                                  [
                                    _c("v-list-item", {
                                      staticClass: "data-header px-0",
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function() {
                                            return [
                                              _c(
                                                "v-list-item-action",
                                                [
                                                  _c("v-checkbox", {
                                                    attrs: { dense: "" },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.deselectAll(
                                                          $event
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value: _vm.deselectedAll,
                                                      callback: function($$v) {
                                                        _vm.deselectedAll = $$v
                                                      },
                                                      expression:
                                                        "deselectedAll"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "papers.builder.settings.table.data.selected"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ])
                                    }),
                                    _c(
                                      "v-list-item-group",
                                      {
                                        attrs: {
                                          multiple: "",
                                          "active-class": ""
                                        },
                                        model: {
                                          value: _vm.deselected,
                                          callback: function($$v) {
                                            _vm.deselected = $$v
                                          },
                                          expression: "deselected"
                                        }
                                      },
                                      [
                                        _vm._l(_vm.columnSelected, function(
                                          column,
                                          index
                                        ) {
                                          return _c("v-list-item", {
                                            key:
                                              "item-" +
                                              index +
                                              "-" +
                                              column.field,
                                            staticClass: "px-0",
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var active = ref.active
                                                    return [
                                                      _c(
                                                        "v-list-item-action",
                                                        [
                                                          _c("v-checkbox", {
                                                            attrs: {
                                                              "input-value": active,
                                                              dense: ""
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  column.display
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        }),
                                        !_vm.columnSelected.length
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "pa-2 text-center"
                                              },
                                              [_vm._v(" No data ")]
                                            )
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("v-tab-item", [
                    _c("div", { staticClass: "table-setting-style--wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "mb-3 d-flex" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "papers.builder.settings.table.style.header"
                                )
                              ) +
                              " "
                          ),
                          _c("v-spacer"),
                          _c("v-checkbox", {
                            staticClass: "mt-0 pt-0",
                            attrs: {
                              "input-value": _vm.headerDisplay,
                              label: _vm.$t("app.show"),
                              "true-value": "table-row",
                              "false-value": "none",
                              dense: "",
                              "hide-details": ""
                            },
                            on: {
                              change: function($event) {
                                return _vm.setElement(
                                  "children.0.children.0.children.0.properties.style",
                                  {
                                    display: $event
                                  }
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-3" },
                        [
                          _c(
                            "v-toolbar",
                            {
                              staticClass: "text-element-editor",
                              attrs: { flat: "", tile: "", dense: "" }
                            },
                            [
                              _c("color-picker", {
                                attrs: { value: _vm.headerColor },
                                on: {
                                  input: function($event) {
                                    return _vm.setElement(
                                      "children.0.children.0.children.0.properties.style",
                                      {
                                        color: $event
                                      }
                                    )
                                  }
                                }
                              }),
                              _c("v-divider", { attrs: { vertical: "" } }),
                              _c(
                                "v-btn-toggle",
                                {
                                  staticClass: "mx-1",
                                  attrs: {
                                    value: [
                                      _vm.headerFontWeight,
                                      _vm.headerFontStyle
                                    ],
                                    color: "primary",
                                    dense: "",
                                    borderless: "",
                                    multiple: ""
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.setHeaderStyle($event)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    { attrs: { value: "bold", text: "" } },
                                    [_c("v-icon", [_vm._v("mdi-format-bold")])],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    { attrs: { value: "italic", text: "" } },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-format-italic")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-divider", { attrs: { vertical: "" } }),
                              _c(
                                "div",
                                { staticClass: "mx-1" },
                                [
                                  _c("v-select", {
                                    staticClass: "app-style",
                                    attrs: {
                                      value: _vm.headerFontSize,
                                      items: _vm.fontSizeOptions,
                                      "item-text": "text",
                                      "item-value": "value",
                                      "menu-props": "auto",
                                      "hide-details": "",
                                      outlined: "",
                                      dense: ""
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.setElement(
                                          "children.0.children.0.children.0.properties.style",
                                          {
                                            fontSize: $event
                                          }
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "papers.builder.settings.table.style.example"
                            )
                          )
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex w-100 example--wrapper",
                          style: {
                            fontWeight: _vm.headerFontWeight,
                            fontStyle: _vm.headerFontStyle,
                            color: _vm.headerColor
                          }
                        },
                        [
                          _c("div", { staticStyle: { width: "6%" } }, [
                            _vm._v(" # ")
                          ]),
                          _c("div", { staticStyle: { width: "24%" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "papers.builder.settings.table.style.item"
                                  )
                                ) +
                                " "
                            )
                          ]),
                          _c("div", { staticStyle: { width: "70%" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "papers.builder.settings.table.style.price"
                                  )
                                ) +
                                " "
                            )
                          ])
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "table-setting-style--wrapper" }, [
                      _c("div", { staticClass: "mb-3" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "papers.builder.settings.table.style.content"
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "mb-3" },
                        [
                          _c(
                            "v-toolbar",
                            {
                              staticClass: "text-element-editor",
                              attrs: { flat: "", tile: "", dense: "" }
                            },
                            [
                              _c("color-picker", {
                                attrs: { value: _vm.contentColor },
                                on: {
                                  input: function($event) {
                                    return _vm.setElement(
                                      "children.0.children.1.children.0.properties.style",
                                      {
                                        color: $event
                                      }
                                    )
                                  }
                                }
                              }),
                              _c("v-divider", { attrs: { vertical: "" } }),
                              _c(
                                "v-btn-toggle",
                                {
                                  staticClass: "mx-1",
                                  attrs: {
                                    value: [
                                      _vm.contentFontWeight,
                                      _vm.contentFontStyle
                                    ],
                                    color: "primary",
                                    dense: "",
                                    borderless: "",
                                    multiple: ""
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.setContentStyle($event)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    { attrs: { value: "bold", text: "" } },
                                    [_c("v-icon", [_vm._v("mdi-format-bold")])],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    { attrs: { value: "italic", text: "" } },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-format-italic")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-divider", { attrs: { vertical: "" } }),
                              _c(
                                "div",
                                { staticClass: "mx-1" },
                                [
                                  _c("v-select", {
                                    staticClass: "app-style",
                                    attrs: {
                                      value: _vm.contentFontSize,
                                      items: _vm.fontSizeOptions,
                                      "item-text": "text",
                                      "item-value": "value",
                                      "menu-props": "auto",
                                      "hide-details": "",
                                      outlined: "",
                                      dense: ""
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.setElement(
                                          "children.0.children.1.children.0.properties.style",
                                          {
                                            fontSize: $event
                                          }
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "papers.builder.settings.table.style.example"
                            )
                          )
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex w-100  example--wrapper",
                          style: {
                            fontWeight: _vm.contentFontWeight,
                            fontStyle: _vm.contentFontStyle,
                            color: _vm.contentColor
                          }
                        },
                        [
                          _c("div", { staticStyle: { width: "6%" } }, [
                            _vm._v(" # ")
                          ]),
                          _c("div", { staticStyle: { width: "24%" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "papers.builder.settings.table.style.item"
                                  )
                                ) +
                                " "
                            )
                          ]),
                          _c("div", { staticStyle: { width: "70%" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "papers.builder.settings.table.style.price"
                                  )
                                ) +
                                " "
                            )
                          ])
                        ]
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }