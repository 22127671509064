var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.formula.length
    ? _c(
        "text-editor-inline",
        {
          attrs: {
            value: _vm.active,
            "font-size": _vm.fontSize,
            "text-align": _vm.textAlign,
            "font-weight": _vm.fontWeight,
            "font-style": _vm.fontStyle,
            "text-decoration": _vm.textDecoration,
            color: _vm.color
          },
          on: {
            input: function(key, value) {
              return _vm.setElement(key, value)
            }
          }
        },
        [
          _c(
            "div",
            {
              style: {
                width: "100%",
                height: "100%",
                overflow: _vm.overflow,
                fontSize: _vm.fontSize,
                textAlign: _vm.textAlign,
                fontWeight: _vm.fontWeight,
                fontStyle: _vm.fontStyle,
                textDecoration: _vm.textDecoration,
                color: _vm.color
              }
            },
            [_vm._v(" ข้อมูลคำนวณเอง ")]
          )
        ]
      )
    : _c(
        "div",
        {
          staticClass:
            "element-formula d-flex flex-column align-center justify-center"
        },
        [
          _c("v-icon", [_vm._v("$paper_formula")]),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "750px", scrollable: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "div",
                        _vm._g(
                          _vm._b(
                            { staticClass: "formula-label secondary--text" },
                            "div",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("papers.builder.elements.fx.placeholder")
                              ) +
                              " "
                          )
                        ]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c("fx", {
                ref: "fxElement",
                attrs: {
                  id: _vm.id,
                  title: _vm.title,
                  "number-display-type": _vm.numberDisplayType,
                  formula: _vm.formula
                },
                on: {
                  submit: function($event) {
                    return _vm.setDialog(false)
                  },
                  close: function($event) {
                    return _vm.setDialog(false)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }