import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  computed: {
    ...mapGetters({
      paperBuilderId: 'App/paperBuilderId',
      findPaperBuilder: 'PaperBuilder/find'
    }),
    element () {
      const paper = this.findPaperBuilder(this.paperBuilderId)
      return _.find(paper.canvas, { id: this.id })
    }
  },
  created () {
    // this.setElement = _.debounce(this._setElement, 100)
  },
  methods: {
    ...mapActions({
      updatePaperBuilderCanvas: 'PaperBuilder/updateAllCanvas',
      addPaperBuilderCanvas: 'PaperBuilder/addCanvas'
    }),
    getResult (addr, defaultValue = null) {
      return _.cloneDeep(
        _.result(this.element, addr, defaultValue)
      )
    },
    findElement (addr) {
      return _.cloneDeep(
        _.result(this.element, addr)
      )
    },
    clear (addr, keep = true) {
      const paper = this.findPaperBuilder(this.paperBuilderId)
      const index = _.findIndex(paper.canvas, { id: this.id })
      this.updatePaperBuilderCanvas({
        id: this.paperBuilderId,
        data: _.update(
          paper.canvas,
          `[${index}].${addr}`,
          (props) => ((_.isObject(props)) ? {} : null)
        ),
        keep
      })
    },
    setElement (addr, object, keep = true) {
      const paper = this.findPaperBuilder(this.paperBuilderId)
      const index = _.findIndex(paper.canvas, { id: this.id })
      this.updatePaperBuilderCanvas({
        id: this.paperBuilderId,
        data: _.update(
          paper.canvas,
          `[${index}].${addr}`,
          (props) => ((typeof object === 'object') ? _.assign(props, object) : object)
        ),
        keep
      })
    }
  }
}
