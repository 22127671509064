var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      attrs: { loading: _vm.submitting, color: "primary", depressed: "" },
      on: {
        click: function($event) {
          return _vm.submit()
        }
      }
    },
    [
      _c("v-icon", { attrs: { size: "18", left: "" } }, [
        _vm._v(" $paper_internet ")
      ]),
      _vm._v(" " + _vm._s(_vm.$t("papers.builder.publish")) + " ")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }