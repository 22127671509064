var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "calculator-formula" },
    _vm._l(_vm.numpads, function(numpad, index) {
      return _c(
        "div",
        { key: "btn-row-" + index, staticClass: "numpad" },
        _vm._l(numpad, function(button) {
          return _c(
            "div",
            {
              key: "btn-" + button.type + "-" + button.value,
              staticClass: "btn-numpad"
            },
            [
              button.type === "variables"
                ? _c(
                    "div",
                    { staticClass: "btn-numpad--key btn-numpad--selector" },
                    [
                      _c("variable-selector", {
                        attrs: {
                          items: _vm.variables,
                          "item-value": "field",
                          "item-text": "display",
                          "group-by-value": "tableName",
                          "group-by-text": "tableDescription"
                        },
                        on: {
                          change: function($event) {
                            return _vm.addVariable($event)
                          },
                          "click:add": function($event) {
                            return _vm.clickAddVariable()
                          },
                          "click:edit": function($event) {
                            return _vm.clickEditVariable($event)
                          },
                          "click:remove": function($event) {
                            return _vm.clickRemoveVariable($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                : button.type === "none"
                ? _c("div", { staticClass: "btn-numpad--key btn-numpad--none" })
                : _c("div", {
                    staticClass: "btn-numpad--key",
                    class: button.class || "btn-numpad--value",
                    domProps: {
                      innerHTML: _vm._s(button.display || button.value)
                    },
                    on: {
                      click: function($event) {
                        return _vm.handleEvent(button)
                      }
                    }
                  })
            ]
          )
        }),
        0
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }