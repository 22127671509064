<template>
  <v-btn
    class="py-4"
    color="primary"
    outlined
    depressed
    tile
    x-small
    @click="selectAll()">
    <v-icon small>
      mdi-select-all
    </v-icon>
  </v-btn>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      paperBuilderId: 'App/paperBuilderId',
      findPaperBuilder: 'PaperBuilder/find'
    }),
    paperBuilder () {
      return this.findPaperBuilder(this.paperBuilderId)
    }
  },
  methods: {
    ...mapActions({
      setPaperBuilderActivatedId: 'App/setPaperBuilderActivatedId'
    }),
    selectAll () {
      const activatedId = this.paperBuilder.canvas.map((el) => el.id)
      this.setPaperBuilderActivatedId(activatedId)
    }
  }
}
</script>
<style scoped>

</style>
