<template>
  <v-dialog
    v-model="dialog"
    max-width="400px"
    scrollable>
    <template v-slot:activator="{ attrs }">
      <v-list-item
        v-bind="attrs"
        link
        @click="setDialog(true, true)">
        <v-list-item-subtitle>
          <v-icon
            size="16"
            class="mr-3"
            color="error">
            $paper_trash_outline
          </v-icon>
          <span class="error--text">
            {{ $t('papers.btn.remove') }}
          </span>
        </v-list-item-subtitle>
      </v-list-item>
    </template>
    <alert-remove-card
      :loading="submitting"
      @confirm="submit()"
      @close="setDialog(false)" />
  </v-dialog>
</template>

<script>
// import { CREATE_PAPERGROUP } from '@/resources/graphql'
import AlertRemoveCard from '@/components/Card/AlertRemoveCard.vue'
import { DELETE_PAPER } from '@/resources/graphql'

export default {
  components: {
    AlertRemoveCard
  },
  props: {
    itemId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      submitting: false
    }
  },
  methods: {
    setDialog (dialog = false, timeout = false) {
      if (timeout) {
        setTimeout(() => {
          this.dialog = dialog
        })
      } else {
        this.dialog = dialog
      }
    },
    async submit () {
      this.submitting = true
      await this.$apollo.mutate({
        mutation: DELETE_PAPER,
        variables: {
          input: {
            id: Number(this.itemId)
          }
        }
      })
      this.submitting = false
      this.setDialog(false)
      this.$emit('submitted')
    }
  }
}
</script>

<style scoped>

</style>
