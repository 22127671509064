<template>
  <v-dialog
    v-model="dialog"
    max-width="420px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        color="primary"
        x-small
        icon
        v-on="on">
        <v-icon
          size="17">
          $paper_cog
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="px-4 pb-1">
        <title-label :label="title" />
        <v-spacer />
        <v-icon
          class="dialog-close-tr"
          color="#C8C8C8"
          small
          @click="setDialog(false)">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="px-4">
        <div class="setting-subtitle mt-2 mb-2">
          {{ $t('papers.builder.settings.number.of') }}
        </div>
        <div class="number--container mb-4">
          <v-select
            :value="runningNumberType"
            :items="paperOrderNumberEnum"
            class="app-style"
            item-value="name"
            item-text="description"
            outlined
            dense
            @input="setElement('data', $event)"></v-select>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import paperSettingMixin from '@/mixins/paperSettingMixin'

export default {
  mixins: [paperSettingMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'None'
    },
    runningNumberType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapGetters({
      findEnum: 'Enum/find'
    }),
    paperOrderNumberEnum () {
      return this.findEnum('PaperOrderNumber')?.values || []
    }
  },
  methods: {
    setDialog (dialog = false) {
      this.dialog = dialog
    }
  }
}
</script>
<style scoped>
  .setting-subtitle {
    color: #B8B8B8;
    font-size: 16px;
  }
  .number--container {
    width: 240px;
  }
</style>
