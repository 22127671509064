<template>
  <div class="snt-form-name d-flex align-center">
    <v-form
      v-if="renaming"
      ref="form"
      v-model="valid"
      :readonly="submitting"
      class="d-flex align-center"
      @submit.prevent="submit()">
      <v-text-field
        v-if="renaming"
        v-model="form.name"
        :rules="rule.name"
        :error-messages="error.name.length ? [$t('papers.form.create.nameError')] : []"
        class="app-style mr-2"
        autocomplete="off"
        background-color="#ffffff"
        autofocus
        hide-details
        outlined
        dense
        @input="clearError('name')" />
      <v-btn
        color="primary"
        :loading="submitting"
        :disabled="!valid"
        rounded
        small
        text
        @click="submit()">
        {{ $t('app.btn.submit') }}
      </v-btn>
      <div
        v-if="error.name.length"
        class="mx-3 error--text caption">
        {{ $t('papers.form.create.nameError') }}
      </div>
    </v-form>
    <div
      v-else
      class="w-100 d-flex align-center">
      <v-tooltip
        :disabled="name.length < 64"
        bottom>
        <template #activator="{ on, attrs }">
          <div
            v-bind="attrs"
            class="w-100 font-weight-medium secondary--text mr-4"
            :class="{
              'text-overflow': name.length >= 64,
            }"
            v-on="on">
            {{ name }}
          </div>
        </template>
        <v-card
          max-width="320"
          color="transparent"
          flat
          dark>
          {{ name }}
        </v-card>
      </v-tooltip>
      <v-btn
        :ripple="false"
        icon
        small
        @click="setRenaming(true)">
        <v-icon small>
          mdi-pencil
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  UPDATE_PAPERGROUP
} from '@/resources/graphql'

export default {
  data () {
    return {
      valid: true,
      renaming: false,
      submitting: false,
      form: {
        name: ''
      },
      rule: {
        name: [
          (v) => !!v || this.$t('app.validate.required')
        ]
      },
      error: {
        name: []
      }
    }
  },
  computed: {
    ...mapGetters({
      paperBuilderId: 'App/paperBuilderId',
      findPaperBuilder: 'PaperBuilder/find'
    }),
    paperBuilder () {
      return this.findPaperBuilder(this.paperBuilderId)
    },
    name () {
      return this.paperBuilder?.name || '...'
    }
  },
  methods: {
    ...mapActions({
      updatePaperBuilder: 'PaperBuilder/update',
      markAsPristine: 'PaperBuilder/markAsPristine'
    }),
    clearError (field) {
      this.error[field] = []
    },
    setRenaming (renaming = false) {
      this.renaming = renaming
      this.form.name = this.name
    },
    async submit () {
      if (!this.valid) return
      this.submitting = true
      const { data: { updatePaperGroup } } = await this.$apollo.mutate({
        mutation: UPDATE_PAPERGROUP,
        variables: {
          input: {
            id: this.paperBuilderId,
            name: this.form.name
          }
        }
      })
      if (updatePaperGroup.errors && Object.keys(updatePaperGroup.errors).length) {
        this.error.name = updatePaperGroup.errors
      } else {
        this.updatePaperBuilder({
          id: this.paperBuilderId,
          name: updatePaperGroup.paperGroup.name
        })
        this.setRenaming(false)
        this.$emit('submitted')
      }
      this.submitting = false
    }
  }
}
</script>

<style scoped>
  .snt-form-name {
    max-width: 70%;
  }
  .app-style .v-input__slot{
    min-height: 36px;
  }
</style>
