var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          staticClass: "mr-2",
          attrs: { color: "primary", outlined: "" },
          on: {
            click: function($event) {
              return _vm.print()
            }
          }
        },
        [
          _c("v-icon", { attrs: { size: "18", color: "primary", left: "" } }, [
            _vm._v(" $paper_print_outline ")
          ]),
          _vm._v(" " + _vm._s(_vm.$t("papers.builder.print")) + " ")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }