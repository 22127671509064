<template>
  <v-btn
    :loading="submitting"
    color="primary"
    depressed
    @click="submit()">
    <v-icon
      size="18"
      left>
      $paper_internet
    </v-icon>
    {{ $t('papers.builder.publish') }}
  </v-btn>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { CREATE_PAPER, PUBLUSH_PAPER, UPDATE_PAPER } from '@/resources/graphql'

export default {
  data () {
    return {
      submitting: false
    }
  },
  computed: {
    ...mapGetters({
      paperBuilderId: 'App/paperBuilderId',
      findPaperBuilder: 'PaperBuilder/find'
    }),
    paperBuilder () {
      return this.findPaperBuilder(this.paperBuilderId)
    },
    paperName () {
      return this.paperBuilder?.name
    },
    paperDraftId () {
      return this.paperBuilder?.draftId
    },
    paperSize () {
      return this.paperBuilder?.size
    },
    paperInnerWidth () {
      return this.paperBuilder?.innerWidth
    },
    paperInnerHeight () {
      return this.paperBuilder?.innerHeight
    },
    paperCondition () {
      return this.paperBuilder?.condition
    },
    canvas () {
      return this.paperBuilder?.canvas || []
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Notification/setSnackbar'
    }),
    async submit () {
      this.submitting = true
      let paperDraftId = this.paperDraftId
      if (paperDraftId) {
        await this.$apollo.mutate({
          mutation: UPDATE_PAPER,
          variables: {
            input: {
              id: this.paperDraftId,
              width: Math.floor(this.paperInnerWidth),
              height: Math.floor(this.paperInnerHeight),
              paperSize: this.paperSize,
              condition: this.paperCondition,
              canvas: this.canvas,
              active: true
            }
          }
        })
      } else {
        const { data } = await this.$apollo.mutate({
          mutation: CREATE_PAPER,
          variables: {
            input: {
              paperGroupId: this.paperBuilderId,
              name: this.paperName,
              width: Math.floor(this.paperInnerWidth),
              height: Math.floor(this.paperInnerHeight),
              paperSize: this.paperSize,
              condition: this.paperCondition,
              canvas: this.canvas,
              active: true
            }
          }
        })
        paperDraftId = data.createPaper.paper.id
      }
      await this.$apollo.mutate({
        mutation: PUBLUSH_PAPER,
        variables: {
          input: {
            id: paperDraftId
          }
        }
      })
      this.submitting = false
      this.setSnackbar({
        message: this.$t('papers.snackbar.publishSuccess'),
        type: 'success',
        value: true,
        absolute: false,
        color: '#4F4F4F',
        timeout: 3000
      })
      this.$router.push({ name: 'Papers' })
    }
  }
}
</script>
<style scoped>
</style>
