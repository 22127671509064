<template>
  <v-dialog
    v-model="dialog"
    max-width="420px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        color="primary"
        x-small
        icon
        v-on="on">
        <v-icon
          size="17">
          $paper_cog
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="px-4 pb-1">
        <title-label :label="title" />
        <v-spacer />
        <v-icon
          class="dialog-close-tr"
          color="#C8C8C8"
          small
          @click="setDialog(false)">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="px-4">
        <div class="setting-subtitle mt-2 mb-4">
          {{ $t('papers.builder.settings.2d.shape') }}
        </div>
        <text-field-label :label="$t('papers.builder.settings.2d.color')" />
        <v-color-picker
          :value="backgroundColor"
          :dot-size="25"
          mode="hexa"
          @input="setElement('properties.style.backgroundColor', $event)">
        </v-color-picker>
        <div class="setting-subtitle mt-2 mb-4">
          {{ $t('papers.builder.settings.2d.border') }}
        </div>
        <div class="d-flex mb-3">
          <text-field-label
            :label="$t('papers.builder.settings.2d.color')"
            class="mr-2" />
          <v-spacer />
          <div class="w-70">
            <snt-color-picker
              :value="borderColor"
              @input="setElement('properties.style.borderColor', $event)" />
          </div>
        </div>
        <div class="d-flex mb-3">
          <text-field-label
            class="mr-2"
            :label="$t('papers.builder.settings.line.size')" />
          <v-spacer />
          <div class="w-70">
            <v-select
              :value="borderWidth"
              :items="borderWidthTypes"
              :placeholder="$t('app.none')"
              class="app-style"
              item-text="text"
              item-value="value"
              hide-details
              outlined
              dense
              @input="setElement('properties.style.borderWidth', $event)" />
          </div>
        </div>
        <div class="d-flex">
          <text-field-label
            class="mr-2"
            :label="$t('papers.builder.settings.line.style')" />
          <v-spacer />
          <div class="w-70">
            <v-select
              :value="borderStyle"
              :items="borderStyleTypes"
              :placeholder="$t('app.none')"
              class="app-style"
              item-text="text"
              item-value="value"
              hide-details
              outlined
              dense
              @input="setElement('properties.style.borderStyle', $event)" />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import paperSettingMixin from '@/mixins/paperSettingMixin'
import SntColorPicker from '@/components/Form/SntColorPicker.vue'

export default {
  components: { SntColorPicker },
  mixins: [paperSettingMixin],
  props: {
    title: {
      type: String,
      default: 'None'
    },
    backgroundColor: {
      type: String,
      default: '#ffffffff'
    },
    borderColor: {
      type: String,
      default: '#ffffffff'
    },
    borderStyle: {
      type: String,
      default: 'solid'
    },
    borderWidth: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      dialog: false,
      borderStyleTypes: [
        { text: this.$t('papers.builder.settings.line.solid'), value: 'solid' },
        { text: this.$t('papers.builder.settings.line.dashed'), value: 'dashed' }
      ],
      borderWidthTypes: [
        { text: this.$t('app.none'), value: '0px' },
        { text: '1px', value: '1px' },
        { text: '2px', value: '2px' },
        { text: '4px', value: '4px' },
        { text: '6px', value: '6px' },
        { text: '8px', value: '8px' },
        { text: '10px', value: '10px' },
        { text: '16px', value: '16px' }
      ]
    }
  },
  methods: {
    setDialog (dialog = false) {
      this.dialog = dialog
    }
  }
}
</script>
<style scoped>
  .setting-subtitle {
    color: #B8B8B8;
    font-size: 16px;
  }
</style>
