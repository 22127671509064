<template>
  <v-menu
    v-model="menu"
    min-width="100"
    transition="slide-x-transition"
    offset-x>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        x-small
        icon
        v-on="on">
        <v-icon
          small>
          mdi-dots-horizontal
        </v-icon>
      </v-btn>
    </template>
    <v-list
      class="py-0"
      dense>
      <v-list-item
        :ripple="false"
        class="px-3"
        link
        @click="duplicate()">
        <v-list-item-subtitle>
          <v-icon
            size="16"
            class="mr-3">
            $paper_copy
          </v-icon>
          <span> {{ $t('papers.btn.duplicate') }}</span>
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item
        :ripple="false"
        class="px-3"
        link
        @click="overlap()">
        <v-list-item-subtitle>
          <v-icon
            size="16"
            class="mr-3">
            mdi-circle-multiple
          </v-icon>
          <span> {{ $t('papers.btn.overlap') }} </span>
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item
        :ripple="false"
        class="px-3"
        link
        @click="remove()">
        <v-list-item-subtitle>
          <v-icon
            size="16"
            class="mr-3"
            color="error">
            $paper_trash_outline
          </v-icon>
          <span class="error--text"> {{ $t('papers.btn.remove') }}</span>
        </v-list-item-subtitle>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import paperSettingMixin from '@/mixins/paperSettingMixin'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

export default {
  mixins: [paperSettingMixin],
  props: {

  },
  data () {
    return {
      menu: false
    }
  },
  methods: {
    overlap () {
      const paper = this.findPaperBuilder(this.paperBuilderId)
      const canvas = _.sortBy(
        _.cloneDeep(paper.canvas),
        ({ id }) => (id === this.id ? 1 : 0)
      )
      this.updatePaperBuilderCanvas({
        id: this.paperBuilderId,
        data: canvas
      })
    },
    duplicate () {
      const paper = this.findPaperBuilder(this.paperBuilderId)
      const element = paper.canvas.find((item) => item.id === this.id)
      const data = _.cloneDeep(element)
      data.id = uuid()
      const left = this.clearSuffixPixel(data.properties.style.left)
      const top = this.clearSuffixPixel(data.properties.style.top)
      this.addPaperBuilderCanvas({
        id: this.paperBuilderId,
        data: _.update(
          data,
          'properties.style',
          (style) => _.assign(style, {
            left: `${left + 24}px`,
            top: `${top + 24}px`
          })
        )
      })
      this.$emit('after:duplicate', data.id)
    },
    remove () {
      const paper = this.findPaperBuilder(this.paperBuilderId)
      this.updatePaperBuilderCanvas({
        id: this.paperBuilderId,
        data: paper.canvas.filter((item) => item.id !== this.id)
      })
    },
    clearSuffixPixel (str) {
      return _.toNumber(
        _.replace(str, 'px', '')
      )
    }
  }
}
</script>
<style scoped>

</style>
