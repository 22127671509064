var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "420px", scrollable: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { attrs: { color: "primary", "x-small": "", icon: "" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(" mdi-plus-circle ")
                  ])
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "px-4" },
            [
              _c("title-label", {
                attrs: {
                  label: _vm.$t("papers.builder.settings.products.create")
                }
              }),
              _c("v-spacer"),
              _c(
                "v-icon",
                {
                  attrs: { small: "" },
                  on: {
                    click: function($event) {
                      return _vm.setDialog(false)
                    }
                  }
                },
                [_vm._v(" mdi-close ")]
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "px-4 pb-0" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { readonly: _vm.submitting },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submit()
                    }
                  },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c("text-field-label", {
                    attrs: {
                      label: _vm.$t("papers.builder.settings.products.name")
                    }
                  }),
                  _c(
                    "div",
                    [
                      _c("v-text-field", {
                        staticClass: "app-style",
                        attrs: {
                          rules: _vm.rule.name,
                          autocomplete: "off",
                          autofocus: "",
                          outlined: "",
                          dense: ""
                        },
                        model: {
                          value: _vm.name,
                          callback: function($$v) {
                            _vm.name = $$v
                          },
                          expression: "name"
                        }
                      })
                    ],
                    1
                  ),
                  _c("text-field-label", {
                    attrs: {
                      label: _vm.$t("papers.builder.settings.products.formula")
                    }
                  }),
                  _c(
                    "div",
                    [
                      _c("editor", {
                        staticClass: "mb-3",
                        attrs: {
                          value: _vm.series,
                          variables: _vm.variables,
                          error: _vm.errorType
                        },
                        on: {
                          input: function($event) {
                            return _vm.setSeries($event)
                          }
                        }
                      }),
                      _c("numpad", {
                        attrs: {
                          value: _vm.calculator.points,
                          variables: _vm.variables
                        },
                        on: {
                          input: function($event) {
                            return _vm.setPoints($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pb-4" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "px-4",
                  attrs: { color: "primary", rounded: "", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.setDialog(false)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("app.btn.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "px-4",
                  attrs: {
                    loading: _vm.submitting,
                    disabled: !_vm.valid || !!_vm.errorType,
                    color: "primary",
                    rounded: "",
                    depressed: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.submit()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("app.btn.submit")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }