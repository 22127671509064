var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { width: "258px", flat: "" } },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-0" },
        [
          _c(
            "div",
            { staticClass: "d-flex mb-1 align-center" },
            [
              _vm.itemActive
                ? _c(
                    "v-icon",
                    {
                      staticClass: "mr-2",
                      attrs: { color: "primary", size: "8" }
                    },
                    [_vm._v(" mdi-circle ")]
                  )
                : _vm._e(),
              !_vm.renaming
                ? _c(
                    "div",
                    {
                      staticClass: "d-flex my-2 align-center paper-draft-card"
                    },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            disabled: _vm.itemName.length < 16,
                            bottom: ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "div",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "mr-1",
                                            class: {
                                              "text-overflow":
                                                _vm.itemName.length >= 16
                                            }
                                          },
                                          "div",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" " + _vm._s(_vm.itemName) + " ")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2869138511
                          )
                        },
                        [
                          _c(
                            "v-card",
                            {
                              attrs: {
                                "max-width": "320",
                                color: "transparent",
                                flat: "",
                                dark: ""
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.itemName) + " ")]
                          )
                        ],
                        1
                      ),
                      _c("draft-action", {
                        attrs: {
                          "item-id": _vm.itemId,
                          "item-active": _vm.itemActive
                        },
                        on: {
                          "click:rename": function($event) {
                            return _vm.setRenaming(true)
                          },
                          "after:removed": function($event) {
                            return _vm.afterRemoved($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                : _c(
                    "v-form",
                    {
                      ref: "form",
                      staticClass: "d-flex align-center",
                      attrs: { readonly: _vm.submitting },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submit()
                        }
                      },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "app-style mr-2",
                        attrs: {
                          value: _vm.itemName,
                          rules: _vm.rule.name,
                          autofocus: "",
                          "hide-details": "",
                          outlined: "",
                          dense: ""
                        },
                        on: {
                          input: function($event) {
                            return _vm.setName($event)
                          }
                        }
                      }),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            loading: _vm.submitting,
                            disabled: !_vm.valid,
                            rounded: "",
                            small: "",
                            text: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.submit()
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("app.btn.submit")) + " ")]
                      )
                    ],
                    1
                  )
            ],
            1
          ),
          _c("v-hover", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var hover = ref.hover
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "draft-card",
                        style: {
                          backgroundImage: _vm.base64
                            ? "url(" + _vm.base64 + ")"
                            : "#ffffff",
                          backgroundSize: "contain"
                        }
                      },
                      [
                        _c("v-fade-transition", [
                          hover && !_vm.itemActive
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex w-100 h-100 d-flex justify-center align-end draft-action-overlay"
                                },
                                [
                                  !_vm.itemActive
                                    ? _c("active-draft-button", {
                                        staticClass: "ma-4",
                                        attrs: { "item-id": _vm.itemId }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("div", { staticClass: "overflow-pixel" }, [
        _c("div", {
          staticClass: "relative",
          attrs: { id: "draft-canvas-" + _vm.itemId }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }