import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      holdCtrl: false
    }
  },
  computed: {
    ...mapGetters({
      paperBuilderId: 'App/paperBuilderId',
      activatedId: 'App/paperBuilderActivatedId',
      findPaperBuilder: 'PaperBuilder/find',
      paperBuilderList: 'PaperBuilder/list'
    }),
    paperBuilder () {
      return this.findPaperBuilder(this.paperBuilderId)
    },
    isMultiActivated () {
      return this.activatedId && this.activatedId.length > 1
    }
  },
  created () {
    window.addEventListener('keyup', (e) => {
      e.preventDefault()
      this.holdCtrl = false
    })
    window.addEventListener('keydown', (e) => {
      if (e.ctrlKey) {
        e.preventDefault()
        this.holdCtrl = true
        switch (e.key.toLowerCase()) {
          case 'a':
            this.selectAll()
            break
          case 'z':
            this.undo()
            break
          case 'x':
            this.redo()
            break
          default:
            break
        }
      } else if (['Delete'].includes(e.key)) {
        this.removeElementIsActive()
      } else if (e.key === 'Escape') {
        this.deSelectAll()
      } else if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
        if (this.activatedId && this.activatedId.length) {
          if (e.target.nodeName !== 'TEXTAREA' || this.isMultiActivated) {
            e.preventDefault()
            this.debounceMove(e)
          }
        }
      }
    })
    this.debounceOnDrag = _.debounce(this.onDrag, 150)
    this.debounceMove = _.debounce(this.onMove, 150)
  },
  methods: {
    ...mapActions({
      setPaperBuilderDrop: 'App/setPaperBuilderDrop',
      setPaperBuilderActivatedId: 'App/setPaperBuilderActivatedId',
      undoPaperBuilder: 'PaperBuilder/undoCanvas',
      redoPaperBuilder: 'PaperBuilder/redoCanvas',
      updatePaperBuilder: 'PaperBuilder/update',
      updatePaperBuilderCanvas: 'PaperBuilder/updateAllCanvas'
    }),
    onMove (e) {
      const paper = this.findPaperBuilder(this.paperBuilderId)
      this.activatedId.forEach((activatedId) => {
        const index = _.findIndex(paper.canvas, { id: activatedId })
        let left = this.clearSuffixPixel(
          _.result(paper.canvas, `[${index}].properties.style.left`, 0)
        )
        let top = this.clearSuffixPixel(
          _.result(paper.canvas, `[${index}].properties.style.top`, 0)
        )
        if (e.key === 'ArrowLeft') left -= this.gridWidth
        if (e.key === 'ArrowRight') left += this.gridWidth
        if (e.key === 'ArrowUp') top -= this.gridHeight
        if (e.key === 'ArrowDown') top += this.gridHeight
        paper.canvas = _.update(
          paper.canvas,
          `[${index}].properties.style`,
          (style) => _.merge(style, {
            left: `${_.round(left, -1)}px`,
            top: `${_.round(top, -1)}px`
          })
        )
      })
      this.updatePaperBuilderCanvas({
        id: this.paperBuilderId,
        data: paper.canvas
      })
    },
    onDrop (e) {
      const layerX = _.round(e.layerX, -1)
      const layerY = _.round(e.layerY, -1)
      this.setPaperBuilderDrop({ left: `${layerX}px`, top: `${layerY}px` })
    },
    onDrag (id, left, top) {
      const paper = this.findPaperBuilder(this.paperBuilderId)
      const index = _.findIndex(paper.canvas, { id })
      this.updatePaperBuilderCanvas({
        id: this.paperBuilderId,
        data: _.update(
          paper.canvas,
          `[${index}].properties.style`,
          (style) => _.merge(style, {
            left: `${_.round(left, -1)}px`,
            top: `${_.round(top, -1)}px`
          })
        )
      })
    },
    onInnerResize (width, height) {
      this.updatePaperBuilder({
        id: this.paperBuilderId,
        innerWidth: width,
        innerHeight: height
      })
    },
    onResize (id, left, top, width, height) {
      const paper = this.findPaperBuilder(this.paperBuilderId)
      const index = _.findIndex(paper.canvas, { id })
      this.updatePaperBuilderCanvas({
        id: this.paperBuilderId,
        data: _.update(
          paper.canvas,
          `[${index}].properties.style`,
          (style) => {
            const newStyle = {
              left: `${left}px`,
              top: `${top}px`,
              width: `${width}px`
            }
            if (height) {
              newStyle.height = `${height}px`
            }
            return _.assign(style, newStyle)
          }
        )
      })
    },
    isActivated (id) {
      return this.$_.includes(this.activatedId, id)
    },
    onActivated (id) {
      if (this.holdCtrl) {
        const activated = this.$_.knock(this.activatedId || [], id)
        this.setPaperBuilderActivatedId(activated)
      } else {
        this.setPaperBuilderActivatedId([id])
      }
    },
    onDeactivated (id) {
      if (this.isActivated(id)) {
        if (this.holdCtrl) {
          const activated = this.$_.knock(this.activatedId || [], id)
          this.setPaperBuilderActivatedId(activated)
        } else {
          this.setPaperBuilderActivatedId([])
        }
      }
    },
    removeElementIsActive () {
      if (this.isMultiActivated) {
        const paper = this.findPaperBuilder(this.paperBuilderId)
        this.updatePaperBuilderCanvas({
          id: this.paperBuilderId,
          data: paper.canvas.filter(({ id }) => !this.activatedId.includes(id))
        })
      } else if (this.activatedId && this.activatedId.length) {
        const paper = this.findPaperBuilder(this.paperBuilderId)
        const element = paper.canvas.find(({ id }) => this.activatedId.includes(id))
        if (!['text'].includes(element.type)) {
          this.updatePaperBuilderCanvas({
            id: this.paperBuilderId,
            data: paper.canvas.filter(({ id }) => !this.activatedId.includes(id))
          })
        }
      }
    },
    selectAll () {
      const activatedId = this.paperBuilder.canvas.map((el) => el.id)
      this.setPaperBuilderActivatedId(activatedId)
    },
    deSelectAll () {
      this.setPaperBuilderActivatedId([])
    },
    undo () {
      this.undoPaperBuilder({
        id: this.paperBuilderId
      })
    },
    redo () {
      this.redoPaperBuilder({
        id: this.paperBuilderId
      })
    },
    onClickOutside  (event) {
      if (['my-canvas', 'paper', 'paper-resizable'].includes(event.target.id)) {
        this.deSelectAll()
      }
    }
  }
}
