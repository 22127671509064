<template>
  <v-dialog
    v-model="dialog"
    max-width="420px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        color="primary"
        x-small
        icon
        v-on="on">
        <v-icon
          size="17">
          $paper_cog
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="px-4 pb-1">
        <title-label :label="title" />
        <v-spacer />
        <v-icon
          class="dialog-close-tr"
          color="#C8C8C8"
          small
          @click="setDialog(false)">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="px-4">
        <div class="setting-subtitle mt-2 mb-2">
          {{ $t('papers.builder.settings.date.of') }}
        </div>
        <div class="date--container mb-4">
          <v-select
            :value="dateField"
            :items="paperDateEnum"
            class="app-style"
            item-value="name"
            item-text="description"
            hide-details
            outlined
            dense
            @input="setElement('data', $event)"></v-select>
        </div>
        <div class="setting-subtitle mt-2 mb-2">
          {{ $t('papers.builder.settings.date.style') }}
        </div>
        <div class="date--container mb-4">
          <v-select
            :value="dateFormat"
            :items="paperStrftimeEnum"
            class="app-style"
            item-value="name"
            item-text="description"
            hide-details
            outlined
            dense
            @input="setDateFormat($event)"></v-select>
        </div>
        <v-row
          no-gutters
          class="mb-4">
          <v-col cols="6">
            <text-field-label
              :label="$t('papers.builder.settings.date.display')"
              class="mr-2" />
          </v-col>
          <v-col cols="5">
            <div class="mr-2">
              <v-select
                v-if="dateFormat === '%d/%m/%Y'"
                :value="dateDisplayType"
                :items="paperDateDisplayEnum"
                class="app-style"
                item-value="name"
                item-text="description"
                hide-details
                outlined
                dense
                @input="setDateDisplayType($event, dateFormat)" />
              <v-select
                v-else
                :value="timeDisplayType"
                :items="paperTimeDisplayEnum"
                class="app-style"
                item-value="name"
                item-text="description"
                hide-details
                outlined
                dense
                @input="setDatetimeDisplayType($event, dateFormat)" />
              <!--  -->
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import paperSettingMixin from '@/mixins/paperSettingMixin'

export default {
  mixins: [paperSettingMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'None'
    },
    dateField: {
      type: String,
      default: ''
    },
    dateFormat: {
      type: String,
      default: ''
    },
    dateDisplayType: {
      type: String,
      default: ''
    },
    timeDisplayType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapGetters({
      findEnum: 'Enum/find'
    }),
    paperDateEnum () {
      return this.findEnum('PaperDate')?.values || []
    },
    paperStrftimeEnum () {
      return this.findEnum('PaperStrftime')?.values || []
    },
    paperDateDisplayEnum () {
      return this.findEnum('PaperDateDisplay')?.values || []
    },
    paperTimeDisplayEnum () {
      return this.findEnum('PaperTimeDisplay')?.values || []
    }
  },
  methods: {
    setDateFormat (value) {
      if (value === '%d/%m/%Y') {
        this.setDateDisplayType('word', value)
      } else {
        this.setDatetimeDisplayType('word', value)
      }
    },
    setDateDisplayType (value, dateFormat) {
      this.clear('fx', false)
      this.setElement('fx', {
        dateFormat: {
          name: 'dateFormat',
          value: dateFormat
        },
        dateDisplayType: {
          name: 'dateDisplayType',
          value
        }
      })
    },
    setDatetimeDisplayType (value, dateFormat) {
      this.clear('fx', false)
      this.setElement('fx', {
        dateFormat: {
          name: 'dateFormat',
          value: dateFormat
        },
        timeDisplayType: {
          name: 'timeDisplayType',
          value
        }
      })
    },
    setDialog (dialog = false) {
      this.dialog = dialog
    }
  }
}
</script>
<style scoped>
  .setting-subtitle {
    color: #B8B8B8;
    font-size: 16px;
  }
  .date--container {
    width: 240px;
  }
</style>
