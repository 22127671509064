<template>
  <div
    class="calc-editor calc-editor-container"
    :class="{ 'calc-editor-container--error': error }">
    <div class="calc-editor-body">
      <div
        class="calc-editor-formula">
        <template v-for="(obj, index) in series">
          <div
            v-if="obj.type ==='number'"
            :key="`smart-input-number-${index}`">
            <v-text-field
              :value="Number(obj.value)"
              type="number"
              class="input-calculator input-calculator--number"
              hide-details
              dense
              outlined
              @change="update($event, obj.id)" />
          </div>
          <div
            v-else-if="obj.type === 'operator'"
            :key="`smart-input-operator-${index}`">
            <v-select
              :value="obj.value"
              class="input-calculator input-calculator--operator"
              :items="operator"
              item-text="display"
              item-value="type"
              hide-details
              dense
              outlined
              @change="update($event, obj.id)" />
          </div>
          <div
            v-else-if="obj.type ==='associate' || obj.type ==='saved_data'"
            :key="`smart-input-associate-${index}`"
            class="d-flex">
            <variable-selector
              :value="obj.value"
              :items="variables"
              item-value="field"
              item-text="display"
              group-by-value="tableName"
              group-by-text="tableDescription"
              show-selected
              @change="update($event, obj.id)"
            />
          </div>
          <div
            v-if="['aggregator', 'begin-aggregator', 'begin-bracket'].includes(obj.type)"
            :key="`smart-input-bracket-${index}`">
            (
          </div>
          <div
            v-else-if="['end-aggregator', 'end-bracket'].includes(obj.type)"
            :key="`smart-input-bracket-${index}`">
            )
          </div>
          <div
            v-else-if="['end-bracket'].includes(obj.type)"
            :key="`smart-input-bracket-${index}`">
            )
          </div>
        </template>
      </div>
      <v-spacer />
      <div class="d-flex">
        <div class="error--text">
          {{ errorMessage }}
        </div>
        <v-spacer />
        <v-btn
          v-if="$route.query.dev"
          color="primary"
          text
          x-small
          @click="setTextMode(!isTextMode)">
          <v-icon small>
            mdi-format-text
          </v-icon>
        </v-btn>
      </div>
    </div>
    <div class="calc-editor-footer">
      <v-icon
        v-if="series.length"
        class="btn-calc-editor-close"
        color="#DADADA"
        small
        @click="clear()">
        mdi-close-circle
      </v-icon>
    </div>
  </div>
</template>

<script>
import VariableSelector from './VariableSelector.vue'

export default {
  components: {
    VariableSelector
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    variables: {
      type: Array,
      default: () => []
    },
    error: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      dialog: true,
      isTextMode: false,
      test: '',
      statements: [],
      selectItems: [
        { name: 'sum', description: 'SUM' },
        { name: 'avg', description: 'AVG' },
        { name: 'count', description: 'COUNT' },
        { name: 'max', description: 'MAX' },
        { name: 'min', description: 'MIN' }
      ],
      operator: [
        { type: 'plus', display: '+' },
        { type: 'minus', display: '-' },
        { type: 'times', display: 'x' },
        { type: 'divided_by', display: '/' }
      ],
      tags: [
        { text: 'เลขที่ออเดอร์', value: '#ORDER_NUMBER#' }
      ]
    }
  },
  computed: {
    series () {
      return this.value || []
    },
    errorMessage () {
      switch (this.error) {
        case 1:
          return 'กรุณาเพิ่มตัวเลข หรือข้อมูล'
        case 2:
          return 'SUM() ของคุณไม่มีข้อมูลในวงเล็บ กรุณาระบุข้อมูลในวงเล็บก่อน'
        case 3:
          return 'กรุณาเลือกการคำนวณทุกครั้ง เช่น SUM ( 250 + ยอดขาย )'
        case 4:
          return 'คุณใส่รูปแบบสมการไม่ถูกต้อง'
        default:
          return null
      }
    }
  },
  methods: {
    setTextMode (isTextMode = false) {
      this.isTextMode = isTextMode
    },
    clear () {
      this.$emit('input', null)
    },
    update (value, id) {
      this.$emit('input', {
        value, id
      })
    }
  }
}
</script>

<style>
  .calc-editor {
    background-color: #ffffff;
  }
  .calc-editor .v-icon.v-icon.btn-calc-editor-close:hover {
    color:#FF8080 !important;
  }
  .calc-editor .v-input.v-input--dense.input-calculator.v-text-field--outlined fieldset {
    border: 1px solid #E4E4E4;
  }
  .calc-editor .v-input.v-input--dense.input-calculator.input-calculator--number .v-input__control > .v-input__slot {
    min-height: 28px !important;
    max-width: 64px;
    height: 28px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    padding: 0 8px !important;
  }
  .calc-editor .v-input.v-input--dense.input-calculator.input-calculator--field .v-input__control > .v-input__slot {
    min-height: 28px !important;
    max-width: 36px;
    height: 28px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    padding: 0 8px !important;
  }
  .calc-editor .v-input.v-input--dense.input-calculator.input-calculator--operator .v-input__control > .v-input__slot {
    min-height: 28px !important;
    max-width: 46px;
    height: 28px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    padding: 0 8px !important;
  }
  .calc-editor .v-input.v-input--dense.input-calculator.input-calculator--aggregator .v-input__control > .v-input__slot {
    min-height: 28px !important;
    max-width: 90px;
    height: 28px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    padding: 0 8px !important;
  }
  .calc-editor .v-input.v-input--dense.input-calculator .v-input__control .v-icon.v-icon {
    font-size: 16px;
  }
  .calc-editor .v-input.v-input--dense.input-calculator .v-input__control .v-input__append-inner {
    margin-top: 2px  !important;
  }
  .calc-editor .v-input.v-input--dense.input-calculator .v-input__control  .v-input__icon {
    height: 24px;
    min-width: 10px;
    width: 10px;
  }
</style>
<style scoped>
  .calc-editor-container {
    display: flex;
    flex: 1 0 auto;
    min-height: 120px;
    border:1px solid #ddd;
    padding: 12px;
  }
  .calc-editor-container.calc-editor-container--error {
    border:2px solid #FF8080;
    padding: 11px;
  }
  .calc-editor-body {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    width: calc(100% - 38px);
  }
  .calc-editor-footer {
    display: flex;
    flex: 1 0 auto;
    width: 38px;
    justify-content: center;
    align-items: center;
  }
  .calc-editor-formula {
    display: flex;
    flex: 1 0 auto;
    flex-wrap: wrap;
    row-gap: 0.5em;
    column-gap: 0.5em;
  }
</style>
