var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        transition: "slide-x-transition",
        "offset-x": ""
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "div",
                _vm._g(
                  _vm._b({ staticClass: "w-100" }, "div", attrs, false),
                  on
                ),
                [
                  !_vm.showSelected
                    ? _c(
                        "div",
                        { staticClass: "btn-variable-selector" },
                        [
                          _c("v-list-item-title", { staticClass: "body-2" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("app.select.placeholder")) +
                                " "
                            )
                          ]),
                          _c("v-spacer"),
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v(" mdi-menu-down ")
                          ])
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "table-group-input--value" },
                        [
                          _c("v-list-item-title", [
                            _vm._v(" " + _vm._s(_vm.selectedItem) + " ")
                          ])
                        ],
                        1
                      )
                ]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.menu,
        callback: function($$v) {
          _vm.menu = $$v
        },
        expression: "menu"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { width: 320 } },
        [
          _c("v-text-field", {
            staticClass: "px-2 pb-2 mt-0",
            attrs: {
              placeholder: _vm.$t("app.form.search.placeholder"),
              "prepend-inner-icon": "mdi-magnify",
              autofocus: "",
              "hide-details": ""
            },
            model: {
              value: _vm.searchText,
              callback: function($$v) {
                _vm.searchText = $$v
              },
              expression: "searchText"
            }
          }),
          _c(
            "div",
            { staticClass: "variable-selector" },
            [
              _c(
                "v-list",
                { staticClass: "pa-0" },
                [
                  _vm._l(_vm.selectItems, function(group) {
                    return [
                      _c(
                        "div",
                        {
                          key: "tb-list-" + group.name,
                          staticClass: "variable-group-name-list pa-2"
                        },
                        [_vm._v(" " + _vm._s(group.description) + " ")]
                      ),
                      _vm._l(group.items, function(item, index) {
                        return [
                          !item.isDeleted
                            ? _c(
                                "v-list-item",
                                {
                                  key:
                                    "tb-" +
                                    group.name +
                                    "-" +
                                    item.name +
                                    "-list",
                                  staticClass: "variable-selector--list mx-2",
                                  attrs: { link: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.select(group.name, item.name)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(item.description))
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      group.name !== "@saved_data"
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "variable-selector--btn-add",
                                              attrs: {
                                                color: "#ffffff",
                                                tag: "a",
                                                small: ""
                                              }
                                            },
                                            [_vm._v(" mdi-plus-circle ")]
                                          )
                                        : [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "variable-selector--btn-add mr-2",
                                                attrs: {
                                                  color: "#ffffff",
                                                  small: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.edit(
                                                      $event,
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" $pencilWhite ")]
                                            ),
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "variable-selector--btn-add",
                                                attrs: {
                                                  color: "#ffffff",
                                                  size: "18"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.remove(
                                                      $event,
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" $trashWhite ")]
                                            )
                                          ]
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      })
                    ]
                  })
                ],
                2
              ),
              _vm.selectItems.length === 0
                ? _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-center align-center h-100 secondary--text"
                    },
                    [_vm._v(" No items ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }