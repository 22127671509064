<template>
  <div />
</template>

<script>
import paperSettingMixin from '@/mixins/paperSettingMixin'
// import ColorPicker from '../ColorPicker.vue'

export default {
  // components: {
  //   ColorPicker
  // },
  mixins: [paperSettingMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'None'
    },
    text: {
      type: String,
      default: ''
    },
    fontSize: {
      type: String,
      default: '14px'
    },
    textAlign: {
      type: String,
      default: 'left'
    },
    fontWeight: {
      type: String,
      default: 'normal'
    },
    fontStyle: {
      type: String,
      default: 'normal'
    },
    color: {
      type: String,
      default: 'initial'
    }
  },
  data () {
    return {
      menu: false,
      fontSizeOptions: [
        { text: 10, value: '10px' },
        { text: 12, value: '12px' },
        { text: 14, value: '14px' },
        { text: 16, value: '16px' },
        { text: 18, value: '18px' },
        { text: 20, value: '20px' },
        { text: 22, value: '22px' },
        { text: 24, value: '24px' },
        { text: 36, value: '36px' },
        { text: 72, value: '72px' }
      ]
    }
  }
}
</script>
<style>
  .text-element-editor .v-toolbar__content {
    padding: 0;
  }
  .text-element-editor .v-btn.v-size--default {
    min-width: 40px;
  }
</style>
