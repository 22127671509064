<template>
  <div class="paper-builder">
    <action-bar />
    <div class="pb-6 px-6 w-100">
      <div class="d-flex justify-space-between w-100">
        <div class="mr-5 overflow-auto">
          <div class="d-flex align-center pt-2 pb-2">
            <name-form />
            <v-spacer />
            <select-all-button class="mr-2" />
            <example />
          </div>
          <v-sheet
            class="my-grid"
            width="100%">
            <st-canvas />
          </v-sheet>
        </div>
        <div class="mt-13">
          <v-sheet
            :min-width="toolWidth"
            :width="toolWidth"
            :max-height="730">
            <draggable-elements />
          </v-sheet>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { GET_PAPER_ENUM,
  GET_PAPERSIZE_ENUM,
  GET_PAPERCONDITION_ENUM,
  GET_PAPERGROUP,
  GET_PAPERFIELDS,
  GET_PAPERGETSIZE
} from '@/resources/graphql'
import ActionBar from './components/ActionBar.vue'
import DraggableElements from './components/DraggableElements.vue'
import StCanvas from './components/Canvas.vue'
import Example from './components/Example.vue'
import NameForm from './components/NameForm.vue'
import SelectAllButton from './components/SelectAllButton.vue'

export default {
  name: 'Paper',
  components: {
    ActionBar,
    StCanvas,
    DraggableElements,
    Example,
    SelectAllButton,
    NameForm
  },
  apollo: {
    paperGetSize: () => ({
      query: GET_PAPERGETSIZE,
      fetchPolicy: 'no-cache',
      result () {
        this.$apollo.queries.paperGroup.skip = false
        this.$apollo.queries.paperGroup.refetch()
      }
    }),
    paperFieldOrder: () => ({
      query: GET_PAPERFIELDS,
      variables () {
        return {
          tableName: 'order'
        }
      },
      update: (data) => data.paperFields,
      result ({ data: { paperFields } }) {
        this.addTableField({
          id: 'paperFieldOrder',
          name: 'paperFieldOrder',
          values: paperFields
        })
      }
    }),
    paperFieldPurchase: () => ({
      query: GET_PAPERFIELDS,
      variables () {
        return {
          tableName: 'purchase'
        }
      },
      update: (data) => data.paperFields,
      result ({ data: { paperFields } }) {
        this.addTableField({
          id: 'paperFieldPurchase',
          name: 'paperFieldPurchase',
          values: paperFields
        })
      }
    }),
    paperFieldSetOrder: () => ({
      query: GET_PAPERFIELDS,
      variables () {
        return {
          tableName: 'set_purchase'
        }
      },
      update: (data) => data.paperFields,
      result ({ data: { paperFields } }) {
        this.addTableField({
          id: 'paperFieldSetOrder',
          name: 'paperFieldSetOrder',
          values: paperFields
        })
      }
    }),
    paperFieldShop: () => ({
      query: GET_PAPERFIELDS,
      variables () {
        return {
          tableName: 'shop'
        }
      },
      update: (data) => data.paperFields,
      result ({ data: { paperFields } }) {
        this.addTableField({
          id: 'paperFieldShop',
          name: 'paperFieldShop',
          values: paperFields
        })
      }
    }),
    paperFieldStock: () => ({
      query: GET_PAPERFIELDS,
      variables () {
        return {
          tableName: 'stock'
        }
      },
      update: (data) => data.paperFields,
      result ({ data: { paperFields } }) {
        this.addTableField({
          id: 'paperFieldStock',
          name: 'paperFieldStock',
          values: paperFields
        })
      }
    }),
    paperFieldReseller: () => ({
      query: GET_PAPERFIELDS,
      variables () {
        return {
          tableName: 'reseller'
        }
      },
      update: (data) => data.paperFields,
      result ({ data: { paperFields } }) {
        this.addTableField({
          id: 'paperFieldReseller',
          name: 'paperFieldReseller',
          values: paperFields
        })
      }
    }),
    paperFieldPromoCode: () => ({
      query: GET_PAPERFIELDS,
      variables () {
        return {
          tableName: 'promo_code'
        }
      },
      update: (data) => data.paperFields,
      result ({ data: { paperFields } }) {
        this.addTableField({
          id: 'paperFieldPromoCode',
          name: 'paperFieldPromoCode',
          values: paperFields
        })
      }
    }),
    paperSizeEnum: () => ({
      query: GET_PAPERSIZE_ENUM,
      update: (data) => data.__type,
      result ({ data: { __type } }) {
        this.addEnum({
          id: 'paperSizeEnum',
          name: 'paperSizeEnum',
          values: __type.enumValues
        })
      }
    }),
    paperConditionEnum: () => ({
      query: GET_PAPERCONDITION_ENUM,
      update: (data) => data.__type,
      result ({ data: { __type } }) {
        this.addEnum({
          id: 'paperConditionEnum',
          name: 'paperConditionEnum',
          values: __type.enumValues
        })
      }
    }),
    paperEnums: () => ({
      query: GET_PAPER_ENUM,
      result ({ data: { paperEnums } }) {
        paperEnums.forEach((paperEnum) => {
          this.addEnum({
            id: _.replace(paperEnum.name, 'Enum', ''),
            name: paperEnum.name,
            values: paperEnum.values
          })
        })
      }
    }),
    paperGroup: () => ({
      query: GET_PAPERGROUP,
      fetchPolicy: 'no-cache',
      skip: true,
      variables () {
        return {
          id: this.paperBuilderId
        }
      },
      result ({ data: { paperGroup } }) {
        const currentDraft = paperGroup.papers.nodes.find((item) => item.active)
        if (currentDraft) {
          const { width, height } = this.paperGetSize.find(({ key }) => key === currentDraft.paperSize)
          this.addPaperBuilder({
            id: paperGroup.id,
            name: paperGroup.name,
            size: currentDraft.paperSize,
            condition: currentDraft.condition,
            width,
            height: height || 2000,
            innerWidth: currentDraft.width,
            innerHeight: currentDraft.height,
            canvas: currentDraft.canvas,
            papers: paperGroup.papers.nodes,
            draftId: currentDraft.id,
            savedCalculations: paperGroup.savedCalculations || []
          })
        } else {
          this.addPaperBuilder({
            id: paperGroup.id,
            name: paperGroup.name,
            size: 'A4',
            condition: 'all',
            innerWidth: 794,
            innerHeight: 1123,
            canvas: [],
            papers: [],
            draftId: null,
            savedCalculations: []
          })
        }
      },
      error () {
        this.$router.push({ name: 'Papers' })
      }
    })
  },
  data () {
    return {
      paperGetSize: [],
      skipQuery: true
    }
  },
  computed: {
    ...mapGetters({
      paperBuilderId: 'App/paperBuilderId',
      findPaperBuilder: 'PaperBuilder/find'
    }),
    paperBuilder () {
      return this.findPaperBuilder(this.paperBuilderId)
    },
    paperDraftId () {
      return this.paperBuilder?.draftId
    },
    name () {
      return this.paperBuilder?.name || ''
    },
    toolWidth () {
      const smAndDown = this.$vuetify.breakpoint.smAndDown ? 150 : 420
      const md = this.$vuetify.breakpoint.md ? 300 : smAndDown
      return md
    }
  },
  created () {
    this.setPaperBuilderId(this.$route.params.id)
  },
  methods: {
    ...mapActions({
      addEnum: 'Enum/add',
      addTableField: 'TableField/add',
      setPaperBuilderId: 'App/setPaperBuilderId',
      addPaperBuilder: 'PaperBuilder/add'
    })
  }
}
</script>

<style scoped>
  .table {
    width: 100%;
  }
  .table td,
  .table th {
    border: 1px solid #333;
  }
  .drag-handle,
  .drag-col-handle {
    cursor: move;
  }
  .paper-builder {
    position: absolute;
    width: 100%;
    background-color: #E5E5E5;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: auto;
  }
  .paper-builder--full-screen {
    position: fixed;
    z-index: 13;
    height: 100%;
  }
  @media (max-width: 1263px){
    .paper-builder {
      width: 100%;
      left: 0px;
    }
  }
  .my-grid {
    padding: 9px;
    background-color: #ffffff;
  }
</style>
