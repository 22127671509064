<template>
  <v-btn
    :loading="submitting"
    color="primary"
    rounded
    depressed
    @click="submit()">
    {{ $t('papers.builder.draft.select') }}
  </v-btn>
</template>

<script>
import { UPDATE_PAPER } from '@/resources/graphql'

export default {
  props: {
    itemId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      submitting: false
    }
  },
  methods: {
    async submit () {
      this.submitting = true
      await this.$apollo.mutate({
        mutation: UPDATE_PAPER,
        variables: {
          input: {
            id: this.itemId,
            active: true
          }
        }
      })
      this.submitting = false
      window.location.reload()
    }
  }
}
</script>

<style scoped>

</style>
