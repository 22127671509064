import _ from 'lodash'
import LineElement from '@/views/Papers/pages/Paper/components/Elements/LineElement.vue'
import InputElement from '@/views/Papers/pages/Paper/components/Elements/InputElement.vue'
import TextElement from '@/views/Papers/pages/Paper/components/Elements/TextElement.vue'
import ShapeElement from '@/views/Papers/pages/Paper/components/Elements/ShapeElement.vue'
import DecimalElement from '@/views/Papers/pages/Paper/components/Elements/DecimalElement.vue'
import IntegerElement from '@/views/Papers/pages/Paper/components/Elements/IntegerElement.vue'
import ImageElement from '@/views/Papers/pages/Paper/components/Elements/ImageElement.vue'
import BarcodeElement from '@/views/Papers/pages/Paper/components/Elements/BarcodeElement.vue'
import DateElement from '@/views/Papers/pages/Paper/components/Elements/DateElement.vue'
import FormulaElement from '@/views/Papers/pages/Paper/components/Elements/FormulaElement.vue'
import ProductTableElement from '@/views/Papers/pages/Paper/components/Elements/ProductTableElement.vue'
import NoneSetting from '@/views/Papers/pages/Paper/components/Settings/NoneSetting.vue'
import ImageSetting from '@/views/Papers/pages/Paper/components/Settings/ImageSetting.vue'
import TwoDSetting from '@/views/Papers/pages/Paper/components/Settings/2dSetting.vue'
import IntegerSetting from '@/views/Papers/pages/Paper/components/Settings/IntegerSetting.vue'
import TextSetting from '@/views/Papers/pages/Paper/components/Settings/TextSetting.vue'
import LineSetting from '@/views/Papers/pages/Paper/components/Settings/LineSetting.vue'
import DecimalSetting from '@/views/Papers/pages/Paper/components/Settings/DecimalSetting.vue'
import DateSetting from '@/views/Papers/pages/Paper/components/Settings/DateSetting.vue'
import AddressSetting from '@/views/Papers/pages/Paper/components/Settings/AddressSetting.vue'
import TelephoneSetting from '@/views/Papers/pages/Paper/components/Settings/TelephoneSetting.vue'
import TaxIdSetting from '@/views/Papers/pages/Paper/components/Settings/TaxIdSetting.vue'
import NumberSetting from '@/views/Papers/pages/Paper/components/Settings/NumberSetting.vue'
import TableSetting from '@/views/Papers/pages/Paper/components/Settings/TableSetting.vue'
import FormulaSetting from '@/views/Papers/pages/Paper/components/Settings/FormulaSetting.vue'
import ActionSetting from '@/views/Papers/pages/Paper/components/Settings/ActionSetting.vue'
import BarcodeSetting from '@/views/Papers/pages/Paper/components/Settings/BarcodeSetting.vue'

export default {
  components: {
    LineElement,
    InputElement,
    TextElement,
    ShapeElement,
    DecimalElement,
    IntegerElement,
    ImageElement,
    DateElement,
    FormulaElement,
    BarcodeElement,
    ProductTableElement,
    NoneSetting,
    ImageSetting,
    BarcodeSetting,
    TwoDSetting,
    IntegerSetting,
    TextSetting,
    LineSetting,
    DecimalSetting,
    DateSetting,
    AddressSetting,
    TelephoneSetting,
    TaxIdSetting,
    NumberSetting,
    TableSetting,
    FormulaSetting,
    ActionSetting
  },
  methods: {
    createTemplate (el) {
      const element = _.cloneDeep(el)
      const { data, fx, properties: { src, style } } = element
      const left = this.clearSuffixPixel(style.left)
      const top = this.clearSuffixPixel(style.top)
      const width = this.clearSuffixPixel(style.width)
      const height = this.clearSuffixPixel(style.height) || 'auto'
      const maxHeight = this.clearSuffixPixel(style.maxHeight)
      const transform = style.transform
      const backgroundColor = style.backgroundColor
      const backgroundSize = style.backgroundSize
      const borderRadius = style.borderRadius
      const borderColor = style.borderColor
      const borderWidth = style.borderWidth
      const borderTopWidth = style.borderTopWidth
      const borderLeftWidth = style.borderLeftWidth
      const borderRightWidth = style.borderRightWidth
      const borderBottomWidth = style.borderBottomWidth
      const borderStyle = style.borderStyle
      const whiteSpace = style.whiteSpace

      const text = _.result(element.children, '0', '')
      const fontSize = style.fontSize
      const color = style.color
      const textAlign = style.textAlign
      const fontWeight = style.fontWeight
      const fontStyle = style.fontStyle
      const textDecoration = style.textDecoration
      const overflow = style.overflow
      const backgroundImage = style.backgroundImage
      const headerFontSize = _.result(element, 'children.0.children.0.children.0.properties.style.fontSize', '')
      const headerFontWeight = _.result(element, 'children.0.children.0.children.0.properties.style.fontWeight', '')
      const headerFontStyle = _.result(element, 'children.0.children.0.children.0.properties.style.fontStyle', '')
      const headerDisplay = _.result(element, 'children.0.children.0.children.0.properties.style.display', 'table-row')
      const headerColor = _.result(element, 'children.0.children.0.children.0.properties.style.color', '')
      const contentFontSize = _.result(element, 'children.0.children.1.children.0.properties.style.fontSize', '')
      const contentFontWeight = _.result(element, 'children.0.children.1.children.0.properties.style.fontWeight', '')
      const contentFontStyle = _.result(element, 'children.0.children.1.children.0.properties.style.fontStyle', '')
      const contentColor = _.result(element, 'children.0.children.1.children.0.properties.style.color', '')
      const barcodeType = src
      // const purchaseFields = _.result(element, 'children.0.children.1.children.0.children', []).map((item) => item.data)
      const numberDisplayType = fx?.numberDisplayType?.value || null
      const digit = fx?.digit?.value || null
      const decimal = fx?.decimal?.value || null
      const dateFormat = fx?.dateFormat?.value || null
      const dateDisplayType = fx?.dateDisplayType?.value || null
      const timeDisplayType = fx?.timeDisplayType?.value || null
      const telDisplayType = fx?.telDisplayType?.value || null
      const taxDisplayType = fx?.taxDisplayType?.value || null
      const formula = data ? _.concat([{
        type: _.isFinite(_.toNumber(data)) ? 'number' : 'associate',
        value: _.isFinite(_.toNumber(data)) ? _.toNumber(data) : data
      }], _.map(_.omit(fx, ['numberDisplayType', 'digit', 'decimal']), (obj) => ([
        {
          type: 'operator',
          value: obj.name
        },
        {
          type: _.isFinite(_.toNumber(obj.value)) ? 'number' : 'associate',
          value: _.isFinite(_.toNumber(obj.value)) ? _.toNumber(obj.value) : obj.value
        }
      ]))).flat(1) : []
      let textAddress = '888/888 อัลคาเทลนครพนมราหุลโอเดียน อีซูซุลิเวอร์พูลโฮจิมินห์เกตส์ โนเบล กรุงเทพ 10000'
      switch (element.type) {
        case 'circle':
          return {
            id: element.id,
            type: 'circle',
            name: this.$t('papers.builder.templates.circle'),
            component: 'shape-element',
            setting: 'two-d-setting',
            fx: {},
            data: {},
            style: { left, top, width, height, transform, borderWidth, borderColor, borderStyle, backgroundColor, borderRadius }
          }
        case 'square':
          return {
            id: element.id,
            type: 'square',
            name: this.$t('papers.builder.templates.square'),
            component: 'shape-element',
            setting: 'two-d-setting',
            fx: {},
            data: {},
            style: { left, top, width, height, transform, borderWidth, borderColor, borderStyle, backgroundColor }
          }
        case 'line':
          return {
            id: element.id,
            type: 'line',
            name: this.$t('papers.builder.templates.line'),
            component: 'line-element',
            setting: 'line-setting',
            attrs: {
              handles: ['mr', 'ml', 'rot']
            },
            fx: {},
            data: {},
            style: {
              left,
              top,
              width,
              height,
              transform,
              maxHeight,
              borderColor,
              borderTopWidth,
              borderRightWidth,
              borderLeftWidth,
              borderBottomWidth,
              borderStyle
            }
          }
        case 'text':
          return {
            id: element.id,
            type: 'text',
            name: this.$t('papers.builder.templates.text'),
            component: 'input-element',
            setting: 'none-setting',
            attrs: {
              handles: ['mr', 'ml']
            },
            text,
            fx: {},
            data: {},
            style: { left, top, width, height, color, transform, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'barcode':
          return {
            id: element.id,
            type: 'barcode',
            name: this.$t('papers.builder.templates.barcode'),
            component: 'barcode-element',
            setting: 'barcode-setting',
            fx: {},
            attrs: {
              handles: ['tl', 'tm', 'tr', 'mr', 'br', 'bm', 'bl', 'ml', 'rot']
            },
            data: {
              barcodeType
            },
            style: { left, top, width, height, fontSize, transform, overflow }
          }
        case 'image':
          return {
            id: element.id,
            type: 'image',
            name: this.$t('papers.builder.templates.image'),
            component: 'image-element',
            setting: 'image-setting',
            attrs: {
              handles: ['tl', 'tm', 'tr', 'mr', 'br', 'bm', 'bl', 'ml', 'rot']
            },
            fx: {},
            data: {},
            style: { left, top, width, height, transform, backgroundImage, backgroundSize, overflow }
          }
        case 'numberItemInOrder':
          return {
            id: element.id,
            type: 'numberItemInOrder',
            name: this.$t('papers.builder.templates.numberItem'),
            component: 'text-element',
            setting: 'integer-setting',
            text: '2',
            fx: { numberDisplayType },
            data: {},
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'numberProductInOrder':
          return {
            id: element.id,
            type: 'numberProductInOrder',
            name: this.$t('papers.builder.templates.numberProduct'),
            component: 'text-element',
            setting: 'integer-setting',
            text: '10',
            fx: { numberDisplayType },
            data: {},
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'vat':
          return {
            id: element.id,
            type: 'vat',
            name: this.$t('papers.builder.templates.vat'),
            component: 'text-element',
            setting: 'decimal-setting',
            fx: { digit, decimal, numberDisplayType },
            text: '100.00',
            data: {},
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'amountreceive':
          return {
            id: element.id,
            type: 'amountreceive',
            name: this.$t('papers.builder.templates.amountReceived'),
            component: 'text-element',
            setting: 'decimal-setting',
            fx: { digit, decimal, numberDisplayType },
            text: '100.00',
            data: {},
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'amountofchange':
          return {
            id: element.id,
            type: 'amountofchange',
            name: this.$t('papers.builder.templates.amountChange'),
            component: 'text-element',
            setting: 'decimal-setting',
            fx: { digit, decimal, numberDisplayType },
            text: '100.00',
            data: {},
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'shippingcost':
          return {
            id: element.id,
            type: 'shippingcost',
            name: this.$t('papers.builder.templates.shippingCost'),
            component: 'text-element',
            setting: 'decimal-setting',
            fx: { digit, decimal, numberDisplayType },
            text: '100.00',
            data: {},
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'discount':
          return {
            id: element.id,
            type: 'discount',
            name: this.$t('papers.builder.templates.codeDiscount'),
            component: 'text-element',
            setting: 'none-setting',
            fx: { digit, decimal, numberDisplayType },
            text: 'XABCDE',
            data: {},
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'discountAtEnd':
          return {
            id: element.id,
            type: 'discountAtEnd',
            name: this.$t('papers.builder.templates.discountAtEnd'),
            component: 'text-element',
            setting: 'decimal-setting',
            fx: { digit, decimal, numberDisplayType },
            text: '100.00',
            data: {},
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'total':
          return {
            id: element.id,
            type: 'total',
            name: this.$t('papers.builder.templates.total'),
            component: 'text-element',
            setting: 'decimal-setting',
            fx: { digit, decimal, numberDisplayType },
            text: '100.00',
            data: {},
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'promocode':
          return {
            id: element.id,
            type: 'promocode',
            name: this.$t('papers.builder.templates.promocode'),
            component: 'text-element',
            setting: 'decimal-setting',
            fx: { digit, decimal, numberDisplayType },
            text: '100.00',
            data: {},
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'date':
          return {
            id: element.id,
            type: 'date',
            name: this.$t('papers.builder.templates.date'),
            component: 'text-element',
            setting: 'date-setting',
            fx: { dateFormat, dateDisplayType, timeDisplayType },
            text: 'วัน/เดือน/ปี',
            data: {
              dateField: data
            },
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'address':
          if (this.$_.endsWith(data, 'address_without_zipcode')) {
            textAddress = '888/888 อัลคาเทลนครพนมราหุลโอเดียน อีซูซุลิเวอร์พูลโฮจิมินห์เกตส์ โนเบล กรุงเทพ'
          } else if (this.$_.endsWith(data, 'zipcode')) {
            textAddress = '10000'
          }
          return {
            id: element.id,
            type: 'address',
            name: this.$t('papers.builder.templates.address'),
            component: 'text-element',
            setting: 'address-setting',
            fx: {},
            text: textAddress,
            data: {
              addressOwnerType: data
            },
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow, whiteSpace }
          }
        case 'telephone':
          return {
            id: element.id,
            type: 'telephone',
            name: this.$t('papers.builder.templates.telephone'),
            component: 'text-element',
            setting: 'telephone-setting',
            fx: { telDisplayType },
            text: '0XXXXXXXXX',
            data: {
              telOwnerType: data
            },
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'taxId':
          return {
            id: element.id,
            type: 'taxId',
            name: this.$t('papers.builder.templates.taxId'),
            component: 'text-element',
            setting: 'tax-id-setting',
            fx: { taxDisplayType },
            text: 'XXXXXXXXXXXXX',
            data: {
              taxOwnerType: data
            },
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'shippingNumber':
          return {
            id: element.id,
            type: 'shippingNumber',
            name: this.$t('papers.builder.templates.shippingNumber'),
            component: 'text-element',
            setting: 'none-setting',
            fx: {},
            text: 'XX-XXXXX-XXXXX',
            data: {},
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'parcelNumber':
          return {
            id: element.id,
            type: 'parcelNumber',
            name: this.$t('papers.builder.templates.parcelNumber'),
            component: 'text-element',
            setting: 'none-setting',
            fx: {},
            text: 'XX-XXXXX-XXXXX',
            data: {},
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'shippingParcelNumber':
          return {
            id: element.id,
            type: 'shippingParcelNumber',
            name: this.$t('papers.builder.templates.shippingParcelNumber'),
            component: 'text-element',
            setting: 'none-setting',
            fx: {},
            text: 'XX-XXXXX-XXXXX',
            data: {},
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'number':
          return {
            id: element.id,
            type: 'number',
            name: this.$t('papers.builder.templates.number'),
            component: 'text-element',
            setting: 'number-setting',
            fx: {},
            text: 'XX-XXXXX-XXXXX',
            data: {
              runningNumberType: data
            },
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'products':
          return {
            id: element.id,
            type: 'products',
            name: this.$t('papers.builder.templates.products'),
            component: 'product-table-element',
            setting: 'table-setting',
            fx: {},
            data: { data },
            style: {
              left,
              top,
              width,
              height,
              fontSize,
              overflow,
              headerFontSize,
              headerFontWeight,
              headerFontStyle,
              headerDisplay,
              headerColor,
              contentFontSize,
              contentFontWeight,
              contentFontStyle,
              contentColor
            }
          }
        case 'fx':
          return {
            id: element.id,
            type: 'fx',
            name: this.$t('papers.builder.templates.fx'),
            component: 'formula-element',
            setting: 'formula-setting',
            fx: { digit, decimal, numberDisplayType },
            // text: 'สมการ',
            data: {
              formula
            },
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'marketplaceId':
          return {
            id: element.id,
            type: 'marketplaceId',
            name: this.$t('papers.builder.templates.marketplaceId'),
            component: 'text-element',
            setting: 'none-setting',
            text: 'Marketplace id',
            fx: {},
            data: { },
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'marketplaceDiscount':
          return {
            id: element.id,
            type: 'marketplaceDiscount',
            name: this.$t('papers.builder.templates.marketplaceDiscount'),
            component: 'text-element',
            setting: 'decimal-setting',
            text: 'ส่วนลดจาก Marketplace',
            fx: { digit, decimal, numberDisplayType },
            data: { },
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'shipping':
          return {
            id: element.id,
            type: 'shipping',
            name: this.$t('papers.builder.templates.shipping'),
            component: 'text-element',
            setting: 'none-setting',
            text: 'EMS / J&T / อื่นๆ',
            fx: {},
            data: { },
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'bank':
          return {
            id: element.id,
            type: 'bank',
            name: this.$t('papers.builder.templates.bank'),
            component: 'text-element',
            setting: 'none-setting',
            text: 'กสิกร / กรุงเทพ / อื่นๆ',
            fx: {},
            data: { },
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'contact':
          return {
            id: element.id,
            type: 'contact',
            name: this.$t('papers.builder.templates.contact'),
            component: 'text-element',
            setting: 'none-setting',
            text: 'Contact',
            fx: {},
            data: { },
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'remark':
          return {
            id: element.id,
            type: 'remark',
            name: this.$t('papers.builder.templates.remark'),
            component: 'text-element',
            setting: 'none-setting',
            text: 'ระวังแตก หรือ อินพุทรีเลย์ โกลบอลพาร์ทิชันเอาท์พุทลีนุกซ์กริด ฮาร์ดดิสก์อูบันตูเอนจิน',
            fx: {},
            data: { },
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'social':
          return {
            id: element.id,
            type: 'social',
            name: this.$t('papers.builder.templates.social'),
            component: 'text-element',
            setting: 'none-setting',
            text: 'Fanpage',
            fx: {},
            data: { },
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'tag':
          return {
            id: element.id,
            type: 'tag',
            name: this.$t('papers.builder.templates.tags'),
            component: 'text-element',
            setting: 'none-setting',
            text: 'Tag,Tag,Tag',
            fx: {},
            data: { },
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'orderCreator':
          return {
            id: element.id,
            type: 'orderCreator',
            name: this.$t('papers.builder.templates.orderCreator'),
            component: 'text-element',
            setting: 'none-setting',
            text: 'สมชาย ใจดีมาก',
            fx: {},
            data: { },
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'publisher':
          return {
            id: element.id,
            type: 'publisher',
            name: this.$t('papers.builder.templates.publisher'),
            component: 'text-element',
            setting: 'none-setting',
            text: 'สมชาย ใจดีมาก',
            fx: {},
            data: { },
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'recipient':
          return {
            id: element.id,
            type: 'recipient',
            name: this.$t('papers.builder.templates.recipient'),
            component: 'text-element',
            setting: 'none-setting',
            text: 'สมหญิง ใจดีน้อย',
            fx: {},
            data: { },
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'sender':
          return {
            id: element.id,
            type: 'sender',
            name: this.$t('papers.builder.templates.sender'),
            component: 'text-element',
            setting: 'none-setting',
            text: 'สมชาย ใจดีมาก',
            fx: {},
            data: { },
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'stockName':
          return {
            id: element.id,
            type: 'stockName',
            name: this.$t('papers.builder.templates.stockName'),
            component: 'text-element',
            setting: 'none-setting',
            text: 'Main',
            fx: {},
            data: { },
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'stockAddress':
          return {
            id: element.id,
            type: 'stockAddress',
            name: this.$t('papers.builder.templates.stockAddress'),
            component: 'text-element',
            setting: 'none-setting',
            text: 'XX/XXX',
            fx: {},
            data: { },
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'stockPrefix':
          return {
            id: element.id,
            type: 'stockPrefix',
            name: this.$t('papers.builder.templates.stockPrefix'),
            component: 'text-element',
            setting: 'none-setting',
            text: 'XX',
            fx: {},
            data: { },
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        case 'unit':
          return {
            id: element.id,
            type: 'unit',
            name: this.$t('papers.builder.templates.unit'),
            component: 'text-element',
            setting: 'none-setting',
            text: 'หน่วย',
            fx: {},
            data: { },
            style: { left, top, width, height, transform, color, fontSize, fontWeight, fontStyle, textDecoration, textAlign, overflow }
          }
        default:
          return {
            id: element.id,
            type: 'undefined',
            fx: {},
            data: {},
            style: { left, top, width, height, transform }
          }
      }
    },
    clearSuffixPixel (str) {
      return _.toNumber(
        _.replace(str, 'px', '')
      )
    }
  }
}
