<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="150"
    offset-x>
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        class="mx-1"
        v-bind="attrs"
        text
        v-on="on">
        <v-row
          align="center"
          class="flex-column"
          justify="center">
          <v-icon class="cols 12">
            mdi-format-color-text
          </v-icon>
          <v-sheet
            tile
            style="margin-top: -4px;"
            height="4"
            width="26"
            :color="value"
          />
        </v-row>
      </v-btn>
    </template>
    <v-color-picker
      :value="value"
      :dot-size="25"
      mode="hexa"
      @input="debounceOnChange($event)">
    </v-color-picker>
  </v-menu>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    value: {
      type: String,
      default: '#ffffff'
    }
  },
  data () {
    return {
      menu: false
    }
  },
  created () {
    this.debounceOnChange = _.debounce(this.onChange, 300)
  },
  methods: {
    onChange (event) {
      this.$emit('input', event)
    }
  }
}
</script>
<style>

</style>
