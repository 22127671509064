<template>
  <v-dialog
    v-model="dialog"
    max-width="750px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        color="primary"
        x-small
        icon
        v-on="on">
        <v-icon
          size="17">
          $paper_cog
        </v-icon>
      </v-btn>
    </template>
    <fx
      :id="id"
      :title="title"
      :number-display-type="numberDisplayType"
      :digit="digit"
      :decimal="decimal"
      :formula="formula"
      @submit="setDialog(false)"
      @close="setDialog(false)" />
  </v-dialog>
</template>
<script>
import Fx from '../Calculator/Fx.vue'

export default {
  components: { Fx },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    title: {
      type: String,
      default: 'None'
    },
    digit: {
      type: Number,
      default: 4
    },
    decimal: {
      type: Number,
      default: 4
    },
    numberDisplayType: {
      type: String,
      default: ''
    },
    formula: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    setDialog (dialog = false) {
      this.dialog = dialog
    }
  }
}
</script>
<style scoped>
  .display-type--container {
    width: 240px;
  }
  .example-formula--wrapper {
    background-color: #ffffff;
    width: 300px;
    border:1px solid #ddd;
    padding: 16px;
  }
  .example-formula--text {
    font-size: 12px;
  }
</style>
