var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "min-width": "100",
        transition: "slide-x-transition",
        "offset-x": ""
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { attrs: { "x-small": "", icon: "" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(" mdi-dots-horizontal ")
                  ])
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.menu,
        callback: function($$v) {
          _vm.menu = $$v
        },
        expression: "menu"
      }
    },
    [
      _c(
        "v-list",
        { staticClass: "py-0", attrs: { dense: "" } },
        [
          _c(
            "v-list-item",
            {
              staticClass: "px-3",
              attrs: { ripple: false, link: "" },
              on: {
                click: function($event) {
                  return _vm.duplicate()
                }
              }
            },
            [
              _c(
                "v-list-item-subtitle",
                [
                  _c("v-icon", { staticClass: "mr-3", attrs: { size: "16" } }, [
                    _vm._v(" $paper_copy ")
                  ]),
                  _c("span", [
                    _vm._v(" " + _vm._s(_vm.$t("papers.btn.duplicate")))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              staticClass: "px-3",
              attrs: { ripple: false, link: "" },
              on: {
                click: function($event) {
                  return _vm.overlap()
                }
              }
            },
            [
              _c(
                "v-list-item-subtitle",
                [
                  _c("v-icon", { staticClass: "mr-3", attrs: { size: "16" } }, [
                    _vm._v(" mdi-circle-multiple ")
                  ]),
                  _c("span", [
                    _vm._v(" " + _vm._s(_vm.$t("papers.btn.overlap")) + " ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              staticClass: "px-3",
              attrs: { ripple: false, link: "" },
              on: {
                click: function($event) {
                  return _vm.remove()
                }
              }
            },
            [
              _c(
                "v-list-item-subtitle",
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-3",
                      attrs: { size: "16", color: "error" }
                    },
                    [_vm._v(" $paper_trash_outline ")]
                  ),
                  _c("span", { staticClass: "error--text" }, [
                    _vm._v(" " + _vm._s(_vm.$t("papers.btn.remove")))
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }