<template>
  <v-menu
    min-width="200"
    origin="center center"
    transition="slide-x-transition"
    offset-x>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        :ripple="false"
        x-small
        icon
        depressed
        v-on="on">
        <v-icon>
          mdi-dots-vertical
        </v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item
        link
        @click="clickRename()">
        <v-list-item-subtitle>
          <v-icon
            size="16"
            class="mr-3">
            $paper_edit_outline
          </v-icon>
          <span> {{ $t('papers.btn.rename') }}</span>
        </v-list-item-subtitle>
      </v-list-item>
      <draft-remove-button
        v-if="!itemActive"
        :item-id="itemId"
        @submitted="afterRemoved($event)" />
    </v-list>
  </v-menu>
</template>

<script>
import DraftRemoveButton from './DraftRemoveButton.vue'

export default {
  components: {
    DraftRemoveButton
  },
  props: {
    itemId: {
      type: [String, Number],
      required: true
    },
    itemActive: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
    }
  },
  methods: {
    clickRename () {
      this.$emit('click:rename')
    },
    afterRemoved () {
      this.$emit('after:removed')
    }
  }
}
</script>
