var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-100" }, [
    _c(
      "div",
      {
        ref: "canvassimu",
        staticClass: "canvas-simu-scroll",
        on: {
          scroll: function($event) {
            return _vm.canvasSimuScroll()
          }
        }
      },
      [
        _c("div", {
          staticClass: "paper-simu-scroll d-inline-block",
          style: {
            width: _vm.width + "px"
          }
        })
      ]
    ),
    _c(
      "div",
      {
        ref: "canvas",
        staticClass: "canvas",
        attrs: { id: "my-canvas" },
        on: {
          click: function($event) {
            return _vm.onClickOutside($event)
          },
          scroll: function($event) {
            return _vm.canvasScroll()
          }
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "paper d-inline-block",
            style: {
              height: _vm.height + "px",
              width: _vm.width + "px"
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "relative",
                attrs: { id: "paper" },
                on: {
                  drop: function($event) {
                    return _vm.onDrop($event)
                  },
                  dragenter: function($event) {
                    $event.preventDefault()
                  },
                  dragover: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "vue-draggable-resizable",
                  {
                    staticClass: "canvas-resizable",
                    attrs: {
                      id: "paper-resizable",
                      w: _vm.innerWidth,
                      h: _vm.innerHeight,
                      "max-width": _vm.maxWidth,
                      "max-height": _vm.maxHeight,
                      grid: [
                        _vm.width * _vm.gridCanvasStep,
                        _vm.height * _vm.gridCanvasStep
                      ],
                      handles: ["mr", "bm"],
                      draggable: false,
                      active: true,
                      "enable-native-drag": true,
                      "prevent-deactivation": true,
                      "class-name-handle": "my-handle-class"
                    },
                    on: {
                      resizestop: function(x, y, width, height) {
                        return _vm.onInnerResize(width, height)
                      }
                    }
                  },
                  _vm._l(_vm.elements, function(el) {
                    return _c(
                      "div",
                      {
                        key: "el-" + el.id,
                        on: {
                          click: function($event) {
                            return _vm.onActivated(el.id)
                          },
                          drop: function($event) {
                            $event.preventDefault()
                          }
                        }
                      },
                      [
                        _c(
                          "vue-draggable-resizable",
                          _vm._b(
                            {
                              attrs: {
                                resizable: !_vm.isMultiActivated,
                                "enable-native-drag": true,
                                x: el.style.left,
                                y: el.style.top,
                                w: el.style.width,
                                h: el.style.height,
                                rotate: _vm.getRotate(el.style.transform),
                                "max-width": el.style.maxWidth,
                                "max-height": el.style.maxHeight,
                                grid: [_vm.gridWidth, _vm.gridHeight],
                                active: _vm.isActivated(el.id),
                                "prevent-deactivation": true,
                                "class-name": "el-draggable",
                                "class-name-handle": "el-handle-class",
                                "drag-handle": ".drag-handle"
                              },
                              on: {
                                dragstop: function(x, y) {
                                  return _vm.onDrag(el.id, x, y)
                                },
                                resizestop: function(x, y, width, height) {
                                  return _vm.onResize(
                                    el.id,
                                    x,
                                    y,
                                    width,
                                    height
                                  )
                                },
                                rotate: function($event) {
                                  return _vm.onRotate(el.id, $event)
                                },
                                deactivated: function($event) {
                                  return _vm.onDeactivated(el.id)
                                }
                              }
                            },
                            "vue-draggable-resizable",
                            el.attrs,
                            false
                          ),
                          [
                            _vm.isActivated(el.id) && !_vm.isMultiActivated
                              ? _c(
                                  "div",
                                  {
                                    class:
                                      "drag-handle-wrap " + el.type + "--wrap",
                                    on: {
                                      click: function($event) {
                                        return _vm.onDeactivated(
                                          _vm.activatedId
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "drag-handle" },
                                      [
                                        _c("v-icon", { attrs: { dark: "" } }, [
                                          _vm._v(" mdi-arrow-all ")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              el.component,
                              _vm._b(
                                {
                                  tag: "component",
                                  attrs: {
                                    id: el.id,
                                    text: el.text,
                                    active:
                                      _vm.isActivated(el.id) &&
                                      !_vm.isMultiActivated,
                                    formula: el.data.formula,
                                    "address-owner-type":
                                      el.data.addressOwnerType,
                                    "purchase-fields": el.data.purchaseFields,
                                    "tel-owner-type": el.data.telOwnerType,
                                    "number-display-type":
                                      el.fx.numberDisplayType,
                                    digit: el.fx.digit,
                                    decimal: el.fx.decimal,
                                    "date-field": el.data.dateField,
                                    "date-format": el.fx.dateFormat,
                                    "date-display-type": el.fx.dateDisplayType,
                                    "time-display-type": el.fx.timeDisplayType,
                                    "tel-display-type": el.fx.telDisplayType,
                                    "tax-owner-type": el.data.taxOwnerType,
                                    "tax-display-type": el.fx.taxDisplayType,
                                    "running-number-type":
                                      el.data.runningNumberType
                                  }
                                },
                                "component",
                                el.style,
                                false
                              )
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.isActivated(el.id) &&
                                      !_vm.isMultiActivated,
                                    expression:
                                      "isActivated(el.id) && !isMultiActivated"
                                  }
                                ]
                              },
                              [
                                _c(
                                  "div",
                                  { class: "tool-wrap " + el.type + "--wrap" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "setting-cog--wrap" },
                                      [
                                        _c(
                                          el.setting,
                                          _vm._b(
                                            {
                                              tag: "component",
                                              attrs: {
                                                id: el.id,
                                                title: el.name,
                                                text: el.text,
                                                formula: el.data.formula,
                                                "address-owner-type":
                                                  el.data.addressOwnerType,
                                                "purchase-fields":
                                                  el.data.purchaseFields,
                                                "tel-owner-type":
                                                  el.data.telOwnerType,
                                                "number-display-type":
                                                  el.fx.numberDisplayType,
                                                digit: el.fx.digit,
                                                decimal: el.fx.decimal,
                                                "date-field": el.data.dateField,
                                                "date-format": el.fx.dateFormat,
                                                "date-display-type":
                                                  el.fx.dateDisplayType,
                                                "time-display-type":
                                                  el.fx.timeDisplayType,
                                                "tel-display-type":
                                                  el.fx.telDisplayType,
                                                "tax-owner-type":
                                                  el.data.taxOwnerType,
                                                "tax-display-type":
                                                  el.fx.taxDisplayType,
                                                "running-number-type":
                                                  el.data.runningNumberType,
                                                "barcode-type":
                                                  el.data.barcodeType
                                              }
                                            },
                                            "component",
                                            el.style,
                                            false
                                          )
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "setting-action--wrap d-flex align-center justify-center"
                                      },
                                      [
                                        _c("action-setting", {
                                          attrs: { id: el.id },
                                          on: {
                                            "after:duplicate": function(
                                              $event
                                            ) {
                                              return _vm.onActivated($event)
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  0
                )
              ],
              1
            ),
            _c("div", { staticClass: "example-container" }, [
              _c(
                "div",
                {
                  staticClass:
                    "example-placeholder d-flex flex-column align-center justify-center flex-grow-1"
                },
                [
                  _c("v-icon", { attrs: { color: "#B6B6B6", size: "65" } }, [
                    _vm._v(" mdi-eye ")
                  ]),
                  _c("div", { staticClass: "disabled--text" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("papers.builder.example.placeholder")) +
                        " "
                    )
                  ])
                ],
                1
              )
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }