var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "420px", scrollable: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { attrs: { color: "primary", "x-small": "", icon: "" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { attrs: { size: "17" } }, [
                    _vm._v(" $paper_cog ")
                  ])
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "px-4 pb-1" },
            [
              _c("title-label", { attrs: { label: _vm.title } }),
              _c("v-spacer"),
              _c(
                "v-icon",
                {
                  staticClass: "dialog-close-tr",
                  attrs: { color: "#C8C8C8", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.setDialog(false)
                    }
                  }
                },
                [_vm._v(" mdi-close ")]
              )
            ],
            1
          ),
          _c("v-card-text", { staticClass: "px-4" }, [
            _c("div", { staticClass: "setting-subtitle mt-2 mb-2" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("papers.builder.settings.barcode.of")) + " "
              )
            ]),
            _c(
              "div",
              { staticClass: "number--container mb-4" },
              [
                _c("v-select", {
                  staticClass: "app-style",
                  attrs: {
                    value: _vm.barcodeType,
                    items: _vm.paperBarcodeEnum,
                    "item-value": "name",
                    "item-text": "description",
                    outlined: "",
                    dense: ""
                  },
                  on: {
                    input: function($event) {
                      return _vm.setElement("properties.src", $event)
                    }
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }