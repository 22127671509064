<template>
  <div
    :style="{
      width: '100%',
      height: '100%',
      borderRadius,
      backgroundColor,
      borderStyle,
      borderTopWidth,
      borderLeftWidth,
      borderRightWidth,
      borderBottomWidth,
      paddingTop,
      borderColor
    }">
  </div>
</template>

<script>
import paperElementMixin from '@/mixins/paperElementMixin'

export default {
  mixins: [paperElementMixin],
  props: {
    borderRadius: {
      type: String,
      default: undefined
    },
    borderStyle: {
      type: String,
      default: undefined
    },
    borderTopWidth: {
      type: [Number, String],
      default: 0
    },
    borderLeftWidth: {
      type: [Number, String],
      default: 0
    },
    borderRightWidth: {
      type: [Number, String],
      default: 0
    },
    borderBottomWidth: {
      type: [Number, String],
      default: 0
    },
    borderColor: {
      type: String,
      default: undefined
    },
    backgroundColor: {
      type: String,
      default: undefined
    },
    paddingTop: {
      type: String,
      default: '0'
    }
  }
}
</script>
<style scoped>
</style>
