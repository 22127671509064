var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      attrs: {
        loading: _vm.submitting,
        disabled: !_vm.dirty,
        color: "primary",
        outlined: ""
      },
      on: {
        click: function($event) {
          return _vm.submit()
        }
      }
    },
    [
      _c("v-icon", { attrs: { size: "16", outlined: "", left: "" } }, [
        _vm._v(" $paper_save_outline ")
      ]),
      _vm._v(" " + _vm._s(_vm.$t("papers.builder.save")) + " ")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }