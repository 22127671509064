<template>
  <v-dialog
    v-model="dialog"
    max-width="400px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="dirty"
        color="error"
        text
        v-bind="attrs"
        v-on="on">
        {{ $t('papers.builder.discard') }}
      </v-btn>
      <v-btn
        v-else
        color="error"
        text
        @click="submit()">
        {{ $t('papers.builder.discard') }}
      </v-btn>
    </template>
    <v-card>
      <div class="d-flex pa-2">
        <v-spacer />
        <v-btn
          icon
          small
          @click="setDialog(false)">
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <div class="text-center mb-1">
        <v-icon
          :size="56"
          color="primary">
          $paper_logout
        </v-icon>
      </div>
      <v-card-title class="subtitle-1 justify-center">
        {{ $t('papers.discard.title') }}
      </v-card-title>
      <v-card-text class="text-center text--disabled">
        <div class="mx-10">
          {{ $t('papers.discard.description') }}
        </div>
      </v-card-text>
      <v-card-actions class="py-4 bg--disabled border-top--color">
        <v-spacer />
        <v-btn
          color="primary"
          class="px-3"
          depressed
          rounded
          @click="setDialog(false)">
          {{ $t('app.btn.cancel') }}
        </v-btn>
        <v-btn
          color="error"
          rounded
          text
          @click="submit()">
          {{ $t('papers.discard.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapGetters({
      paperBuilderId: 'App/paperBuilderId',
      findPaperBuilder: 'PaperBuilder/find'
    }),
    paperBuilder () {
      return this.findPaperBuilder(this.paperBuilderId)
    },
    dirty () {
      return this.paperBuilder?.dirty || false
    }
  },
  methods: {
    setDialog (dialog = false) {
      this.dialog = dialog
    },
    submit () {
      this.$router.push({ name: 'Papers' })
    }
  }
}
</script>

<style scoped>
  .bg--disabled {
    background: #FBFBFB;
  }
  .border-top--color {
    border-top: 1px solid #EEEEEE;
  }
</style>
