var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pa-3" }, [
    _c(
      "div",
      { staticClass: "pa-2" },
      [
        _c("v-text-field", {
          staticClass: "search-style",
          attrs: {
            placeholder: _vm.$t("app.form.search.placeholder"),
            autocomplete: "off",
            "prepend-inner-icon": "mdi-magnify",
            "hide-details": "",
            outlined: "",
            dense: ""
          },
          model: {
            value: _vm.searchText,
            callback: function($$v) {
              _vm.searchText = $$v
            },
            expression: "searchText"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "pa-2 toolbox" },
      [
        _c(
          "v-row",
          { attrs: { dense: "" } },
          _vm._l(_vm.items, function(item) {
            return _c(
              "v-col",
              {
                key: "toolbox-item-" + item.name,
                attrs: { cols: "12", md: "6", lg: "4" }
              },
              [
                _c(item.component, {
                  tag: "component",
                  attrs: {
                    name: _vm.$t("papers.builder.templates." + item.name),
                    icon: item.icon
                  }
                })
              ],
              1
            )
          }),
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }