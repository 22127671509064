export default {
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    },
    maxWidth: {
      type: [Number, String],
      default: null
    },
    maxHeight: {
      type: [Number, String],
      default: null
    },
    transform: {
      type: String,
      default: null
    },
    data: {
      type: String,
      default: null
    }
  }
}
