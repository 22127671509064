<template>
  <v-dialog
    v-model="dialog"
    max-width="420px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        x-small
        icon
        v-bind="attrs"
        v-on="on">
        <v-icon small>
          mdi-plus-circle
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="px-4">
        <title-label :label="$t('papers.builder.settings.products.create')" />
        <v-spacer />
        <v-icon
          small
          @click="setDialog(false)">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="px-4 pb-0">
        <v-form
          ref="form"
          v-model="valid"
          :readonly="submitting"
          @submit.prevent="submit()">
          <text-field-label :label="$t('papers.builder.settings.products.name')" />
          <div>
            <v-text-field
              v-model="name"
              :rules="rule.name"
              class="app-style"
              autocomplete="off"
              autofocus
              outlined
              dense />
          </div>
          <text-field-label :label="$t('papers.builder.settings.products.formula')" />
          <div>
            <editor
              :value="series"
              :variables="variables"
              :error="errorType"
              class="mb-3"
              @input="setSeries($event)" />
            <numpad
              :value="calculator.points"
              :variables="variables"
              @input="setPoints($event)" />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer />
        <v-btn
          class="px-4"
          color="primary"
          rounded
          text
          @click="setDialog(false)">
          {{ $t('app.btn.cancel') }}
        </v-btn>
        <v-btn
          :loading="submitting"
          :disabled="!valid || !!errorType"
          class="px-4"
          color="primary"
          rounded
          depressed
          @click="submit()">
          {{ $t('app.btn.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import CalculatorParser from '@/assets/js/calculator-parser'
import { UPDATE_PAPERGROUP } from '@/resources/graphql'
import { mapActions, mapGetters } from 'vuex'
import Numpad from '../../Calculator/Numpad.vue'
import Editor from '../../Calculator/Editor.vue'

export default {
  components: { Numpad, Editor },
  data () {
    return {
      dialog: false,
      submitting: false,
      valid: true,
      name: '',
      calculator: new CalculatorParser([]),
      rule: {
        name: [
          (v) => !!v || this.$t('app.validate.required')
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      paperBuilderId: 'App/paperBuilderId',
      findPaperBuilder: 'PaperBuilder/find',
      findTableField: 'TableField/find'
    }),
    paperBuilder () {
      return this.findPaperBuilder(this.paperBuilderId)
    },
    paperFieldOrder () {
      return this.findTableField('paperFieldOrder')?.values || []
    },
    paperFieldPurchase () {
      return this.findTableField('paperFieldPurchase')?.values || []
    },
    paperFieldSetOrder () {
      return this.findTableField('paperFieldSetOrder')?.values || []
    },
    paperFieldShop () {
      return this.findTableField('paperFieldShop')?.values || []
    },
    paperFieldStock () {
      return this.findTableField('paperFieldStock')?.values || []
    },
    paperFieldReseller () {
      return this.findTableField('paperFieldReseller')?.values || []
    },
    paperFieldPromoCode () {
      return this.findTableField('paperFieldPromoCode')?.values || []
    },
    variables () {
      const order = this.paperFieldOrder.map((props) => ({
        ...props,
        id: `order.${props.field}`,
        tableName: 'order',
        tableDescription: 'ออเดอร์'
      }))
      const purchase = this.paperFieldPurchase.map((props) => ({
        ...props,
        id: `purchase.${props.field}`,
        tableName: 'purchase',
        tableDescription: 'การซื้อ'
      }))
      const setOrder = this.paperFieldSetOrder.map((props) => ({
        ...props,
        id: `set_order.${props.field}`,
        tableName: 'set_order',
        tableDescription: 'เซตออเดอร์'
      }))
      const shop = this.paperFieldShop.map((props) => ({
        ...props,
        id: `shop.${props.field}`,
        tableName: 'shop',
        tableDescription: 'ร้านค้า'
      }))
      const stock = this.paperFieldStock.map((props) => ({
        ...props,
        id: `order.${props.field}`,
        tableName: 'stock',
        tableDescription: 'คลัง'
      }))
      const reseller = this.paperFieldReseller.map((props) => ({
        ...props,
        id: `reseller.${props.field}`,
        tableName: 'reseller',
        tableDescription: 'ตัวแทน'
      }))
      const promoCode = this.paperFieldPromoCode.map((props) => ({
        ...props,
        id: `promo_code.${props.field}`,
        tableName: 'promo_code',
        tableDescription: 'ส่วนลด'
      }))
      const variables = _.concat(order, purchase, setOrder, shop, stock, reseller, promoCode)
      const variableNumberOnly = variables.filter(({ type }) => _.includes(['number', 'float', 'double', 'integer', 'decimal'], type))
      return variableNumberOnly
    },
    series () {
      return this.calculator.toSeries()
    },
    errorType () {
      let errorType = null
      if (this.series.length) {
        if (['operator'].includes(_.last(this.series).type)) {
          errorType = 1
        }
      } else {
        errorType = 1
      }
      return errorType
    }
  },
  methods: {
    ...mapActions({
      updatePaperBuilder: 'PaperBuilder/update'
    }),
    async submit () {
      if (!this.valid || this.errorType) return
      this.submitting = true
      const paper = this.findPaperBuilder(this.paperBuilderId)
      const savedCalculations = [
        ...paper.savedCalculations,
        {
          id: uuid(),
          name: this.name,
          value: this.calculator.toJSON()
        }
      ]
      await this.$apollo.mutate({
        mutation: UPDATE_PAPERGROUP,
        variables: {
          input: {
            id: this.paperBuilderId,
            savedCalculations
          }
        }
      })
      this.submitting = false
      this.updatePaperBuilder({
        id: this.paperBuilderId,
        savedCalculations
      })
      this.setDialog(false)
    },
    setSeries (serie) {
      if (serie) {
        const { id, value } = serie
        const points = _.cloneDeep(this.calculator.points)
        points[id].value = value
        this.setPoints(points)
      } else {
        this.setPoints([])
      }
    },
    setPoints (points) {
      this.calculator.points = points
    },
    setDialog (dialog = false) {
      this.dialog = dialog
    }
  }

}
</script>

<style scoped>

</style>
