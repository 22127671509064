<template>
  <text-editor-inline
    v-if="formula.length"
    :value="active"
    :font-size="fontSize"
    :text-align="textAlign"
    :font-weight="fontWeight"
    :font-style="fontStyle"
    :text-decoration="textDecoration"
    :color="color"
    @input="(key, value) => setElement(key, value)">
    <div
      :style="{
        width: '100%',
        height: '100%',
        overflow,
        fontSize,
        textAlign,
        fontWeight,
        fontStyle,
        textDecoration,
        color,
      }">
      ข้อมูลคำนวณเอง
    </div>
  </text-editor-inline>
  <div
    v-else
    class="element-formula d-flex flex-column align-center justify-center">
    <v-icon>$paper_formula</v-icon>
    <v-dialog
      v-model="dialog"
      max-width="750px"
      scrollable>
      <template v-slot:activator="{ on, attrs }">
        <div
          class="formula-label secondary--text"
          v-bind="attrs"
          v-on="on">
          {{ $t(`papers.builder.elements.fx.placeholder`) }}
        </div>
      </template>
      <fx
        :id="id"
        ref="fxElement"
        :title="title"
        :number-display-type="numberDisplayType"
        :formula="formula"
        @submit="setDialog(false)"
        @close="setDialog(false)" />
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import paperElementMixin from '@/mixins/paperElementMixin'
import paperSettingMixin from '@/mixins/paperSettingMixin'
import CalculatorParser from '@/assets/js/calculator-parser'
import Fx from '../Calculator/Fx.vue'
import TextEditorInline from '../Widget/TextEditorInline.vue'

export default {
  components: {
    Fx,
    TextEditorInline
  },
  mixins: [paperElementMixin, paperSettingMixin],
  props: {
    active: {
      type: Boolean,
      default: false
    },
    fontSize: {
      type: String,
      default: '14px'
    },
    textAlign: {
      type: String,
      default: 'left'
    },
    fontWeight: {
      type: String,
      default: 'normal'
    },
    fontStyle: {
      type: String,
      default: 'normal'
    },
    textDecoration: {
      type: String,
      default: 'none'
    },
    color: {
      type: String,
      default: '#000000'
    },
    overflow: {
      type: String,
      default: 'initial'
    },
    title: {
      type: String,
      default: 'None'
    },
    numberDisplayType: {
      type: String,
      default: ''
    },
    formula: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      dialog: false,
      calculator: new CalculatorParser(this.formula)
    }
  },
  computed: {
    ...mapGetters({
      findTableField: 'TableField/find'
    }),
    paperFieldOrder () {
      return this.findTableField('paperFieldOrder')?.values || []
    },
    paperFieldPurchase () {
      return this.findTableField('paperFieldPurchase')?.values || []
    },
    paperFieldSetOrder () {
      return this.findTableField('paperFieldSetOrder')?.values || []
    },
    paperFieldShop () {
      return this.findTableField('paperFieldShop')?.values || []
    },
    paperFieldStock () {
      return this.findTableField('paperFieldStock')?.values || []
    },
    paperFieldReseller () {
      return this.findTableField('paperFieldReseller')?.values || []
    },
    paperFieldPromoCode () {
      return this.findTableField('paperFieldPromoCode')?.values || []
    },
    variables () {
      const order = this.paperFieldOrder.map((props) => ({
        ...props,
        id: `order.${props.field}`,
        tableName: 'order',
        tableDescription: 'ออเดอร์'
      }))
      const purchase = this.paperFieldPurchase.map((props) => ({
        ...props,
        id: `purchase.${props.field}`,
        tableName: 'purchase',
        tableDescription: 'การซื้อ'
      }))
      const setOrder = this.paperFieldSetOrder.map((props) => ({
        ...props,
        id: `set_order.${props.field}`,
        tableName: 'set_order',
        tableDescription: 'เซตออเดอร์'
      }))
      const shop = this.paperFieldShop.map((props) => ({
        ...props,
        id: `shop.${props.field}`,
        tableName: 'shop',
        tableDescription: 'ร้านค้า'
      }))
      const stock = this.paperFieldStock.map((props) => ({
        ...props,
        id: `order.${props.field}`,
        tableName: 'stock',
        tableDescription: 'คลัง'
      }))
      const reseller = this.paperFieldReseller.map((props) => ({
        ...props,
        id: `reseller.${props.field}`,
        tableName: 'reseller',
        tableDescription: 'ตัวแทน'
      }))
      const promoCode = this.paperFieldPromoCode.map((props) => ({
        ...props,
        id: `promo_code.${props.field}`,
        tableName: 'promo_code',
        tableDescription: 'ส่วนลด'
      }))
      const variables = _.concat(order, purchase, setOrder, shop, stock, reseller, promoCode)
      const variableNumberOnly = variables.filter(({ type }) => _.includes(['number', 'float', 'double', 'integer', 'decimal'], type))
      return variableNumberOnly
    },
    series () {
      return this.calculator.toSeries()
    }
  },
  watch: {
    formula (value) {
      this.calculator = new CalculatorParser(value)
    }
  },
  methods: {
    setDialog (dialog = false) {
      this.dialog = dialog
    }
  }
}
</script>
<style scoped>
.element-formula {
  height: 100%;
  width: 100%;
  background: #C4C4C4;
}
.formula-label {
  margin: 6px 0 0;
  cursor: pointer;
  font-size: 12px;
}
</style>
