<template>
  <v-card color="#FBFBFB">
    <v-card-title class="px-4 pb-3">
      <title-label
        class="mr-4"
        :label="$t('papers.builder.settings.fx.display')" />
      <div class="display-type--container">
        <v-select
          :value="numberDisplayType"
          :items="paperNumberDisplayEnum"
          class="app-style"
          item-value="name"
          item-text="description"
          background-color="#ffffff"
          hide-details
          outlined
          dense
          @input="setElement('fx.numberDisplayType', {
            value: $event
          })" />
      </div>
      <v-spacer />
      <v-icon
        class="dialog-close-tr"
        color="#C8C8C8"
        small
        @click="close()">
        mdi-close
      </v-icon>
    </v-card-title>
    <v-card-text class="px-4">
      <div class="d-flex align-center mb-2">
        <title-label
          :label="$t('papers.builder.settings.decimal.digits')"
          class="mr-8" />
        <div class="display-type--container mr-6">
          <v-select
            :value="digit"
            :items="digits"
            class="app-style"
            background-color="#ffffff"
            hide-details
            outlined
            dense
            @input="setElement('fx.digit', {
              value: $event
            })" />
        </div>
        <title-label
          :label="$t('papers.builder.settings.decimal.decimal')"
          class="mr-3" />
        <div class="display-type--container">
          <v-select
            :value="decimal"
            :items="decimals"
            class="app-style"
            background-color="#ffffff"
            hide-details
            outlined
            dense
            @input="setElement('fx.decimal', {
              value: $event
            })" />
        </div>
      </div>
      <editor
        :value="series"
        :variables="variables"
        :error="errorType"
        class="mb-3"
        @input="setSeries($event)" />
      <div class="d-flex justify-space-between">
        <div class="numpad--wrapper">
          <numpad
            :value="calculator.points"
            :variables="variables"
            @input="setPoints($event)" />
        </div>
        <v-spacer />
        <div class="example-formula--wrapper mr-3">
          <div class="subtitle-2">
            {{ $t('papers.builder.settings.fx.example') }}
          </div>
          <example
            class="example-formula--text"
            :value="series"
            :variables="variables"
          />
        </div>
        <div>
          <v-btn
            color="primary"
            min-height="100%"
            max-width="60px"
            depressed
            block
            @click="submit()">
            <div>
              <div class="mb-1">
                {{ $t('papers.builder.settings.fx.submit') }}
              </div>
              <v-icon>
                mdi-content-save-outline
              </v-icon>
            </div>
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import _ from 'lodash'
import CalculatorParser from '@/assets/js/calculator-parser'
import { mapGetters } from 'vuex'
import paperSettingMixin from '@/mixins/paperSettingMixin'
import Numpad from './Numpad.vue'
import Example from './Example.vue'
import Editor from './Editor.vue'

export default {
  components: { Numpad, Example, Editor },
  mixins: [paperSettingMixin],
  props: {
    title: {
      type: String,
      default: 'None'
    },
    numberDisplayType: {
      type: String,
      default: ''
    },
    digit: {
      type: Number,
      default: 4
    },
    decimal: {
      type: Number,
      default: 4
    },
    formula: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      dialog: false,
      calculator: new CalculatorParser(this.formula),
      digits: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      decimals: [1, 2, 3, 4]
    }
  },
  computed: {
    ...mapGetters({
      findEnum: 'Enum/find',
      findTableField: 'TableField/find'
    }),
    paperNumberDisplayEnum () {
      return this.findEnum('PaperNumberDisplay')?.values || []
    },
    paperFieldOrder () {
      return this.findTableField('paperFieldOrder')?.values || []
    },
    paperFieldPurchase () {
      return this.findTableField('paperFieldPurchase')?.values || []
    },
    paperFieldSetOrder () {
      return this.findTableField('paperFieldSetOrder')?.values || []
    },
    paperFieldShop () {
      return this.findTableField('paperFieldShop')?.values || []
    },
    paperFieldStock () {
      return this.findTableField('paperFieldStock')?.values || []
    },
    paperFieldReseller () {
      return this.findTableField('paperFieldReseller')?.values || []
    },
    paperFieldPromoCode () {
      return this.findTableField('paperFieldPromoCode')?.values || []
    },
    variables () {
      const order = this.paperFieldOrder.map((props) => ({
        ...props,
        id: `order.${props.field}`,
        tableName: 'order',
        tableDescription: 'ออเดอร์'
      }))
      const purchase = this.paperFieldPurchase.map((props) => ({
        ...props,
        id: `purchase.${props.field}`,
        tableName: 'purchase',
        tableDescription: 'การซื้อ'
      }))
      const setOrder = this.paperFieldSetOrder.map((props) => ({
        ...props,
        id: `set_order.${props.field}`,
        tableName: 'set_order',
        tableDescription: 'เซตออเดอร์'
      }))
      const shop = this.paperFieldShop.map((props) => ({
        ...props,
        id: `shop.${props.field}`,
        tableName: 'shop',
        tableDescription: 'ร้านค้า'
      }))
      const stock = this.paperFieldStock.map((props) => ({
        ...props,
        id: `order.${props.field}`,
        tableName: 'stock',
        tableDescription: 'คลัง'
      }))
      const reseller = this.paperFieldReseller.map((props) => ({
        ...props,
        id: `reseller.${props.field}`,
        tableName: 'reseller',
        tableDescription: 'ตัวแทน'
      }))
      const promoCode = this.paperFieldPromoCode.map((props) => ({
        ...props,
        id: `promo_code.${props.field}`,
        tableName: 'promo_code',
        tableDescription: 'ส่วนลด'
      }))
      const variables = _.concat(order, purchase, setOrder, shop, stock, reseller, promoCode)
      const variableNumberOnly = variables.filter(({ type }) => _.includes(['number', 'float', 'double', 'integer', 'decimal'], type))
      return variableNumberOnly
    },
    series () {
      return this.calculator.toSeries()
    },
    errorType () {
      let errorType = null
      if (this.series.length) {
        if (['operator'].includes(_.last(this.series).type)) {
          errorType = 1
        }
      } else {
        errorType = 1
      }
      return errorType
    }
  },
  watch: {
    formula (value) {
      this.calculator = new CalculatorParser(value)
    }
  },
  methods: {
    submit () {
      const series = this.series.filter((serie) => _.includes(['number', 'field', 'associate', 'operator'], serie.type))
      this.setElement('data', _.head(series).value)
      this.clear('fx')
      let count = 0
      _.tail(series).forEach((point) => {
        if (point.type === 'operator') {
          this.setElement(`fx[${count}].name`, point.value)
        } else {
          this.setElement(`fx[${count}].value`, _.isFinite(_.toNumber(point.value)) ? _.toNumber(point.value) : point.value)
          count++
        }
      })
      this.setElement('fx.numberDisplayType', {
        name: 'numberDisplayType',
        value: this.numberDisplayType
      })
      this.setElement('fx.digit', {
        name: 'digit',
        value: this.digit
      })
      this.setElement('fx.decimal', {
        name: 'decimal',
        value: this.decimal
      })
      this.$emit('submit')
    },
    setSeries (serie) {
      if (serie) {
        const { id, value } = serie
        const points = _.cloneDeep(this.calculator.points)
        points[id].value = value
        this.setPoints(points)
      } else {
        this.setPoints([])
      }
    },
    setPoints (points) {
      this.calculator.points = points
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
<style scoped>
  .display-type--container {
    width: 240px;
  }
  .example-formula--wrapper {
    background-color: #ffffff;
    width: 300px;
    border:1px solid #ddd;
    padding: 16px;
  }
  .example-formula--text {
    font-size: 12px;
  }
</style>
