<template>
  <!-- eslint-disable vue/no-v-html  -->
  <v-dialog
    v-model="dialog"
    max-width="1300px"
    @input="changeDialog($event)">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="py-4"
        color="primary"
        outlined
        depressed
        tile
        v-bind="attrs"
        x-small
        v-on="on">
        <v-icon small>
          mdi-eye
        </v-icon>
      </v-btn>
    </template>
    <v-row no-gutters>
      <v-col cols="8">
        <div class="example py-6 px-8 h-100">
          <div class="example-preview text-center overflow-auto">
            <div
              v-if="isLoading"
              :style="{ height: height +'px'}"
              class="pa-10">
              <v-progress-circular
                indeterminate
                color="#ffffff"
              />
            </div>
            <template v-else>
              <div
                v-for="(page, i) in pages"
                :key="`example-${i}`"
                :class="{ 'canvas--black-mode': color === 'black' }"
                :style="{ width: width +'px', height: height +'px'}"
                class="d-inline-block relative white overflow-hidden"
                v-html="page">
              </div>
              <!-- {{ pages }}
              <div v-html="pages"></div> -->
            </template>
          </div>
        </div>
      </v-col>
      <v-col
        cols="4">
        <v-sheet
          class="py-6 px-4"
          height="100%">
          <div class="d-flex mb-3">
            <div class="font-weight-medium secondary--text">
              {{ $t('papers.builder.example.title') }}
            </div>
            <v-spacer />
            <v-btn
              :ripple="false"
              class="dialog-close-tr"
              icon
              x-small
              @click="setDialog(false)">
              <v-icon
                color="#C8C8C8"
                small>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <div class="example-subtitle mt-2 mb-4">
            {{ $t('papers.builder.example.subtitle') }}
          </div>
          <v-row
            no-gutters
            class="mb-4">
            <v-col cols="4">
              <text-field-label
                :label="$t('papers.builder.example.amount')"
                class="mr-2" />
            </v-col>
            <v-col cols="5">
              <div class="mr-2">
                <v-text-field
                  :value="2"
                  :disabled="isLoading"
                  background-color="#ffffff"
                  type="number"
                  class="app-style"
                  min="1"
                  max="100"
                  hide-details
                  outlined
                  dense
                  @input="debounceSetAmount($event)" />
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="4">
              <text-field-label
                :label="$t('papers.builder.example.color')"
                class="mr-2" />
            </v-col>
            <v-col cols="5">
              <div class="mr-2">
                <v-select
                  v-model="color"
                  :items="colors"
                  :disabled="isLoading"
                  item-text="name"
                  item-value="value"
                  class="app-style"
                  background-color="#ffffff"
                  hide-details
                  outlined
                  dense />
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-dialog>
</template>
<script>
/* eslint-disable new-cap */
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { GET_PAPERHTML } from '@/resources/graphql'

export default {
  apollo: {
    paperTestHtml: {
      query: GET_PAPERHTML,
      skip () {
        return !this.canvas.length || this.skip
      },
      watchLoading (isLoading) {
        this.isLoading = isLoading
      },
      variables () {
        return {
          canvas: this.canvas,
          paperSize: this.size,
          width: Math.floor(this.innerWidth),
          height: Math.floor(this.innerHeight),
          itemCount: _.toNumber(this.amount)
        }
      },
      result ({ data: { paperTestHtml } }) {
        this.pages = []
        const div = document.createElement('div')
        div.innerHTML = paperTestHtml.trim()
        div.children[0].children.forEach((doc) => {
          // eslint-disable-next-line no-param-reassign
          this.pages.push(doc.outerHTML)
        })
      }
    }
  },
  data () {
    return {
      isLoading: false,
      dialog: false,
      amount: 2,
      color: 'color',
      pages: ['<div></div>'],
      colors: [
        {
          name: this.$t('papers.builder.example.options.black'),
          value: 'black'
        },
        {
          name: this.$t('papers.builder.example.options.color'),
          value: 'color'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      paperBuilderId: 'App/paperBuilderId',
      findPaperBuilder: 'PaperBuilder/find'
    }),
    paperBuilder () {
      return this.findPaperBuilder(this.paperBuilderId)
    },
    paperDraftId () {
      return this.paperBuilder?.draftId
    },
    canvas () {
      return this.paperBuilder?.canvas || []
    },
    width () {
      return this.paperBuilder?.width
    },
    size () {
      return this.paperBuilder?.size
    },
    height () {
      return this.paperBuilder?.height
    },
    innerWidth () {
      return this.paperBuilder?.innerWidth
    },
    innerHeight () {
      return this.paperBuilder?.innerHeight
    },
    skip () {
      return !this.dialog
    }
  },
  created () {
    this.debounceSetAmount = _.debounce(this.setAmount, 500)
  },
  mounted () {
  },
  methods: {
    setAmount (value) {
      this.amount = value
    },
    reload () {
      this.pages = ['<div></div>']
      this.$apollo.queries.paperTestHtml.refetch()
    },
    changeDialog (dialog) {
      if (dialog) {
        this.reload()
      }
    },
    setDialog (dialog = false) {
      this.dialog = dialog
    }
  }
}
</script>
<style scoped>
  .example {
    background: #DADCE0;
  }
  .example-subtitle {
    color: #B8B8B8;
    font-size: 16px;
  }
  .example-preview {
    max-height: 600px;
    background: #D1D1D1;

  }
  .example-preview-list {
    position: relative;
    /* background-color:#ffffff; */
  }
  .example-preview {
    overflow: hidden;
    /* border: 1px solid #eee */
  }
  .canvas--black-mode {
    filter: grayscale(1);
  }
</style>
