import { render, staticRenderFns } from "./Canvas.vue?vue&type=template&id=14168575&"
import script from "./Canvas.vue?vue&type=script&lang=js&"
export * from "./Canvas.vue?vue&type=script&lang=js&"
import style0 from "./Canvas.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('14168575')) {
      api.createRecord('14168575', component.options)
    } else {
      api.reload('14168575', component.options)
    }
    module.hot.accept("./Canvas.vue?vue&type=template&id=14168575&", function () {
      api.rerender('14168575', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Papers/pages/Paper/components/Canvas.vue"
export default component.exports