var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    {
      staticClass: "w-100 table-purchase",
      attrs: { dense: "" },
      on: {
        mousemove: function($event) {
          return _vm.handleMousemove($event)
        },
        mouseup: function($event) {
          return _vm.handleMouseup($event)
        },
        mouseleave: function($event) {
          return _vm.handleMouseup($event)
        }
      }
    },
    [
      _vm.showHeader
        ? _c(
            "thead",
            [
              _c(
                "draggable",
                {
                  staticClass: "d-table-row",
                  style: _vm.headerStyles,
                  attrs: {
                    value: _vm.datasource,
                    handle: ".drag-col-handle",
                    tag: "tr"
                  },
                  on: {
                    input: function($event) {
                      return _vm.sortable($event)
                    }
                  }
                },
                _vm._l(_vm.datasource, function(source, index) {
                  return _c(
                    "td",
                    {
                      key: "purchase-header-" + source.field,
                      style: source.hProperties.style,
                      attrs: { scope: "col" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c(
                            "div",
                            { staticClass: "w-100" },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "drag-col-handle",
                                  attrs: { size: "10" }
                                },
                                [_vm._v(" $paper_drag ")]
                              ),
                              _vm._v(" " + _vm._s(source.display) + " ")
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          index !== _vm.datasource.length - 1
                            ? _c(
                                "span",
                                {
                                  ref: "resizeHandle" + index,
                                  refInFor: true,
                                  staticClass: "resize-handle",
                                  on: {
                                    mousedown: function($event) {
                                      return _vm.handleMousedown($event, index)
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { size: "14" } }, [
                                    _vm._v(" $paper_resize_column ")
                                  ])
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                }),
                0
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "tbody",
        [
          !_vm.showHeader
            ? [
                _c(
                  "draggable",
                  {
                    staticClass: "d-table-row",
                    style: _vm.contentStyles,
                    attrs: {
                      value: _vm.datasource,
                      handle: ".drag-col-handle",
                      tag: "tr"
                    },
                    on: {
                      input: function($event) {
                        return _vm.sortable($event)
                      }
                    }
                  },
                  [
                    _vm._l(_vm.datasource, function(source, index) {
                      return [
                        source.data.type === "string"
                          ? _c(
                              "td",
                              {
                                key: "purchase-header-" + source.field,
                                style: source.cProperties.style,
                                attrs: { scope: "col" }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "w-100" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "drag-col-handle",
                                            attrs: { size: "10" }
                                          },
                                          [_vm._v(" $paper_drag ")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "papers.builder.elements.table.product",
                                                [1]
                                              )
                                            ) +
                                            " "
                                        )
                                      ],
                                      1
                                    ),
                                    _c("v-spacer"),
                                    index !== _vm.datasource.length - 1
                                      ? _c(
                                          "span",
                                          {
                                            ref: "resizeHandle" + index,
                                            refInFor: true,
                                            staticClass: "resize-handle",
                                            on: {
                                              mousedown: function($event) {
                                                return _vm.handleMousedown(
                                                  $event,
                                                  index
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "14" } },
                                              [_vm._v(" $paper_resize_column ")]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            )
                          : source.data.type === "url"
                          ? _c(
                              "td",
                              {
                                key: "purchase-td-handle-" + source.field,
                                style: source.cProperties.style
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "drag-col-handle",
                                        attrs: { size: "10" }
                                      },
                                      [_vm._v(" $paper_drag ")]
                                    ),
                                    _c(
                                      "vue-draggable-resizable",
                                      {
                                        staticClass: "relative",
                                        attrs: {
                                          x: 0,
                                          y: 0,
                                          w: _vm.imgWidth,
                                          h: _vm.imgHeight,
                                          draggable: false,
                                          handles: ["br"],
                                          active: true,
                                          "prevent-deactivation": true,
                                          grid: [5, 5],
                                          "class-name":
                                            "purchase-image-draggable"
                                        },
                                        on: {
                                          resizestop: function(
                                            x,
                                            y,
                                            width,
                                            height
                                          ) {
                                            return _vm.resizeImage(
                                              width,
                                              height,
                                              index
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "purchase-image"
                                        })
                                      ]
                                    ),
                                    index !== _vm.datasource.length - 1
                                      ? _c(
                                          "span",
                                          {
                                            ref: "resizeHandle" + index,
                                            refInFor: true,
                                            staticClass: "resize-handle",
                                            on: {
                                              mousedown: function($event) {
                                                return _vm.handleMousedown(
                                                  $event,
                                                  index
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "14" } },
                                              [_vm._v(" $paper_resize_column ")]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            )
                          : _c(
                              "td",
                              {
                                key: "purchase-header-" + source.field,
                                style: source.cProperties.style,
                                attrs: { scope: "col" }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "w-100" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "drag-col-handle",
                                            attrs: { size: "10" }
                                          },
                                          [_vm._v(" $paper_drag ")]
                                        ),
                                        _vm._v(" 100.00 ")
                                      ],
                                      1
                                    ),
                                    _c("v-spacer"),
                                    index !== _vm.datasource.length - 1
                                      ? _c(
                                          "span",
                                          {
                                            ref: "resizeHandle" + index,
                                            refInFor: true,
                                            staticClass: "resize-handle",
                                            on: {
                                              mousedown: function($event) {
                                                return _vm.handleMousedown(
                                                  $event,
                                                  index
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "14" } },
                                              [_vm._v(" $paper_resize_column ")]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            )
                      ]
                    })
                  ],
                  2
                )
              ]
            : _vm._e(),
          _vm.showHeader
            ? _c(
                "tr",
                { style: _vm.contentStyles },
                [
                  _vm._l(_vm.datasource, function(source, index) {
                    return [
                      source.data.type === "string"
                        ? _c(
                            "td",
                            {
                              key: "purchase-td-handle-" + source.field,
                              style: source.cProperties.style
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "papers.builder.elements.table.product",
                                      [1]
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : source.data.type === "url"
                        ? _c(
                            "td",
                            {
                              key: "purchase-td-handle-" + source.field,
                              style: source.cProperties.style
                            },
                            [
                              _c(
                                "vue-draggable-resizable",
                                {
                                  staticClass: "relative",
                                  attrs: {
                                    x: 0,
                                    y: 0,
                                    w: _vm.imgWidth,
                                    h: _vm.imgHeight,
                                    draggable: false,
                                    handles: ["br"],
                                    active: true,
                                    "prevent-deactivation": true,
                                    grid: [5, 5],
                                    "class-name": "purchase-image-draggable"
                                  },
                                  on: {
                                    resizestop: function(x, y, width, height) {
                                      return _vm.resizeImage(
                                        width,
                                        height,
                                        index
                                      )
                                    }
                                  }
                                },
                                [_c("div", { staticClass: "purchase-image" })]
                              )
                            ],
                            1
                          )
                        : _c(
                            "td",
                            {
                              key: "purchase-td-handle-" + source.field,
                              style: source.cProperties.style
                            },
                            [_vm._v(" 100.00 ")]
                          )
                    ]
                  })
                ],
                2
              )
            : _vm._e(),
          _vm._l(1, function(item) {
            return _c(
              "tr",
              { key: "purchase-body-" + item, style: _vm.contentStyles },
              _vm._l(_vm.datasource, function(source) {
                return _c(
                  "td",
                  {
                    key: "purchase-td-" + source.field,
                    style: source.cProperties.style
                  },
                  [
                    source.data.type === "string"
                      ? [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "papers.builder.elements.table.product",
                                  [item + 1]
                                )
                              ) +
                              " "
                          )
                        ]
                      : source.data.type === "url"
                      ? [
                          _c("img", {
                            attrs: {
                              width: _vm.imgWidth,
                              height: _vm.imgHeight,
                              src: "https://picsum.photos/100/100?image=30"
                            }
                          })
                        ]
                      : [_vm._v(" 100.00 ")]
                  ],
                  2
                )
              }),
              0
            )
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }