<template>
  <v-btn
    :loading="submitting"
    :disabled="!dirty"
    color="primary"
    outlined
    @click="submit()">
    <v-icon
      size="16"
      outlined
      left>
      $paper_save_outline
    </v-icon>
    {{ $t('papers.builder.save') }}
  </v-btn>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { CREATE_PAPER, UPDATE_PAPER } from '@/resources/graphql'

export default {
  data () {
    return {
      submitting: false
    }
  },
  computed: {
    ...mapGetters({
      paperBuilderId: 'App/paperBuilderId',
      findPaperBuilder: 'PaperBuilder/find'
    }),
    paperBuilder () {
      return this.findPaperBuilder(this.paperBuilderId)
    },
    paperName () {
      return this.paperBuilder?.name
    },
    paperDraftId () {
      return this.paperBuilder?.draftId
    },
    paperSize () {
      return this.paperBuilder?.size
    },
    paperInnerWidth () {
      return this.paperBuilder?.innerWidth
    },
    paperInnerHeight () {
      return this.paperBuilder?.innerHeight
    },
    paperCondition () {
      return this.paperBuilder?.condition
    },
    canvas () {
      return this.paperBuilder?.canvas || []
    },
    dirty () {
      return this.paperBuilder?.dirty || false
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Notification/setSnackbar',
      markAsPristine: 'PaperBuilder/markAsPristine'
    }),
    async submit () {
      this.submitting = true
      if (this.paperDraftId) {
        const res = await this.$apollo.mutate({
          mutation: UPDATE_PAPER,
          variables: {
            input: {
              id: this.paperDraftId,
              width: Math.floor(this.paperInnerWidth),
              height: Math.floor(this.paperInnerHeight),
              paperSize: this.paperSize,
              condition: this.paperCondition,
              canvas: this.canvas,
              active: true
            }
          }
        })
        if (res) {
          const { data: { updatePaper } } = res
          if (updatePaper.errors && Object.keys(updatePaper.errors).length) {
            this.setSnackbar({
              message: 'Error',
              type: 'error',
              value: true,
              absolute: false,
              color: 'error',
              timeout: 3000
            })
          } else {
            this.setSnackbar({
              message: this.$t('papers.snackbar.saveSuccess'),
              type: 'success',
              value: true,
              absolute: false,
              color: '#4F4F4F',
              timeout: 3000
            })
            this.markAsPristine({
              id: this.paperBuilderId
            })
          }
        }
      } else {
        const res = await this.$apollo.mutate({
          mutation: CREATE_PAPER,
          variables: {
            input: {
              paperGroupId: this.paperBuilderId,
              name: this.paperName,
              width: Math.floor(this.paperInnerWidth),
              height: Math.floor(this.paperInnerHeight),
              paperSize: this.paperSize,
              condition: this.paperCondition,
              canvas: this.canvas,
              active: true
            }
          }
        })
        if (res) {
          const { data: { createPaper } } = res
          if (createPaper.errors && Object.keys(createPaper.errors).length) {
            this.setSnackbar({
              message: 'Error',
              type: 'error',
              value: true,
              absolute: false,
              color: 'error',
              timeout: 3000
            })
          } else {
            this.setSnackbar({
              message: this.$t('papers.snackbar.saveSuccess'),
              type: 'success',
              value: true,
              absolute: false,
              color: '#4F4F4F',
              timeout: 3000
            })
            window.location.reload()
          }
        }
      }
      this.submitting = false
    }
  }
}
</script>
<style scoped>
</style>
