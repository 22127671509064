<template>
  <text-editor-inline
    :value="active"
    :font-size="fontSize"
    :text-align="textAlign"
    :font-weight="fontWeight"
    :font-style="fontStyle"
    :text-decoration="textDecoration"
    :color="color"
    @input="(key, value) => setElement(key, value)">
    <div
      class="text-element"
      :style="{
        width: '100%',
        height: '100%',
        fontSize,
        textAlign,
        fontWeight,
        fontStyle,
        textDecoration,
        color,
        overflow,
        whiteSpace
      }">
      {{ text }}
    </div>
  </text-editor-inline>
</template>

<script>
import paperElementMixin from '@/mixins/paperElementMixin'
import paperSettingMixin from '@/mixins/paperSettingMixin'
import TextEditorInline from '../Widget/TextEditorInline.vue'

export default {
  components: { TextEditorInline },
  mixins: [paperElementMixin, paperSettingMixin],
  props: {
    text: {
      type: String,
      default: ''
    },
    fontSize: {
      type: String,
      default: '14px'
    },
    textAlign: {
      type: String,
      default: 'left'
    },
    fontWeight: {
      type: String,
      default: 'normal'
    },
    fontStyle: {
      type: String,
      default: 'normal'
    },
    textDecoration: {
      type: String,
      default: 'none'
    },
    color: {
      type: String,
      default: '#000000'
    },
    overflow: {
      type: String,
      default: 'initial'
    },
    whiteSpace: {
      type: String,
      default: 'normal'
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  }

}
</script>
<style scoped>
  .text-element {
    user-select: none;
  }
</style>
<style>
  .input-element-style,
  .input-element-style:focus {
    width: 100%;
    border: 0;
    outline: none;
    resize: none;
    overflow: hidden;
    user-select: none;
  }
</style>
