var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { color: "#FBFBFB" } },
    [
      _c(
        "v-card-title",
        { staticClass: "px-4 pb-3" },
        [
          _c("title-label", {
            staticClass: "mr-4",
            attrs: { label: _vm.$t("papers.builder.settings.fx.display") }
          }),
          _c(
            "div",
            { staticClass: "display-type--container" },
            [
              _c("v-select", {
                staticClass: "app-style",
                attrs: {
                  value: _vm.numberDisplayType,
                  items: _vm.paperNumberDisplayEnum,
                  "item-value": "name",
                  "item-text": "description",
                  "background-color": "#ffffff",
                  "hide-details": "",
                  outlined: "",
                  dense: ""
                },
                on: {
                  input: function($event) {
                    return _vm.setElement("fx.numberDisplayType", {
                      value: $event
                    })
                  }
                }
              })
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-icon",
            {
              staticClass: "dialog-close-tr",
              attrs: { color: "#C8C8C8", small: "" },
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [_vm._v(" mdi-close ")]
          )
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "px-4" },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center mb-2" },
            [
              _c("title-label", {
                staticClass: "mr-8",
                attrs: {
                  label: _vm.$t("papers.builder.settings.decimal.digits")
                }
              }),
              _c(
                "div",
                { staticClass: "display-type--container mr-6" },
                [
                  _c("v-select", {
                    staticClass: "app-style",
                    attrs: {
                      value: _vm.digit,
                      items: _vm.digits,
                      "background-color": "#ffffff",
                      "hide-details": "",
                      outlined: "",
                      dense: ""
                    },
                    on: {
                      input: function($event) {
                        return _vm.setElement("fx.digit", {
                          value: $event
                        })
                      }
                    }
                  })
                ],
                1
              ),
              _c("title-label", {
                staticClass: "mr-3",
                attrs: {
                  label: _vm.$t("papers.builder.settings.decimal.decimal")
                }
              }),
              _c(
                "div",
                { staticClass: "display-type--container" },
                [
                  _c("v-select", {
                    staticClass: "app-style",
                    attrs: {
                      value: _vm.decimal,
                      items: _vm.decimals,
                      "background-color": "#ffffff",
                      "hide-details": "",
                      outlined: "",
                      dense: ""
                    },
                    on: {
                      input: function($event) {
                        return _vm.setElement("fx.decimal", {
                          value: $event
                        })
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("editor", {
            staticClass: "mb-3",
            attrs: {
              value: _vm.series,
              variables: _vm.variables,
              error: _vm.errorType
            },
            on: {
              input: function($event) {
                return _vm.setSeries($event)
              }
            }
          }),
          _c(
            "div",
            { staticClass: "d-flex justify-space-between" },
            [
              _c(
                "div",
                { staticClass: "numpad--wrapper" },
                [
                  _c("numpad", {
                    attrs: {
                      value: _vm.calculator.points,
                      variables: _vm.variables
                    },
                    on: {
                      input: function($event) {
                        return _vm.setPoints($event)
                      }
                    }
                  })
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "div",
                { staticClass: "example-formula--wrapper mr-3" },
                [
                  _c("div", { staticClass: "subtitle-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("papers.builder.settings.fx.example")) +
                        " "
                    )
                  ]),
                  _c("example", {
                    staticClass: "example-formula--text",
                    attrs: { value: _vm.series, variables: _vm.variables }
                  })
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        "min-height": "100%",
                        "max-width": "60px",
                        depressed: "",
                        block: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.submit()
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("div", { staticClass: "mb-1" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("papers.builder.settings.fx.submit")
                                ) +
                                " "
                            )
                          ]),
                          _c("v-icon", [_vm._v(" mdi-content-save-outline ")])
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }