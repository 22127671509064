<template>
  <div class="w-100">
    <div
      ref="canvassimu"
      class="canvas-simu-scroll"
      @scroll="canvasSimuScroll()">
      <div
        class="paper-simu-scroll d-inline-block"
        :style="{
          width: width + 'px',
        }"></div>
    </div>
    <div
      id="my-canvas"
      ref="canvas"
      class="canvas"
      @click="onClickOutside($event)"
      @scroll="canvasScroll()">
      <div
        class="paper d-inline-block"
        :style="{
          height: height + 'px',
          width: width + 'px',
        }">
        <div
          id="paper"
          class="relative"
          @drop="onDrop($event)"
          @dragenter.prevent
          @dragover.prevent>
          <vue-draggable-resizable
            id="paper-resizable"
            class="canvas-resizable"
            :w="innerWidth"
            :h="innerHeight"
            :max-width="maxWidth"
            :max-height="maxHeight"
            :grid="[(width*gridCanvasStep), (height*gridCanvasStep)]"
            :handles="['mr', 'bm']"
            :draggable="false"
            :active="true"
            :enable-native-drag="true"
            :prevent-deactivation="true"
            class-name-handle="my-handle-class"
            @resizestop="(x, y, width, height) => onInnerResize(width, height)">
            <div
              v-for="el in elements"
              :key="`el-${el.id}`"
              @click="onActivated(el.id)"
              @drop.prevent>
              <vue-draggable-resizable
                v-bind="el.attrs"
                :resizable="!isMultiActivated"
                :enable-native-drag="true"
                :x="el.style.left"
                :y="el.style.top"
                :w="el.style.width"
                :h="el.style.height"
                :rotate="getRotate(el.style.transform)"
                :max-width="el.style.maxWidth"
                :max-height="el.style.maxHeight"
                :grid="[gridWidth,gridHeight]"
                :active="isActivated(el.id)"
                :prevent-deactivation="true"
                class-name="el-draggable"
                class-name-handle="el-handle-class"
                drag-handle=".drag-handle"
                @dragstop="(x, y) => onDrag(el.id, x, y)"
                @resizestop="(x, y, width, height) => onResize(el.id, x, y, width, height)"
                @rotate="onRotate(el.id, $event)"
                @deactivated="onDeactivated(el.id)">
                <div
                  v-if="isActivated(el.id) && !isMultiActivated"
                  :class="`drag-handle-wrap ${el.type}--wrap`"
                  @click="onDeactivated(activatedId)">
                  <div class="drag-handle">
                    <v-icon
                      dark>
                      mdi-arrow-all
                    </v-icon>
                  </div>
                </div>
                <component
                  :is="el.component"
                  :id="el.id"
                  :text="el.text"
                  :active="isActivated(el.id) && !isMultiActivated"
                  v-bind="el.style"
                  :formula="el.data.formula"
                  :address-owner-type="el.data.addressOwnerType"
                  :purchase-fields="el.data.purchaseFields"
                  :tel-owner-type="el.data.telOwnerType"
                  :number-display-type="el.fx.numberDisplayType"
                  :digit="el.fx.digit"
                  :decimal="el.fx.decimal"
                  :date-field="el.data.dateField"
                  :date-format="el.fx.dateFormat"
                  :date-display-type="el.fx.dateDisplayType"
                  :time-display-type="el.fx.timeDisplayType"
                  :tel-display-type="el.fx.telDisplayType"
                  :tax-owner-type="el.data.taxOwnerType"
                  :tax-display-type="el.fx.taxDisplayType"
                  :running-number-type="el.data.runningNumberType" />
                <div v-show="isActivated(el.id) && !isMultiActivated">
                  <div :class="`tool-wrap ${el.type}--wrap`">
                    <div class="setting-cog--wrap">
                      <component
                        :is="el.setting"
                        :id="el.id"
                        :title="el.name"
                        :text="el.text"
                        v-bind="el.style"
                        :formula="el.data.formula"
                        :address-owner-type="el.data.addressOwnerType"
                        :purchase-fields="el.data.purchaseFields"
                        :tel-owner-type="el.data.telOwnerType"
                        :number-display-type="el.fx.numberDisplayType"
                        :digit="el.fx.digit"
                        :decimal="el.fx.decimal"
                        :date-field="el.data.dateField"
                        :date-format="el.fx.dateFormat"
                        :date-display-type="el.fx.dateDisplayType"
                        :time-display-type="el.fx.timeDisplayType"
                        :tel-display-type="el.fx.telDisplayType"
                        :tax-owner-type="el.data.taxOwnerType"
                        :tax-display-type="el.fx.taxDisplayType"
                        :running-number-type="el.data.runningNumberType"
                        :barcode-type="el.data.barcodeType" />
                    </div>
                    <div
                      class="setting-action--wrap d-flex align-center justify-center">
                      <action-setting
                        :id="el.id"
                        @after:duplicate="onActivated($event)" />
                    </div>
                  </div>
                </div>
              </vue-draggable-resizable>
            </div>
          </vue-draggable-resizable>
        </div>
        <div class="example-container">
          <div class="example-placeholder d-flex flex-column align-center justify-center flex-grow-1">
            <v-icon
              color="#B6B6B6"
              size="65">
              mdi-eye
            </v-icon>
            <div class="disabled--text">
              {{ $t('papers.builder.example.placeholder') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import paperCanvasEventMixin from '@/mixins/paperCanvasEventMixin'
import paperCanvasRegisterMixin from '@/mixins/paperCanvasRegisterMixin'

export default {
  name: 'StCanvas',
  mixins: [paperCanvasEventMixin, paperCanvasRegisterMixin],
  data () {
    return {
      gridWidth: 10,
      gridHeight: 10,
      gridCanvasStep: 0.05
    }
  },
  computed: {
    ...mapGetters({
      paperBuilderId: 'App/paperBuilderId',
      findPaperBuilder: 'PaperBuilder/find',
      paperBuilderList: 'PaperBuilder/list'
    }),
    paperBuilder () {
      return this.findPaperBuilder(this.paperBuilderId)
    },
    width () {
      return this.paperBuilder?.width || 0
    },
    height () {
      return this.paperBuilder?.height || 0
    },
    maxWidth () {
      return this.width + 1
    },
    maxHeight () {
      return this.height + 1
    },
    innerWidth () {
      return this.paperBuilder?.innerWidth
    },
    innerHeight () {
      return this.paperBuilder?.innerHeight
    },
    canvas () {
      return this.paperBuilder?.canvas || []
    },
    elements () {
      return this.canvas.map(this.createTemplate)
    }
  },
  methods: {
    canvasSimuScroll () {
      this.$refs.canvas.scrollLeft = this.$refs.canvassimu.scrollLeft
    },
    canvasScroll () {
      this.$refs.canvassimu.scrollLeft = this.$refs.canvas.scrollLeft
    },
    onRotate (id, degree) {
      const paper = this.findPaperBuilder(this.paperBuilderId)
      const index = _.findIndex(paper.canvas, { id })
      this.updatePaperBuilderCanvas({
        id: this.paperBuilderId,
        data: _.update(
          paper.canvas,
          `[${index}].properties.style`,
          (style) => _.merge(style, {
            transform: `rotate(${degree}deg)`
          })
        )
      })
    },
    rotate (id) {
      const paper = this.findPaperBuilder(this.paperBuilderId)
      const index = _.findIndex(paper.canvas, { id })
      this.updatePaperBuilderCanvas({
        id: this.paperBuilderId,
        data: _.update(
          paper.canvas,
          `[${index}].properties.style`,
          (style) => _.merge(style, {
            transform: 'rotate(90deg)'
          })
        )
      })
    },
    getRotate (rotate) {
      const match = rotate?.match(/\d+/) || [0]
      return Number(match[0])
    }
  }
}
</script>
<style>
  @import '~@/assets/scss/canvas.scss';
</style>
