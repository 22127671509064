var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "420px", scrollable: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { attrs: { color: "primary", "x-small": "", icon: "" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { attrs: { size: "17" } }, [
                    _vm._v(" $paper_cog ")
                  ])
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "px-4 pb-1" },
            [
              _c("title-label", { attrs: { label: _vm.title } }),
              _c("v-spacer"),
              _c(
                "v-icon",
                {
                  staticClass: "dialog-close-tr",
                  attrs: { color: "#C8C8C8", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.setDialog(false)
                    }
                  }
                },
                [_vm._v(" mdi-close ")]
              )
            ],
            1
          ),
          _c("v-card-text", { staticClass: "px-4" }, [
            _c("div", { staticClass: "setting-subtitle mt-2 mb-4" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("papers.builder.settings.image.title")) +
                  " "
              )
            ]),
            _c(
              "div",
              { staticClass: "w-100" },
              [
                _vm.backgroundImage
                  ? _c("v-img", {
                      style: {
                        width: "100%",
                        height: "150px",
                        backgroundImage: _vm.backgroundImage,
                        backgroundPosition: _vm.backgroundPosition,
                        backgroundSize: _vm.backgroundSize
                      }
                    })
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column align-center justify-center"
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        ref: "setting-upload-label",
                        staticClass: "upload-label",
                        attrs: {
                          for: "setting-upload-" + _vm.id,
                          tag: "label",
                          color: "primary",
                          depressed: "",
                          tile: ""
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "papers.builder.elements.image.placeholder"
                              )
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "upload-input text-center p-2" },
                      [
                        _c("file-upload", {
                          ref: "upload-" + _vm.id,
                          attrs: {
                            extensions: "gif,jpg,jpeg,png,webp",
                            accept: "image/png,image/gif,image/jpeg,image/webp",
                            name: "setting-upload-" + _vm.id,
                            drop: !_vm.edit
                          },
                          on: {
                            "input-filter": _vm.inputFilter,
                            "input-file": _vm.inputFile
                          },
                          model: {
                            value: _vm.files,
                            callback: function($$v) {
                              _vm.files = $$v
                            },
                            expression: "files"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }