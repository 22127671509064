<template>
  <v-dialog
    v-model="dialog"
    max-width="420px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        color="primary"
        x-small
        icon
        v-on="on">
        <v-icon
          size="17">
          $paper_cog
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="px-4 pb-1">
        <title-label :label="title" />
        <v-spacer />
        <v-icon
          class="dialog-close-tr"
          color="#C8C8C8"
          small
          @click="setDialog(false)">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="px-4">
        <div class="setting-subtitle mt-2 mb-4">
          {{ $t('papers.builder.settings.image.title') }}
        </div>
        <div class="w-100">
          <v-img
            v-if="backgroundImage"
            :style="{
              width: '100%',
              height: '150px',
              backgroundImage,
              backgroundPosition,
              backgroundSize,
            }"
          />
          <div class="d-flex flex-column align-center justify-center">
            <v-btn
              :ref="`setting-upload-label`"
              :for="`setting-upload-${id}`"
              class="upload-label"
              tag="label"
              color="primary"
              depressed
              tile>
              {{ $t(`papers.builder.elements.image.placeholder`) }}
            </v-btn>
            <div class="upload-input text-center p-2">
              <file-upload
                :ref="`upload-${id}`"
                v-model="files"
                :extensions="'gif,jpg,jpeg,png,webp'"
                :accept="'image/png,image/gif,image/jpeg,image/webp'"
                :name="`setting-upload-${id}`"
                :drop="!edit"
                @input-filter="inputFilter"
                @input-file="inputFile" />
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import paperSettingMixin from '@/mixins/paperSettingMixin'
import FileUpload from 'vue-upload-component'

export default {
  components: {
    FileUpload
  },
  mixins: [paperSettingMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'None'
    },
    backgroundImage: {
      type: String,
      default: ''
    },
    backgroundPosition: {
      type: String,
      default: 'center center'
    },
    backgroundSize: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      dialog: false,
      files: [],
      edit: false
    }
  },
  methods: {
    async inputFile (newFile, oldFile) {
      if (newFile && !oldFile) {
        this.$nextTick(async () => {
          if (this.confirm) {
            this.edit = true
          } else {
            const file = this.files[0]
            const base64 = await this.toBase64(file)
            this.setElement('properties.style.backgroundImage', `url(${base64})`)
            this.edit = false
          }
        })
      }
      if (!newFile && oldFile) {
        this.edit = false
      }
    },
    toBase64 (newFile) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(newFile.file)
        reader.onloadend = () => resolve(reader.result)
      })
    },
    async inputFilter (newFile, oldFile, prevent) {
      this.error = null
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.error = '* Your choice is not a picture'
          return prevent()
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        const URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    },
    setDialog (dialog = false) {
      this.dialog = dialog
    }
  }
}
</script>

<style scoped>
  .no-option--container {
    height: 300px;
  }
  .setting-subtitle {
    color: #B8B8B8;
    font-size: 16px;
  }
  .element-upload {
    height: 100%;
    width: 300px;
    background: #C4C4C4;
  }
  .upload-label {
    margin: 6px 0 0;
    cursor: pointer;
    font-size: 12px;
  }
  .upload-input {
    display: none;
  }
</style>
