var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        "nudge-width": 150,
        "offset-x": ""
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var attrs = ref.attrs
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { staticClass: "mx-1", attrs: { text: "" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "flex-column",
                      attrs: { align: "center", justify: "center" }
                    },
                    [
                      _c("v-icon", { staticClass: "cols 12" }, [
                        _vm._v(" mdi-format-color-text ")
                      ]),
                      _c("v-sheet", {
                        staticStyle: { "margin-top": "-4px" },
                        attrs: {
                          tile: "",
                          height: "4",
                          width: "26",
                          color: _vm.value
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.menu,
        callback: function($$v) {
          _vm.menu = $$v
        },
        expression: "menu"
      }
    },
    [
      _c("v-color-picker", {
        attrs: { value: _vm.value, "dot-size": 25, mode: "hexa" },
        on: {
          input: function($event) {
            return _vm.debounceOnChange($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }