var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "400px", scrollable: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _vm.dirty
                ? _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { color: "error", text: "" } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("papers.builder.discard")) + " "
                      )
                    ]
                  )
                : _c(
                    "v-btn",
                    {
                      attrs: { color: "error", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.submit()
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("papers.builder.discard")) + " "
                      )
                    ]
                  )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "div",
            { staticClass: "d-flex pa-2" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.setDialog(false)
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(" mdi-close ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-center mb-1" },
            [
              _c("v-icon", { attrs: { size: 56, color: "primary" } }, [
                _vm._v(" $paper_logout ")
              ])
            ],
            1
          ),
          _c("v-card-title", { staticClass: "subtitle-1 justify-center" }, [
            _vm._v(" " + _vm._s(_vm.$t("papers.discard.title")) + " ")
          ]),
          _c("v-card-text", { staticClass: "text-center text--disabled" }, [
            _c("div", { staticClass: "mx-10" }, [
              _vm._v(" " + _vm._s(_vm.$t("papers.discard.description")) + " ")
            ])
          ]),
          _c(
            "v-card-actions",
            { staticClass: "py-4 bg--disabled border-top--color" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "px-3",
                  attrs: { color: "primary", depressed: "", rounded: "" },
                  on: {
                    click: function($event) {
                      return _vm.setDialog(false)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("app.btn.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "error", rounded: "", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.submit()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("papers.discard.submit")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }