<template>
  <v-card
    width="258px"
    flat>
    <v-card-text class="pa-0">
      <div
        class="d-flex mb-1 align-center">
        <v-icon
          v-if="itemActive"
          class="mr-2"
          color="primary"
          size="8">
          mdi-circle
        </v-icon>
        <div
          v-if="!renaming"
          class="d-flex my-2 align-center paper-draft-card">
          <v-tooltip
            :disabled="itemName.length < 16"
            bottom>
            <template #activator="{ on, attrs }">
              <div
                v-bind="attrs"
                class="mr-1"
                :class="{
                  'text-overflow': itemName.length >= 16,
                }"
                v-on="on">
                {{ itemName }}
              </div>
            </template>
            <v-card
              max-width="320"
              color="transparent"
              flat
              dark>
              {{ itemName }}
            </v-card>
          </v-tooltip>
          <draft-action
            :item-id="itemId"
            :item-active="itemActive"
            @click:rename="setRenaming(true)"
            @after:removed="afterRemoved($event)" />
        </div>
        <v-form
          v-else
          ref="form"
          v-model="valid"
          :readonly="submitting"
          class="d-flex align-center"
          @submit.prevent="submit()">
          <v-text-field
            :value="itemName"
            :rules="rule.name"
            class="app-style mr-2"
            autofocus
            hide-details
            outlined
            dense
            @input="setName($event)" />
          <v-btn
            color="primary"
            :loading="submitting"
            :disabled="!valid"
            rounded
            small
            text
            @click="submit()">
            {{ $t('app.btn.submit') }}
          </v-btn>
        </v-form>
      </div>
      <v-hover v-slot="{ hover }">
        <div
          class="draft-card"
          :style="{
            backgroundImage: base64 ? `url(${base64})`: '#ffffff',
            backgroundSize: 'contain'
          }">
          <v-fade-transition>
            <div
              v-if="hover && !itemActive"
              class="d-flex w-100 h-100 d-flex justify-center align-end draft-action-overlay">
              <active-draft-button
                v-if="!itemActive"
                class="ma-4"
                :item-id="itemId" />
            </div>
          </v-fade-transition>
        </div>
      </v-hover>
    </v-card-text>
    <div class="overflow-pixel">
      <div
        :id="`draft-canvas-${itemId}`"
        class="relative" />
    </div>
  </v-card>
</template>

<script>
import * as htmlToImage from 'html-to-image'
import { mapGetters } from 'vuex'
import { GET_PAPERHTML, UPDATE_PAPER } from '@/resources/graphql'
import ActiveDraftButton from './ActiveDraftButton.vue'
import DraftAction from './DraftAction.vue'

export default {
  components: {
    ActiveDraftButton,
    DraftAction
  },
  apollo: {
    paperTestHtml: () => ({
      query: GET_PAPERHTML,
      fetchPolicy: 'no-cache',
      variables () {
        return {
          canvas: this.itemCanvas,
          paperSize: this.itemPaperSize,
          width: Math.floor(this.itemWidth),
          height: Math.floor(this.itemHeight)
        }
      },
      result ({ data: paperTestHtml }) {
        const node = document.getElementById(`draft-canvas-${this.itemId}`)
        node.innerHTML = paperTestHtml.paperTestHtml
        this.$nextTick(() => {
          htmlToImage.toPng(node)
            .then((dataUrl) => {
              this.base64 = dataUrl
            })
        })
      }
    })
  },
  props: {
    itemId: {
      type: [String, Number],
      required: true
    },
    itemName: {
      type: String,
      required: true
    },
    itemActive: {
      type: Boolean,
      required: true
    },
    itemCanvas: {
      type: [Array],
      default: () => ([])
    },
    itemWidth: {
      type: [String, Number],
      default: 260
    },
    itemHeight: {
      type: [String, Number],
      default: 185
    },
    itemPaperSize: {
      type: [String],
      default: ''
    }
  },
  data () {
    return {
      dialog: false,
      submitting: false,
      valid: true,
      renaming: false,
      name: this.itemName,
      base64: null,
      rule: {
        name: [
          (v) => !!v || this.$t('app.validate.required')
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      paperBuilderId: 'App/paperBuilderId',
      findPaperBuilder: 'PaperBuilder/find'
    }),
    paperBuilder () {
      return this.findPaperBuilder(this.paperBuilderId)
    },
    papers () {
      return this.paperBuilder?.papers || []
    }
  },
  watch: {
    itemName (value) {
      this.name = value
    }
  },
  methods: {
    setRenaming (renaming = false) {
      this.renaming = renaming
    },
    setName (name) {
      this.name = name
    },
    async submit () {
      if (!this.valid) return
      this.submitting = true
      await this.$apollo.mutate({
        mutation: UPDATE_PAPER,
        variables: {
          input: {
            id: this.itemId,
            name: this.name
          }
        }
      })
      this.submitting = false
      this.setRenaming(false)
      this.$emit('after:renamed')
    },
    afterRemoved () {
      this.$emit('after:removed')
    }
  }
}
</script>
<style scoped>
  .draft-card {
    height: 380px;
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 2px;
  }
  .draft-action-overlay {
    background: rgba(108, 108, 108, 0.2);
  }
  .overflow-pixel {
    width: 0;
    height: 0;
    overflow: hidden;
  }
  .paper-draft-card {
    width: 240px;
  }
</style>
