import { render, staticRenderFns } from "./VariableSelector.vue?vue&type=template&id=873a2f8a&scoped=true&"
import script from "./VariableSelector.vue?vue&type=script&lang=js&"
export * from "./VariableSelector.vue?vue&type=script&lang=js&"
import style0 from "./VariableSelector.vue?vue&type=style&index=0&lang=css&"
import style1 from "./VariableSelector.vue?vue&type=style&index=1&id=873a2f8a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "873a2f8a",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VIcon,VList,VListItem,VListItemContent,VListItemIcon,VListItemTitle,VMenu,VSpacer,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('873a2f8a')) {
      api.createRecord('873a2f8a', component.options)
    } else {
      api.reload('873a2f8a', component.options)
    }
    module.hot.accept("./VariableSelector.vue?vue&type=template&id=873a2f8a&scoped=true&", function () {
      api.rerender('873a2f8a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Papers/pages/Paper/components/Calculator/VariableSelector.vue"
export default component.exports